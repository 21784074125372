import React, { useEffect, useRef, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Segment, Tab } from 'semantic-ui-react';

import {
	clearSelectedClientReq4In,
	listenToSelectedClientReq4In,
	setActiveTab,
} from '../clientReqActions4In';

import {
	clearPaydocu,
	fetchPaydocusFormFirestoreService4In,
} from '../../../companyManage/companyActions';

import {
	addChildLeaveOfAbsenceToWorkerFirestore,
	addChildShortTimeToWorkerFirestore,
	addClientReq4InToFirestore,
	addLeaveOfAbsenceToWorkerFirestore,
	addMaternityLeaveToWorkerFirestore,
	addTotalWagesArrayToWorkerFirestore,
	listenToClientReq4InFromFirestore,
	updateChildLeaveOfAbsenceInWorkerFirestore,
	updateChildShortTimeInWorkerFirestore,
	updateClientReq4InInFirestore,
	updateLeaveOfAbsenceInWorkerFirestore,
	updateMaternityLeaveInWorkerFirestore,
} from '../../../../app/firestore/firestoreService4In';

import { decrypto } from '../../../../app/firestore/firestoreService';
import useFirestoreDoc from '../../../../app/hooks/useFirestoreDoc';
import {
	checkNoUserInfo,
	isAgentF,
	lastPreventDateF,
	redirectToAppropriatePage,
} from '../../../../app/common/util/util';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import CrwfWorkerBasicInfo from './crwfDetail/CrwfWorkerBasicInfo';
import CrwfWorkerBasicTimeInfo from './crwfDetail/CrwfWorkerBasicTimeInfo';
import CrwfWorkerBasicWageInfo from './crwfDetail/CrwfWorkerBasicWageInfo';
import CrwfWorkerRetireInfo from './crwfDetail/CrwfWorkerRetireInfo';
import ClientReqLastPreventData from '../clientsReqDashboard4In/ClientReqLastPreventData';
import PaydocuShortCut from '../../../companyManage/paydocuDashboard/PaydocuShortCut';
import axios from 'axios';
import { deleteField, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../app/config/firebase';
const API_BASE_URL = process.env.REACT_APP_API_SERVER_URL; // 실제 서버 URL로 변경하세요.
// const API_BASE_URL = 'http://localhost:5000'; // 실제 서버 URL로 변경하세요.

export default function ClientReqWorkerForm({ match, history, location }) {
	const dispatch = useDispatch();
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { yearMonth, paydocus, filter, clientInfo } = useSelector(
		(state) => state.companyReducer
	);
	const { loading, error } = useSelector((state) => state.async);
	const { clientReqs4In, workerRegistryType, selectedClientReq4In, activeTab } =
		useSelector((state) => state.clientReq4InStore);
	const [perEvalOrNot, setPerEvalOrNot] = useState('');
	const [sumOfWorkDays, setSumOfWorkDays] = useState('');
	const [eiOutDetailType, setEiOutDetailType] = useState('');
	const [workerYouth, setWorkerYouth] = useState(true);
	const [noticeFireOrNot, setNoticeFireOrNot] = useState(false);
	const [firedPaperOrNot, setFiredPaperOrNot] = useState(false);

	const [altWorker, setAltWorker] = useState(
		selectedClientReq4In?.originalWorkerLeaveStartDate ? true : false
	);
	const [openPensionPayType, setOpenPensionPayType] = useState(
		selectedClientReq4In?.pensionBaseWage ? true : false
	);
	const [openHealthCarePayType, setOpenHealthCarePayType] = useState(
		selectedClientReq4In?.healthCareBaseWage ? true : false
	);
	const [openNoJobPayType, setOpenNoJobPayType] = useState(
		selectedClientReq4In?.noJobBaseWage ? true : false
	);
	const [openOldCarePayType, setOpenOldCarePayType] = useState(
		selectedClientReq4In?.oldCareBaseWage ? true : false
	);
	const [noJobPay, setNoJobPay] = useState(
		selectedClientReq4In?.noJobPayYesType ||
			selectedClientReq4In?.noJobPayNoType
			? true
			: false
	);
	const [retiredDate, setRetiredDate] = useState('');
	const [enterDate, setEnterDate] = useState(
		selectedClientReq4In?.workerEnteredDate
	);
	const [editingLeave, setEditingLeave] = useState(null);
	const [formValues, setFormValues] = useState({});

	const hostUid = currentUserProfile?.id;

	const lastPreventData = lastPreventDateF(clientReqs4In);
	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const personalEvaluation =
		(clientInfo?.personalEvaluation
			? clientInfo?.personalEvaluation
			: currentUserProfile?.personalEvaluation) ===
		'BSC 기반 인사고과 기능 사용'
			? true
			: false;
	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;

	const isMounted = useRef(true);

	useEffect(() => {
		if (checkNoUserInfo(currentUserProfile)) {
			redirectToAppropriatePage(currentUserProfile, history);
		}
	}, [currentUserProfile, history]);

	useEffect(() => {
		isMounted.current = true; // 컴포넌트가 마운트되었는지 추적하는 플래그

		if (!selectedClientReq4In?.id) return;

		const fetchData = async () => {
			try {
				await dispatch(
					fetchPaydocusFormFirestoreService4In(
						'isWorker',
						yearMonth,
						selectedClientReq4In?.id,
						clientInfo
					)
				);
				if (isMounted.current) {
					// 컴포넌트가 여전히 마운트된 상태에서만 상태 업데이트
					// setSomeState(someData); // 예시로 상태 업데이트
				}
			} catch (error) {
				console.error(error);
			}
		};

		fetchData();

		return () => {
			isMounted.current = false; // 컴포넌트가 언마운트되면 isMounted를 false로 설정
			dispatch(clearPaydocu());
		};
	}, [dispatch, yearMonth, selectedClientReq4In?.id, clientInfo]);

	useEffect(() => {
		if (location.pathname !== '/createClientReq4In') return;
		dispatch(clearSelectedClientReq4In());
	}, [location.pathname, dispatch]);

	const data = selectedClientReq4In;
	const decryptoData = {};
	for (const prop in data) {
		decryptoData[prop] =
			prop === 'workerSocialNumberFront' ||
			prop === 'workerSocialNumberBack' ||
			prop === 'familySocialNumber' ||
			prop === 'healthCarePeopleComments'
				? decrypto(data[prop], String(process.env.CRYPTO_KEY))
				: data[prop];
	}

	const isExceptTimeLawWorker = selectedClientReq4In?.isExceptTimeLawWorker
		? selectedClientReq4In?.isExceptTimeLawWorker
		: false;
	const isEtcWorker = selectedClientReq4In?.isEtcWorker
		? selectedClientReq4In?.isEtcWorker
		: false;
	const isAgent = isAgentF(
		currentUserProfile?.agentType,
		currentUserProfile?.authLevel
	);
	const agentType = clientInfo?.agentType
		? clientInfo?.agentType
		: currentUserProfile?.agentType;
	const isCoWorkSemusa =
		isAgent === '스폰서' && agentType === 'bs세무사' ? true : false;
	const numOfWorkers = clientInfo?.numOfWorkers
		? clientInfo?.numOfWorkers
		: currentUserProfile?.numOfWorkers;
	// 임금명세서 만들기용

	const agentUid = clientInfo?.agentUid ? clientInfo?.agentUid : null;

	const maternityLeaveStartDateValue =
		selectedClientReq4In?.maternityLeaves?.length > 0
			? (formValues?.type === 'maternityLeave' && formValues?.startDate) ||
			  decryptoData?.maternityLeaveStartDate
			: '';
	const maternityLeaveEndDateValue =
		selectedClientReq4In?.maternityLeaves?.length > 0
			? (formValues?.type === 'maternityLeave' && formValues?.endDate) ||
			  decryptoData?.maternityLeaveEndDate
			: '';
	const maternityLeaveTypeValue =
		selectedClientReq4In?.maternityLeaves?.length > 0
			? (formValues?.type === 'maternityLeave' && formValues?.leaveType) ||
			  decryptoData?.maternityLeaveType
			: '';
	const childLeaveOfAbsenceStartDateValue =
		selectedClientReq4In?.childLeaveOfAbsences?.length > 0
			? (formValues?.type === 'childLeaveOfAbsence' && formValues?.startDate) ||
			  decryptoData?.childLeaveOfAbsenceStartDate
			: '';
	const childLeaveOfAbsenceEndDateValue =
		selectedClientReq4In?.childLeaveOfAbsences?.length > 0
			? (formValues?.type === 'childLeaveOfAbsence' && formValues?.endDate) ||
			  decryptoData?.childLeaveOfAbsenceEndDate
			: '';
	const childLeaveOfAbsenceTypeValue =
		selectedClientReq4In?.childLeaveOfAbsences?.length > 0
			? (formValues?.type === 'childLeaveOfAbsence' && formValues?.leaveType) ||
			  decryptoData?.childLeaveOfAbsenceType
			: '';
	const childShortTimeStartDateValue =
		selectedClientReq4In?.childShortTimes?.length > 0
			? (formValues?.type === 'childShortTime' && formValues?.startDate) ||
			  decryptoData?.childShortTimeStartDate
			: '';
	const childShortTimeEndDateValue =
		selectedClientReq4In?.childShortTimes?.length > 0
			? (formValues?.type === 'childShortTime' && formValues?.endDate) ||
			  decryptoData?.childShortTimeEndDate
			: '';
	const childShortTimeTypeValue =
		selectedClientReq4In?.childShortTimes?.length > 0
			? (formValues?.type === 'childShortTime' && formValues?.leaveType) ||
			  decryptoData?.childShortTimeType
			: '';
	const leaveOfAbsenceStartDateValue =
		selectedClientReq4In?.leaveOfAbsences?.length > 0
			? (formValues?.type === 'leaveOfAbsence' && formValues?.startDate) ||
			  decryptoData?.leaveOfAbsenceStartDate
			: '';
	const leaveOfAbsenceEndDateValue =
		selectedClientReq4In?.leaveOfAbsences?.length > 0
			? (formValues?.type === 'leaveOfAbsence' && formValues?.endDate) ||
			  decryptoData?.leaveOfAbsenceEndDate
			: '';
	const leaveOfAbsenceTypeValue =
		selectedClientReq4In?.leaveOfAbsences?.length > 0
			? (formValues?.type === 'leaveOfAbsence' && formValues?.leaveType) ||
			  decryptoData?.leaveOfAbsenceType
			: '';

	const initialValues = {
		companyName: decryptoData?.companyName || companyName,
		company: decryptoData?.company || {
			ceoName: '',
			addr: '',
			companyName: '',
		},
		over52Permission: decryptoData?.over52Permission || '',
		worker: decryptoData?.worker || {
			name: '',
			comNumber: '',
			email: '',
			bank: '',
			bankAccount: '',
			phone: '',
			address: '',
			ability: '',
			schoolGrade: '',
			companyExperience: '',
			militaryService: '',
			memo: '',
		},
		workerEnteredDate: decryptoData?.workerEnteredDate || '',
		workerSocialNumberFront: decryptoData?.workerSocialNumberFront || '',
		workerSocialNumberBack: decryptoData?.workerSocialNumberBack || '',
		workerTitles: decryptoData?.workerTitles || '',
		foreigner: decryptoData?.foreigner || {
			nationality: '',
			visaType: '',
			englishName: '',
			selfNoJobPay: '', // 1-1
		},

		workerDivisions: decryptoData?.workerDivisions || '',
		noJobPayDetail: decryptoData?.noJobPayDetail || '',
		workDetailField: decryptoData?.workDetailField || '',
		workField: decryptoData?.workField || '',

		// 1-1
		pensionIn: decryptoData?.pensionIn || '',
		healthIn: decryptoData?.healthIn || '',
		workerSupportFund: decryptoData?.workerSupportFund || '',
		pensionIn4PublicJob: decryptoData?.pensionIn4PublicJob || '',
		healthIn4Discount: decryptoData?.healthIn4Discount || '',
		healthCarePeople: decryptoData?.healthCarePeople || {
			familyRelation: '',
			familyName: '',
			handyCapFamily: '',
			foreignerFamily: '',
		},
		familySocialNumber: decryptoData?.familySocialNumber || '',
		healthCarePeopleComments: decryptoData?.healthCarePeopleComments || '',

		//WorkerFormStep1ContractType
		contractOldWorker: decryptoData?.contractOldWorker || '',
		contractEndDate: decryptoData?.contractEndDate || '',
		againContractStartDate: decryptoData?.againContractStartDate || '',
		traineeStartDate: decryptoData?.traineeStartDate || '',
		traineeEndDate: decryptoData?.traineeEndDate || '',
		oldRetireDate: decryptoData?.oldRetireDate || '',
		afterRetireAgainContractStartDate:
			decryptoData?.afterRetireAgainContractStartDate || '',
		afterRetireAgainContractEndDate:
			decryptoData?.afterRetireAgainContractEndDate || '',

		//CrwfWorkerBasicInfoSupportFund
		workerYouthType: decryptoData?.workerYouthType || '',
		altWorker: decryptoData?.altWorker || '',
		originalWorkerLeaveStartDate:
			decryptoData?.originalWorkerLeaveStartDate || '',
		originalWorkerLeaveEndDate: decryptoData?.originalWorkerLeaveEndDate || '',

		//WorkerFormStep1Hr
		workerPersonalEvaluation: decryptoData?.workerPersonalEvaluation || '',
		workerPersonalEvaluationNoReason:
			decryptoData?.workerPersonalEvaluationNoReason || '',
		workerPersonalEvaluationView:
			decryptoData?.workerPersonalEvaluationView || '',

		//CrwfWorkerBasicInfoChildLeaves
		maternityLeaveType: maternityLeaveTypeValue,
		maternityLeaveStartDate: maternityLeaveStartDateValue,
		maternityLeaveEndDate: maternityLeaveEndDateValue,

		childLeaveOfAbsenceType: childLeaveOfAbsenceTypeValue,
		childLeaveOfAbsenceStartDate: childLeaveOfAbsenceStartDateValue,
		childLeaveOfAbsenceEndDate: childLeaveOfAbsenceEndDateValue,

		childShortTimeType: childShortTimeTypeValue,
		childShortTimeStartDate: childShortTimeStartDateValue,
		childShortTimeEndDate: childShortTimeEndDateValue,

		leaveOfAbsenceType: leaveOfAbsenceTypeValue,
		leaveOfAbsenceStartDate: leaveOfAbsenceStartDateValue,
		leaveOfAbsenceEndDate: leaveOfAbsenceEndDateValue,

		//CrwfLaborContractTimeInfo
		paidOffdays: decryptoData?.paidOffdays || '',
		selectWorkDays1: decryptoData?.selectWorkDays1 || [],
		selectWorkDays1Info: decryptoData?.selectWorkDays1Info || {
			skipWeek: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDay1: decryptoData?.plusWorkDay1 || '',

		selectWorkDays2: decryptoData?.selectWorkDays2 || [],
		selectWorkDays2Info: decryptoData?.selectWorkDays2Info || {
			skipWeek: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDay2: decryptoData?.plusWorkDay2 || '',

		selectWorkDays3: decryptoData?.selectWorkDays3 || [],
		selectWorkDays3Info: decryptoData?.selectWorkDays3Info || {
			skipWeek: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDay3: decryptoData?.plusWorkDay3 || '',

		selectWorkDays4: decryptoData?.selectWorkDays4 || [],
		selectWorkDays4Info: decryptoData?.selectWorkDays4Info || {
			skipWeek: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDay4: decryptoData?.plusWorkDay4 || '',

		selectWorkDays5: decryptoData?.selectWorkDays5 || [],
		selectWorkDays5Info: decryptoData?.selectWorkDays5Info || {
			skipWeek: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDay5: decryptoData?.plusWorkDay5 || '',

		selectWorkDays6: decryptoData?.selectWorkDays6 || [],
		selectWorkDays6Info: decryptoData?.selectWorkDays6Info || {
			skipWeek: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},

		//CrwfExceptTimeLawLaborContractTimeInfo
		selectWorkDaysETL1: decryptoData?.selectWorkDaysETL1 || [],
		selectWorkDaysETL1Info: decryptoData?.selectWorkDaysETL1Info || {
			shiftWork: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDayETL1: decryptoData?.plusWorkDayETL1 || '',

		selectWorkDaysETL2: decryptoData?.selectWorkDaysETL2 || [],
		selectWorkDaysETL2Info: decryptoData?.selectWorkDaysETL2Info || {
			shiftWork: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDayETL2: decryptoData?.plusWorkDayETL2 || '',

		selectWorkDaysETL3: decryptoData?.selectWorkDaysETL3 || [],
		selectWorkDaysETL3Info: decryptoData?.selectWorkDaysETL3Info || {
			shiftWork: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDayETL3: decryptoData?.plusWorkDayETL3 || '',

		selectWorkDaysETL4: decryptoData?.selectWorkDaysETL4 || [],
		selectWorkDaysETL4Info: decryptoData?.selectWorkDaysETL4Info || {
			shiftWork: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDayETL4: decryptoData?.plusWorkDayETL4 || '',

		selectWorkDaysETL5: decryptoData?.selectWorkDaysETL5 || [],
		selectWorkDaysETL5Info: decryptoData?.selectWorkDaysETL5Info || {
			shiftWork: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDayETL5: decryptoData?.plusWorkDayETL5 || '',

		selectWorkDaysETL6: decryptoData?.selectWorkDaysETL6 || [],
		selectWorkDaysETL6Info: decryptoData?.selectWorkDaysETL6Info || {
			shiftWork: '',
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		plusWorkDayETL6: decryptoData?.plusWorkDayETL6 || '',
		selectWorkDaysETL7: decryptoData?.selectWorkDaysETL7 || [],
		expectYearly7thWorkDayCounts:
			decryptoData?.expectYearly7thWorkDayCounts || '',
		expectYearly7thDaysETLInfo: decryptoData?.expectYearly7thDaysETLInfo || {
			workingStartTime: '',
			workingEndTimeNextDayOrNot: '',
			workingEndTime: '',
			dayBreakTimes: '',
			nightBreakTimes: '',
			breakTimeContents: '',
		},
		expectWeeklyOverETRWorks: decryptoData?.expectWeeklyOverETRWorks || '',
		expectWeeklyNightETRWorks: decryptoData?.expectWeeklyNightETRWorks || '',
		annualLeavesIncludeOrNot: decryptoData?.annualLeavesIncludeOrNot || '',

		//CrwfEtcLaborContractTimeInfo
		etc: decryptoData?.etc || {
			paidOffdays: '',
			paidHolidayLBTime: '',
			weekLBTime: '',
			monthOWT: '',
			monthNWT: '',
			monthHoliBasicTime: '',
			monthOWTHoli: '',
			monthNWTHoli: '',
			workTimeComment: '',
		},

		//WorkerFormTimeComments
		workTimeComment: decryptoData?.workTimeComment || '',

		//WorkerFormTimeWhole
		expectWeeklyOverWorks: decryptoData?.expectWeeklyOverWorks || '',
		expectWeeklyNightWorks: decryptoData?.expectWeeklyNightWorks || '',
		expectYearlyHolidayWorks: decryptoData?.expectYearlyHolidayWorks || 0,
		expectYearlyHolidayWorksInfo:
			decryptoData?.expectYearlyHolidayWorksInfo || {
				workingStartTime: '',
				workingEndTimeNextDayOrNot: '',
				workingEndTime: '',
				dayBreakTimes: '',
				nightBreakTimes: '',
				breakTimeContents: '',
			},

		//CrwfWorkerBasicInfoMonthWageInfo
		totalMonthWageAmount: decryptoData?.totalMonthWageAmount || '',
		wageContractStartDate: decryptoData?.wageContractStartDate || '',
		wageContarctEndDate: decryptoData?.wageContarctEndDate || '',
		monthlyPay: decryptoData?.monthlyPay || {
			startToEndDate: '',
			payDay: '',
			payDayBeforeAfter: '',
		},

		//WorkerFormWageComments
		wageComment: decryptoData?.wageComment || '',

		//CrwfWorkerBasicInfoTax4In
		taxPayType: decryptoData?.taxPayType || '',
		numOfTaxFamily: decryptoData?.numOfTaxFamily || 1,
		numOfTaxFamily7To20: decryptoData?.numOfTaxFamily7To20 || 0,
		pensionPayType: decryptoData?.pensionPayType || '',
		pensionBaseWage: decryptoData?.pensionBaseWage || '',
		healthCarePayType: decryptoData?.healthCarePayType || '',
		healthCareBaseWage: decryptoData?.healthCareBaseWage || '',
		oldCarePayType: decryptoData?.oldCarePayType || '',
		oldCareBaseWage: decryptoData?.oldCareBaseWage || '',
		noJobBaseWage: decryptoData?.noJobBaseWage || '',
		noJobPayType: decryptoData?.noJobPayType || '',

		//CrwfWorkerBasicInfoMonthWageOrd
		taxFreeWages: decryptoData?.taxFreeWages || {
			mealWage: '',
			carWage: '',
			childWage: '',
			studyWage: '',
			etc: '',
			etcComment: '',
		},
		ordinaryWage: decryptoData?.ordinaryWage || {
			title: '',
			job: '',
			license: '',
			monthlyBonus: '',
			longTerm: '',
			etc: '',
			etcComment: '',
			childManageStudy: '',
			childStudy: '',
			childCare: '',
			childActivity: '',
		},

		//CrwfWorkerBasicInfoMonthWageAver
		averageWageTF: decryptoData?.averageWageTF || {
			irregularAWage1: '',
			irregularAWCondition1: '',
			irregularAWName1: '',
			irregularAWage2: '',
			irregularAWCondition2: '',
			irregularAWName2: '',
			irregularAWage3: '',
			irregularAWCondition3: '',
			irregularAWName3: '',
			irregularAWage4: '',
			irregularAWCondition4: '',
			irregularAWName4: '',
		},
		averageWage: decryptoData?.averageWage || {
			irregularAWage1: '',
			irregularAWCondition1: '',
			irregularAWName1: '',
			irregularAWage2: '',
			irregularAWCondition2: '',
			irregularAWName2: '',
			irregularAWage3: '',
			irregularAWCondition3: '',
			irregularAWName3: '',
			irregularAWage4: '',
			irregularAWCondition4: '',
			irregularAWName4: '',
		},

		//CrwfWorkerRetireInfo41
		retiredDate: decryptoData?.retiredDate || '', //일용직은 4대보험 설정부분에 위치함
		eiOutType: decryptoData?.eiOutType || '',
		eiOutDetailType: decryptoData?.eiOutDetailType || '',
		eiOutReason: decryptoData?.eiOutReason || '',

		retiredPaperType: decryptoData?.retiredPaperType || '',
		firedPaperType: decryptoData?.firedPaperType || '',
		judgeIlligalFire: decryptoData?.judgeIlligalFire || '',
		under3MonthWorker: decryptoData?.under3MonthWorker || '',
		noticeFire: decryptoData?.noticeFire || '',
		noticeFireException: decryptoData?.noticeFireException || '',

		//CrwfWorkerRetireInfo42
		noJobPay: decryptoData?.noJobPay || '',
		noJobPayYesType: decryptoData?.noJobPayYesType || '',
		noJobPayNoType: decryptoData?.noJobPayNoType || '',
		pensionOutType: decryptoData?.pensionOutType || '',
		healthOutType: decryptoData?.healthOutType || '',
		comment4InsureOut: decryptoData?.comment4InsureOut || '',

		//CrwfWorkerRetireInfo43
		annualLeavesPay: decryptoData?.annualLeavesPay || '',
		compensationVacationPay: decryptoData?.compensationVacationPay || '',
		yearEndRefundPay: decryptoData?.yearEndRefundPay || '',
		severancePay: decryptoData?.severancePay || '',
		comment4InsureOutMemo: decryptoData?.comment4InsureOutMemo || '',
	};

	const prevWorker = selectedClientReq4In?.worker.name;
	const validationSchemaEmployee4In = Yup.object({
		//직원 기초정보 ok
		worker: Yup.object({
			name: Yup.string().required('필수항목'),
		}),
		workerSocialNumberFront: Yup.string().required('필수항목'),
		workerSocialNumberBack: Yup.string().required('필수항목'),
		workerEnteredDate: Yup.string().required('필수항목'),
		workerDivisions: Yup.string().required('필수항목'),
		workerTitles: Yup.string().required('필수항목'),
		workDetailField: Yup.string().required('필수항목'),
		totalMonthWageAmount: Yup.string().required('필수항목'),
		wageContractStartDate: Yup.string().required('필수항목'),
		wageContarctEndDate: Yup.string().required('필수항목'),
		monthlyPay: Yup.object({
			startToEndDate: Yup.string().required('필수항목'),
			payDay: Yup.string().required('필수항목'),
		}),

		pensionOutType: Yup.string().when('retiredDate', {
			is: (retiredDate) => !!retiredDate, // retiredDate가 있을 때 (truthy 일 때)
			then: Yup.string().required('필수항목'), // 필수로 만든다.
			otherwise: Yup.string().nullable(), // 그렇지 않으면 선택적으로 만든다.
		}),

		healthOutType: Yup.string().when('retiredDate', {
			is: (retiredDate) => !!retiredDate, // retiredDate가 있을 때 (truthy 일 때)
			then: Yup.string().required('필수항목'), // 필수로 만든다.
			otherwise: Yup.string().nullable(), // 그렇지 않으면 선택적으로 만든다.
		}),

		eiOutType: Yup.string().when('retiredDate', {
			is: (retiredDate) => !!retiredDate, // retiredDate가 있을 때 (truthy 일 때)
			then: Yup.string().required('필수항목'), // 필수로 만든다.
			otherwise: Yup.string().nullable(), // 그렇지 않으면 선택적으로 만든다.
		}),

		eiOutDetailType: Yup.string().when('retiredDate', {
			is: (retiredDate) => !!retiredDate, // retiredDate가 있을 때 (truthy 일 때)
			then: Yup.string().required('필수항목'), // 필수로 만든다.
			otherwise: Yup.string().nullable(), // 그렇지 않으면 선택적으로 만든다.
		}),
	});

	useFirestoreDoc({
		query: () =>
			match.params.id
				? listenToClientReq4InFromFirestore(match.params.id)
				: null,
		data: (clientReq4In) =>
			dispatch(listenToSelectedClientReq4In(clientReq4In)),
		deps: [match.params.id, dispatch],
		shouldExecute:
			match.params.id !== selectedClientReq4In?.id &&
			location.pathname !== '/createClientReq4In',
	});

	//탭 메뉴
	const handleTabChange = (e, { activeIndex }) => {
		dispatch(setActiveTab(activeIndex));
	};

	const now = new Date();
	const enteredDateC = new Date(
		enterDate || selectedClientReq4In?.workerEnteredDate
	);
	const initialRetiredDate = selectedClientReq4In?.retiredDate
		? selectedClientReq4In?.retiredDate
		: '';
	const retiredDateC = retiredDate ? new Date(retiredDate) : '';
	const currentYearMonth = `${now.getFullYear()}-${String(
		now.getMonth() + 1
	).padStart(2, '0')}`; // 현재 년도와 월을 "YYYY-MM" 형식으로 생성
	const enteredYearMonth = `${enteredDateC.getFullYear()}-${String(
		enteredDateC.getMonth() + 1
	).padStart(2, '0')}`;
	const retiredYearMonth = retiredDateC
		? `${retiredDateC.getFullYear()}-${String(
				retiredDateC.getMonth() + 1
		  ).padStart(2, '0')}`
		: '';
	const isWorker = currentUserProfile?.isWorker;

	function hasRetireDateChanged(initialRetiredDate, retiredDateC) {
		if (!initialRetiredDate) return true; // 기존 데이터가 없으면 변경된 것으로 간주
		if (!retiredDateC) return false; // 새로운 데이터가 없으면 변경되지 않은 것으로 간주
		const result =
			new Date(initialRetiredDate).getTime() !==
			new Date(retiredDateC).getTime()
				? true
				: false;
		return result;
	}
	const retiredDateChanged = hasRetireDateChanged(
		initialRetiredDate,
		retiredDateC
	);

	if (loading)
		return <LoadingComponent content='Loading Worker Info edit ...' />;
	if (error) return <Redirect to='/error' />;

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchemaEmployee4In}
				onSubmit={async (values, { setSubmitting }) => {
					isMounted.current = true; // 컴포넌트가 마운트되었는지 추적하는 플래그

					try {
						let documentId = null;
						if (
							selectedClientReq4In &&
							selectedClientReq4In?.worker.name === values?.worker.name
						) {
							documentId = await updateClientReq4InInFirestore(
								values,
								clientInfo,
								selectedClientReq4In.id
							);
						} else {
							documentId = await addClientReq4InToFirestore(
								values,
								agentUid,
								clientInfo,
								''
							);
						}

						if (
							selectedClientReq4In?.worker?.name !== values?.worker?.name &&
							enteredYearMonth >= currentYearMonth &&
							!retiredYearMonth
						) {
							axios
								.post(`${API_BASE_URL}/add-in-out-worker-wage`, {
									hostUid,
									values,
									documentId,
									clientInfo,
									thisMonthWorker: true,
									beforeMonthWorker: false,
									workerRetiredYearMonth: '',
								})
								.catch((error) => {
									if (isMounted.current) {
										console.error('Error:', error);
										toast.error(error.message);
									}
								})
								.finally(() => {
									if (isMounted.current) {
										setSubmitting(false);
									}
								});
						}

						if (
							selectedClientReq4In?.worker?.name !== values?.worker?.name &&
							enteredYearMonth < currentYearMonth &&
							!retiredYearMonth
						) {
							axios
								.post(`${API_BASE_URL}/add-in-out-worker-wage`, {
									hostUid,
									values,
									documentId,
									clientInfo,
									thisMonthWorker: false,
									beforeMonthWorker: true,
									workerRetiredYearMonth: '',
								})
								.catch((error) => {
									if (isMounted.current) {
										console.error('Error:', error);
										toast.error(error.message);
									}
								})
								.finally(() => {
									if (isMounted.current) {
										setSubmitting(false);
									}
								});
						}

						if (
							selectedClientReq4In?.worker?.name === values?.worker?.name &&
							retiredYearMonth
						) {
							axios
								.post(`${API_BASE_URL}/add-in-out-worker-wage`, {
									hostUid,
									values,
									documentId,
									clientInfo,
									thisMonthWorker: false,
									beforeMonthWorker: false,
									workerRetiredYearMonth: retiredYearMonth,
								})
								.then(async (response) => {
									if (retiredDateChanged) {
										await updateDoc(
											doc(db, 'workers', selectedClientReq4In?.id),
											{
												retireInfo: deleteField(),
												retireInfoSign: deleteField(),
												isInsureOutRequest: deleteField(),
												isInsureOutRequestDate: deleteField(),
											}
										);
										if (initialRetiredDate && retiredDateChanged) {
											alert(
												'퇴직일자 변경으로 일할계산 정보, 퇴직정산, 4대보험 신청 정보가 변경됩니다.'
											);
											alert(
												'퇴직월 급여/퇴직정산을 다시 하신 후 4대보험 신고 Data를 다시 생성하세요.'
											);
										}
										if (isMounted.current) {
											history.push(
												`/manageClientRDWageTable/${
													selectedClientReq4In?.id
												}?yearMonth=${retiredDateC.getFullYear()}${String(
													retiredDateC.getMonth() + 1
												).padStart(2, '0')}`
											);
										}
									} else {
										if (isMounted.current) {
											history.push('/paydocus');
										}
									}
								})
								.catch((error) => {
									if (isMounted) {
										console.error('Error:', error);
										toast.error(error.message);
									}
								})
								.finally(() => {
									if (isMounted.current) {
										setSubmitting(false);
									}
								});
						}

						if (editingLeave) {
							const { type, data } = editingLeave;
							const startDateKey =
								type === 'maternityLeave'
									? 'maternityLeaveStartDate'
									: type === 'childLeaveOfAbsence'
									? 'childLeaveOfAbsenceStartDate'
									: type === 'childShortTime'
									? 'childShortTimeStartDate'
									: 'leaveOfAbsenceStartDate';
							const endDateKey =
								type === 'maternityLeave'
									? 'maternityLeaveEndDate'
									: type === 'childLeaveOfAbsence'
									? 'childLeaveOfAbsenceEndDate'
									: type === 'childShortTime'
									? 'childShortTimeEndDate'
									: 'leaveOfAbsenceEndDate';
							const typeKey =
								type === 'maternityLeave'
									? 'maternityLeaveType'
									: type === 'childLeaveOfAbsence'
									? 'childLeaveOfAbsenceType'
									: type === 'childShortTime'
									? 'childShortTimeType'
									: 'leaveOfAbsenceType';

							const convertToFirestoreTimestamp = (date) => {
								if (typeof date === 'string' && !isNaN(Date.parse(date))) {
									const milliseconds = new Date(date).getTime();
									const seconds = Math.floor(milliseconds / 1000);
									return { seconds: seconds, nanoseconds: 0 };
								}
								return date;
							};

							const updatedValues = {
								[startDateKey]:
									convertToFirestoreTimestamp(values[startDateKey]) || null,
								[endDateKey]:
									convertToFirestoreTimestamp(values[endDateKey]) || null,
								[typeKey]: values[typeKey] || null,
							};

							if (type === 'maternityLeave') {
								await updateMaternityLeaveInWorkerFirestore(
									selectedClientReq4In.id,
									data.id,
									updatedValues
								);
							} else if (type === 'childLeaveOfAbsence') {
								await updateChildLeaveOfAbsenceInWorkerFirestore(
									selectedClientReq4In.id,
									data.id,
									updatedValues
								);
							} else if (type === 'childShortTime') {
								await updateChildShortTimeInWorkerFirestore(
									selectedClientReq4In.id,
									data.id,
									updatedValues
								);
							} else if (type === 'leaveOfAbsence') {
								await updateLeaveOfAbsenceInWorkerFirestore(
									selectedClientReq4In.id,
									data.id,
									updatedValues
								);
							}
						} else {
							if (
								selectedClientReq4In &&
								selectedClientReq4In?.id === documentId &&
								(values?.maternityLeaveStartDate ||
									values?.maternityLeaveEndDate ||
									values?.maternityLeaveType) &&
								selectedClientReq4In?.maternityLeaveStartDate !==
									values?.maternityLeaveStartDate
							) {
								await addMaternityLeaveToWorkerFirestore(documentId, values);
							}

							if (
								selectedClientReq4In &&
								selectedClientReq4In?.id === documentId &&
								(values?.childLeaveOfAbsenceStartDate ||
									values?.childLeaveOfAbsenceEndDate ||
									values?.childLeaveOfAbsenceType) &&
								selectedClientReq4In?.childLeaveOfAbsenceStartDate !==
									values?.childLeaveOfAbsenceStartDate
							) {
								await addChildLeaveOfAbsenceToWorkerFirestore(
									documentId,
									values
								);
							}

							if (
								selectedClientReq4In &&
								selectedClientReq4In?.id === documentId &&
								(values?.childShortTimeStartDate ||
									values?.childShortTimeEndDate ||
									values?.childShortTimeType) &&
								selectedClientReq4In?.childShortTimeStartDate !==
									values?.childShortTimeStartDate
							) {
								await addChildShortTimeToWorkerFirestore(documentId, values);
							}

							if (
								selectedClientReq4In &&
								selectedClientReq4In?.id === documentId &&
								(values?.leaveOfAbsenceStartDate ||
									values?.leaveOfAbsenceEndDate ||
									values?.leaveOfAbsenceType) &&
								selectedClientReq4In?.leaveOfAbsenceStartDate !==
									values?.leaveOfAbsenceStartDate
							) {
								await addLeaveOfAbsenceToWorkerFirestore(documentId, values);
							}

							if (
								selectedClientReq4In &&
								selectedClientReq4In?.id === documentId &&
								values?.totalMonthWageAmount
							) {
								await addTotalWagesArrayToWorkerFirestore(documentId, values);
							}
						}

						setEditingLeave(null);
						if (isMounted.current) {
							setSubmitting(false);
						}
						if (!initialRetiredDate || !retiredDateChanged) {
							if (isMounted.current) {
								history.push('/clientReqs4In');
							}
						}
						dispatch(clearSelectedClientReq4In());
						if (isMounted.current) {
							history.push('/clientReqs4In');
						}
					} catch (error) {
						if (isMounted.current) {
							console.error(error.message);
							toast.error(error.message);
							setSubmitting(false);
						}
					}

					return () => {
						isMounted.current = false; // 컴포넌트가 언마운트되면 isMounted를 false로 설정
					};
				}}
				enableReinitialize>
				{({ isSubmitting, dirty, isValid, values }) => (
					<Form className='ui form'>
						<Segment clearing>
							{lastPreventData && (
								<ClientReqLastPreventData
									lastPreventData={lastPreventData}
									authLevel={authLevel}
								/>
							)}

							<Tab
								menu={{ fluid: true, vertical: false, tabular: true }}
								panes={[
									{
										menuItem: '1. 기본인사정보',
										key: '기본인사정보',
										render: () => (
											<Tab.Pane>
												<CrwfWorkerBasicInfo
													isCoWorkSemusa={isCoWorkSemusa}
													setPerEvalOrNot={setPerEvalOrNot}
													perEvalOrNot={perEvalOrNot}
													setWorkerYouth={setWorkerYouth}
													workerYouth={workerYouth}
													values={values}
													personalEvaluation={personalEvaluation}
													currentUserProfile={currentUserProfile}
													setAltWorker={setAltWorker}
													altWorker={altWorker}
													authLevel={authLevel}
													setEnterDate={setEnterDate}
													prevWorker={prevWorker}
													over52Permission={values.over52Permission}
													selectedClientReq4In={selectedClientReq4In}
													setEditingLeave={setEditingLeave}
													setFormValues={setFormValues}
												/>
											</Tab.Pane>
										),
									},
									{
										menuItem: '2. 근로시간정보',
										key: '근로시간정보',
										render: () => (
											<Tab.Pane>
												<CrwfWorkerBasicTimeInfo
													isExceptTimeLawWorker={isExceptTimeLawWorker}
													isEtcWorker={isEtcWorker}
													workerRegistryType={workerRegistryType}
													values={values}
													selectedClientReq4In={selectedClientReq4In}
													setSumOfWorkDays={setSumOfWorkDays}
													authLevel={authLevel}
												/>
											</Tab.Pane>
										),
									},
									{
										menuItem: '3. 임금정보',
										key: '임금정보',
										render: () => (
											<Tab.Pane>
												<CrwfWorkerBasicWageInfo
													values={values}
													openPensionPayType={openPensionPayType}
													setOpenPensionPayType={setOpenPensionPayType}
													openHealthCarePayType={openHealthCarePayType}
													setOpenHealthCarePayType={setOpenHealthCarePayType}
													openNoJobPayType={openNoJobPayType}
													setOpenNoJobPayType={setOpenNoJobPayType}
													openOldCarePayType={openOldCarePayType}
													setOpenOldCarePayType={setOpenOldCarePayType}
													companyName={companyName}
												/>
											</Tab.Pane>
										),
									},
									{
										menuItem: '4. 퇴직정보',
										key: '퇴직정보',
										render: () => (
											<Tab.Pane>
												{authLevel >= 10 && (
													<CrwfWorkerRetireInfo
														numOfWorkers={numOfWorkers}
														selectedClientReq4In={selectedClientReq4In}
														retiredDate={retiredDate}
														eiOutDetailType={eiOutDetailType}
														setEiOutDetailType={setEiOutDetailType}
														noJobPay={noJobPay}
														setNoJobPay={setNoJobPay}
														noticeFireOrNot={noticeFireOrNot}
														setNoticeFireOrNot={setNoticeFireOrNot}
														firedPaperOrNot={firedPaperOrNot}
														setFiredPaperOrNot={setFiredPaperOrNot}
														paydocus={paydocus}
														workerEnteredDate={values.workerEnteredDate}
														workerRetiredDate={values.retiredDate}
														setRetiredDate={setRetiredDate}
													/>
												)}
											</Tab.Pane>
										),
									},
								]}
								activeIndex={activeTab}
								onTabChange={handleTabChange}
							/>
							<br />
							{selectedClientReq4In ? (
								<>
									{isExceptTimeLawWorker ? (
										<Button
											loading={isSubmitting}
											disabled={
												currentUserProfile?.email === 'freya0207@gmail.com' ||
												currentUserProfile?.email === 'paydac@naver.com'
													? false
													: !isValid || !dirty || isSubmitting
											}
											type='submit'
											floated='right'
											positive
											content='저장'
											size='huge'
										/>
									) : (
										<Button
											loading={isSubmitting}
											disabled={
												currentUserProfile?.email === 'freya0207@gmail.com' ||
												currentUserProfile?.email === 'paydac@naver.com'
													? false
													: !isValid || !dirty || isSubmitting
											}
											type='submit'
											floated='right'
											positive
											content='저장'
											size='huge'
										/>
									)}
									<Button
										disabled={isSubmitting}
										as={Link}
										to='/clientReqs4In'
										type='submit'
										floated='right'
										content='취소'
										size='huge'
									/>
								</>
							) : (
								<>
									<Button
										loading={isSubmitting}
										disabled={
											!isValid || !dirty || isSubmitting || sumOfWorkDays > 6
										}
										type='submit'
										floated='right'
										positive
										content='저장'
										size='huge'
									/>
									<Button
										disabled={isSubmitting}
										as={Link}
										to='/clientReqs4In'
										type='submit'
										floated='right'
										content='취소'
										size='huge'
									/>
								</>
							)}
						</Segment>
					</Form>
				)}
			</Formik>
		</>
	);
}
