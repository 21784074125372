import React, { useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, Grid } from 'semantic-ui-react';
import {
	josaGaType,
	josaUnType,
	josaWaType,
	numStringToNumber,
	paydacOwnerCompanyName,
	printRoe,
} from '../../../../app/common/util/util';
import { listenToClientReq4InFromFirestore } from '../../../../app/firestore/firestoreService4In';
import useFirestoreDoc from '../../../../app/hooks/useFirestoreDoc';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
	listenToSelectedClientReq4In,
} from '../clientReqActions4In';
import ClientReqDetailedHeader4In from './ClientReqDetailedHeader4In';
import ClientReqDLCHeadPerInfoAgree from './clientReqDetailedLCDetail/ClientReqDLCHeadPerInfoAgree';
import PaydocuShortCut from '../../../companyManage/paydocuDashboard/PaydocuShortCut';
import './ClientRDPersonalInfoAgree.css'; // Importing CSS file

export default function ClientRDPersonalInfoAgree({ match }) {
	const htmlContentRef = useRef(null);
	const dispatch = useDispatch();
	const { selectedClientReq4In, clientReqs4In } = useSelector(
		(state) => state.clientReq4InStore
	);
	const { clientInfo, filter } = useSelector((state) => state.companyReducer);
	const { loading, error } = useSelector((state) => state.async);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const worker = selectedClientReq4In?.worker?.name;

	const daylyTimePay = selectedClientReq4In?.daylyPay?.timeWage
		? numStringToNumber(selectedClientReq4In?.daylyPay?.timeWage)
		: 0;

	const isWorker = currentUserProfile?.isWorker;

	const companyName = isWorker
		? currentUserProfile?.workerInputCompanyName ||
		  selectedClientReq4In?.companyName
		: clientInfo?.companyName ||
		  selectedClientReq4In?.company?.companyName ||
		  selectedClientReq4In?.companyName;

	useFirestoreDoc({
		query: () => listenToClientReq4InFromFirestore(match.params.id),
		data: (selectedClientReq4In) =>
			dispatch(listenToSelectedClientReq4In(selectedClientReq4In)),
		deps: [match.params.id, dispatch],
	});

	useEffect(() => {
		dispatch(fetchClientReqs4In(filter));
		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	if (loading || (!selectedClientReq4In && !error))
		return <LoadingComponent content='Loading event ...' />;
	if (error) return <Redirect to='/error' />;

	const today = new Date();

	const companyWa = josaWaType(companyName);
	const companyGa = josaGaType(companyName);
	const workerUn = josaUnType(worker);
	const workerGa = josaGaType(worker);
	const workerWa = josaWaType(worker);

	const isDayOrTimeWorker =
		selectedClientReq4In?.dayWorker?.daylyPay || daylyTimePay;
	const printFunc = () => {
		printRoe();
	};
	return (
		<>
			{!isWorker && (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}

			<Grid>
				<Grid.Column width={16}>
					<ClientReqDetailedHeader4In
						selectedClientReq4In={selectedClientReq4In}
						companyName={companyName}
						clientReqs4In={clientReqs4In}
						daylyTimePay={daylyTimePay}
						isDayOrTimeWorker={isDayOrTimeWorker}
					/>

					<Button type='button' color='blue' onClick={() => printFunc()}>
						출력
					</Button>
					<br />
					<br />
					<div id='roeContents' ref={htmlContentRef}>
						<ClientReqDLCHeadPerInfoAgree
							companyName={companyName}
							worker={worker}
						/>
						<div class='laborContractStyle'>
							<div class='jang'>1. 수집 및 이용(활용)에 관한 사항</div>

							<div class='sectionup'>
								<div class='jau__title'>목적</div>
								<div class='jau__contents'>
									{worker}의 근로계약 체결 및 유지, 인시기록관리, 급여지급처리,
									각종 증명서 발급, 사회보험 업무처리, 기타 인사/노무 업무 처리
									등
								</div>
							</div>

							<div class='sectionup'>
								<div class='jau__title'>항목</div>
								<div class='jau__contents'>
									<ul>
										<li class='rule-item' data-index='0'>
											{worker}의 근로계약 및 노무관리 정보(이력서, 인사기록카드,
											주민등록등본 등 본인이 제출한 인사서류상 기재 정보, 사번,
											소속, 직무, 복무, 근무성적, 근태정보, 근무성적, 표상/징계,
											인사발령, 외부겸직, 퇴직정보 등 근로관계에 따라 발생,
											생성, 수집된 노무관리 정보)
										</li>
										<li class='rule-item' data-index='1'>
											{worker}의 CCTV 영상정보 또는 바이오정보(지문 등),
											-바이오정보는 대상자에 한함
										</li>
										<li class='rule-item' data-index='2'>
											{worker}의 채용신체검사서 및 정기건강검진 결과에 기재된
											건강정보 등 민감정보
										</li>
									</ul>
								</div>
							</div>

							<div class='sectionup'>
								<div class='jau__title'>기간</div>
								<div class='jau__contents'>
									위 {worker}의 개인정보는 수집, 이용에 관한 동의일로부터
									재직기간 동안, 그리고 근로기준법 제42조(계약 서류의 보존),
									시행령 제22조(보존 대상 서류 등)에서 정하는 바에 따라 서류별로
									각각 기산일로부터 3년간 보존되며, 기타 법령에서 정한 기간까지
									위 이용 목적을 위하여 보유되고 이용됩니다.
								</div>
							</div>

							<div class='sectionup'>
								<div class='jau__title'>동의 거부 권리 및 거부시 불이익</div>
								<div class='jau__contents'>
									위 {worker}의 개인정보의 수집, 이용에 관한 동의는 근로기준법,
									세법, 4대보험 법령 등에 따라 근로계약의 체결과 유지 및
									인사노무관리, 그리고 퇴직 후의 사업주 의무이행을 위해
									필수적으로 {workerGa} 동의하셔야지만 {companyWa}의 근로관계
									성립 및 유지가 가능합니다. 그럼에도 불구하고 {workerUn}{' '}
									개인정보보호법에 따라 동의를 거부하실 수 있으나, 동의하지
									않을시 근로계약의 체결이 불가능하고, 채용 후라 하더라도 위
									개인정보에 대한 동의가 없을시 근로관계 존속 및 유지 의사가
									없는 것으로 보아 {workerWa} {companyWa}의 근로관계가 종료됨을
									안내해드립니다.
								</div>
							</div>

							<div class='sectionup'>
								<div class='jau__title'>수집 및 이용 동의 여부</div>
								<div class='jau__contents'>
									{companyGa} {worker}의 개인정보 수집 및 이용하는 것에 대한
									동의 여부.
									<div align='center'>( □ 동의함, □ 동의하지 않음 )</div>
								</div>
							</div>

							<div class='sectionup'>
								<div class='jau__title'>
									고유식별정보 수집 및 이용 동의 여부
								</div>
								<div class='jau__contents'>
									{companyGa} 개인정보보호법 제24조(고유식별정보의 처리 제한) 및
									제24조의 2(주민등록번호 처리의 제한)에 근거하여 {worker}의
									고유식별정보를 수집 및 이용하는 것에 대한 동의 여부
									<div align='center'>( □ 동의함, □ 동의하지 않음 )</div>
								</div>
							</div>

							<div class='sectionup'>
								<div class='jau__title'>민감정보 수집 및 이용 동의 여부</div>
								<div class='jau__contents'>
									{companyGa} 개인정보보호법 제23조(민감정보의 처리 제한)에
									근거하여 {worker}의 민감정보를 수집 및 이용하는 것에 대한 동의
									여부
									<div align='center'>( □ 동의함, □ 동의하지 않음 )</div>
								</div>
							</div>
						</div>

						<div class='laborContractStyle'>
							<div class='jang'>2. 제3자 개인정보 제공에 관한 사항</div>

							<div class='sectionup'>
								<div class='jau__title'>
									{worker}의 개인정보를 제공받는 제3자
								</div>
								<div class='jau__contents'>
									<ul>
										<li class='rule-item' data-index='0'>
											{companyGa} 법령상 의무이행을 위한 공공기관:
											국세청(세무서), 고용노동부, 건강보험관리공단,
											국민연금관리공단 등
										</li>
										<li class='rule-item' data-index='1'>
											{companyGa} {worker}의 인사/노무/세무 관리 및 신고 등을
											위해 회사와 계약한 노무사, 세무사
										</li>
										<li class='rule-item' data-index='2'>
											{companyWa} 계약한 근로계약서 등 노무관리 SYSTEM인 AI
											NOMUSA 운영사인 {paydacOwnerCompanyName()}
										</li>
									</ul>
								</div>
							</div>

							<div class='sectionup'>
								<div class='jau__title'>
									{worker}의 개인정보를 제공받는 제3자 이용목적
								</div>
								<div class='jau__contents'>
									법령상 소관 업무수행 및 의무이행
								</div>
							</div>

							<div class='sectionup'>
								<div class='jau__title'>제공할 {worker}의 개인정보항목</div>
								<div class='jau__contents'>
									{workerGa} 수집 및 이용에 동의한 정보 중 해당 기관의 업무수행,
									위탁업무 목적 및 법령상 의무이행을 위하여 필요한 최소한의
									정보에 한하여 제공되며, {worker}의 개인정보 중 본 동의 이전에{' '}
									{workerWa} {companyWa}의 근로 관계로 인해 수집된 정보도
									포함됩니다.
								</div>
							</div>

							<div class='sectionup'>
								<div class='jau__title'>
									{worker}의 개인정보를 제공받는 제3자의 개인정보보유 이용기간
								</div>
								<div class='jau__contents'>
									{worker}의 개인정보는 제공된 날부터 제공된 목적을 달성할
									때까지 보유 및 이용됩니다.
									<br />
									제공된 목적 달성 후에는 위에 기재된 이용목적과 관련된 사회보험
									신고, 분쟁 해결, 민원처리, 법령상 의무이행만을 위해 필요한
									범위 내에서만 보유 및 이용됩니다.
								</div>
							</div>

							<div class='sectionup'>
								<div class='jau__title'>
									{worker}의 동의거부권리 및 거부시 불이익
								</div>
								<div class='jau__contents'>
									{worker}의 위 개인정보의 수집, 이용에 관한 동의는 근로기준법,
									세법, 4대보험 법령 등에 따라 근로계약의 체결과 유지 및
									인사노무관리, 그리고 퇴직 후의 사업주 의무이행을 위해
									필수적으로 {workerGa} 동의하셔야지만 근로관계 성립 및 유지가
									가능합니다. 그럼에도 불구하고 {workerUn} 개인정보보호법에 따라
									동의를 거부할 수 있으나, 동의하지 않을시 근로계약의 체결이
									불가능하고, 채용 후라 하더라도 위 개인정보에 대한 동의가
									없을시 근로관계 존속 및 유지 의사가 없는 것으로 보아{' '}
									{companyWa} {workerWa}의 근로관계는 종료됨을 안내해드립니다.
								</div>
							</div>

							<div class='sectionup'>
								<div class='jau__title'>개인정보 제공 동의 여부</div>
								<div class='jau__contents'>
									{companyGa} 상기의 제3자에게 {worker}의 개인정보 등을 제공하는
									것에 대한 동의 여부
									<div align='center'>( □ 동의함, □ 동의하지 않음 )</div>
								</div>
							</div>

							<div class='sectionup'>
								<div class='jau__title'>고유식별정보 제공 동의 여부</div>
								<div class='jau__contents'>
									{companyGa} 개인정보보호법 제24조(고유식별정보의 처리 제한) 및
									제24조의 2(주민등록번호 처리의 제한)에 근거하여 {worker}의
									고유식별정보를 상기 제3자에게 제공하는 것에 대한 동의 여부
									<div align='center'>( □ 동의함, □ 동의하지 않음 )</div>
								</div>
							</div>
						</div>

						<p align='center'>{format(today, 'yyyy-MM-dd')}</p>

						<p align='center'>
							위 개인정보 제공 및 수집 • 이용 • 활용 동의자 : {worker} (서명
							또는 인)
						</p>
					</div>
				</Grid.Column>
			</Grid>
		</>
	);
}
