import React, { useEffect, useState } from 'react';
import { Checkbox, Icon, Label } from 'semantic-ui-react';
import {
  selectWorkDays,
  selectPaidWeekHolidays,
  dayType,
  skipWeekType,
} from '../../../../../app/api/dropdownOption';
import MySelectInput from '../../../../../app/common/form/MySelectInput';
import MyTextInput from '../../../../../app/common/form/MyTextInput';
import MyTimeInput from '../../../../../app/common/form/MyTimeInput';
import MyNumberInput from '../../../../../app/common/form/MyNumberInput';
import { timeErrorAlert } from '../../../../../app/common/util/util';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../../app/common/modals/modalReducer';

export default function CrwfLaborContractTimeInfo({
  setSumOfWorkDays, 
  selectedClientReq4In,
  values
}) {
  const [alertShown, setAlertShown] = useState(false);
  const [holiAlertShown, setHoliAlertShown] = useState(false);
  const [daysExceededAlertShown, setDaysExceededAlertShown] = useState(false);

  const dispatch = useDispatch();

  const [plusWorkDay1, setPlusWorkDay1] = useState(selectedClientReq4In?.selectWorkDays2 ? true : false);
  const [plusWorkDay2, setPlusWorkDay2] = useState(selectedClientReq4In?.selectWorkDays3 ? true : false);
  const [plusWorkDay3, setPlusWorkDay3] = useState(selectedClientReq4In?.selectWorkDays4 ? true : false);
  const [plusWorkDay4, setPlusWorkDay4] = useState(selectedClientReq4In?.selectWorkDays5 ? true : false);
  const [plusWorkDay5, setPlusWorkDay5] = useState(selectedClientReq4In?.selectWorkDays6 ? true : false);
  const [paidHoli, setPaidHoli] = useState('');
  const [workDays1, setWorkDays1] = useState([]);
  const [workDays2, setWorkDays2] = useState([]);
  const [workDays3, setWorkDays3] = useState([]);
  const [workDays4, setWorkDays4] = useState([]);
  const [workDays5, setWorkDays5] = useState([]);
  const [workDays6, setWorkDays6] = useState([]);

  const sumOfWorkDays = workDays1?.length + workDays2?.length + workDays3?.length + workDays4?.length + workDays5?.length + workDays6?.length;
  
  useEffect(() => {
    setSumOfWorkDays(sumOfWorkDays);
  }, [setSumOfWorkDays, sumOfWorkDays]);

  function plusWorkDay1Handler () {
    setPlusWorkDay1(!plusWorkDay1);
  }

  function plusWorkDay2Handler () {
    setPlusWorkDay2(!plusWorkDay2);
  } 

  function plusWorkDay3Handler () {
    setPlusWorkDay3(!plusWorkDay3);
  } 

  function plusWorkDay4Handler () {
    setPlusWorkDay4(!plusWorkDay4);
  } 

  function plusWorkDay5Handler () {
    setPlusWorkDay5(!plusWorkDay5);
  } 

  function holiAlert() {
    if (!holiAlertShown) {
      alert(`주휴일이 포함되었습니다. 만약 주휴일도 근무한다면 해당 근무시간을 휴일근무시간으로 설정하세요.`);
      setHoliAlertShown(true);
    }
  }

  useEffect(() => {
    timeErrorAlert(values.selectWorkDays1Info?.workingStartTime, values.selectWorkDays1Info?.workingEndTime, values.selectWorkDays1Info?.workingEndTimeNextDayOrNot)
  }, [values.selectWorkDays1Info?.workingStartTime, values.selectWorkDays1Info?.workingEndTime, values.selectWorkDays1Info?.workingEndTimeNextDayOrNot]);

  useEffect(() => {
    timeErrorAlert(values.selectWorkDays2Info?.workingStartTime, values.selectWorkDays2Info?.workingEndTime, values.selectWorkDays2Info?.workingEndTimeNextDayOrNot)
  }, [values.selectWorkDays2Info?.workingStartTime, values.selectWorkDays2Info?.workingEndTime, values.selectWorkDays2Info?.workingEndTimeNextDayOrNot]);

  useEffect(() => {
    timeErrorAlert(values.selectWorkDays3Info?.workingStartTime, values.selectWorkDays3Info?.workingEndTime, values.selectWorkDays3Info?.workingEndTimeNextDayOrNot)
  }, [values.selectWorkDays3Info?.workingStartTime, values.selectWorkDays3Info?.workingEndTime, values.selectWorkDays3Info?.workingEndTimeNextDayOrNot]);

  useEffect(() => {
    timeErrorAlert(values.selectWorkDays4Info?.workingStartTime, values.selectWorkDays4Info?.workingEndTime, values.selectWorkDays4Info?.workingEndTimeNextDayOrNot)
  }, [values.selectWorkDays4Info?.workingStartTime, values.selectWorkDays4Info?.workingEndTime, values.selectWorkDays4Info?.workingEndTimeNextDayOrNot]);

  useEffect(() => {
    timeErrorAlert(values.selectWorkDays5Info?.workingStartTime, values.selectWorkDays5Info?.workingEndTime, values.selectWorkDays5Info?.workingEndTimeNextDayOrNot)
  }, [values.selectWorkDays5Info?.workingStartTime, values.selectWorkDays5Info?.workingEndTime, values.selectWorkDays5Info?.workingEndTimeNextDayOrNot]);

  useEffect(() => {
    timeErrorAlert(values.selectWorkDays6Info?.workingStartTime, values.selectWorkDays6Info?.workingEndTime, values.selectWorkDays6Info?.workingEndTimeNextDayOrNot)
  }, [values.selectWorkDays6Info?.workingStartTime, values.selectWorkDays6Info?.workingEndTime, values.selectWorkDays6Info?.workingEndTimeNextDayOrNot]);

  function workerRegStep2YTOpen() {
    dispatch(openModal({modalType:'WorkerRegStep2'}));
  }

  function workerRegStep2_1YTOpen() {
    dispatch(openModal({modalType:'WorkerRegStep2_1'}));
  }

  useEffect(() => {
    const allRequiredFieldsFilled = values.paidOffdays &&
      values.selectWorkDays1 &&
      values.selectWorkDays1Info?.workingStartTime &&
      values.selectWorkDays1Info?.workingEndTimeNextDayOrNot &&
      values.selectWorkDays1Info?.workingEndTime &&
      values.selectWorkDays1Info?.dayBreakTimes &&
      values.selectWorkDays1Info?.breakTimeContents;

    if (allRequiredFieldsFilled && !alertShown) {
      alert('2. 근로시간정보 필수 입력란을 모두 입력하셨습니다. "3.임금정보" 단계로 진행하셔도 됩니다.');
      setAlertShown(true);
    }
  }, [
    values.paidOffdays,
    values.selectWorkDays1,
    values.selectWorkDays1Info?.workingStartTime,
    values.selectWorkDays1Info?.workingEndTimeNextDayOrNot,
    values.selectWorkDays1Info?.workingEndTime,
    values.selectWorkDays1Info?.dayBreakTimes,
    values.selectWorkDays1Info?.breakTimeContents,
    alertShown
  ]);

  useEffect(() => {
    if (sumOfWorkDays > 6 && !daysExceededAlertShown) {
      alert("1주일의 근무일을 6일을 초과할 수 없습니다. 주휴일, 중복된 요일 등을 확인하세요.");
      setDaysExceededAlertShown(true);
    }
  }, [sumOfWorkDays, daysExceededAlertShown]);

  useEffect(() => {
    workDays1?.forEach(day1 => {
      if (day1 === (paidHoli !== "" && paidHoli)) {
        holiAlert();
      }
    });
  }, [workDays1, paidHoli]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
         {/* 7일 패턴 설정 */}
      <div style={{display: 'flex', alignItems : 'center', alignContent : 'flex-end', width : '100%', marginBottom : "30px", marginTop : "30px", fontSize: '17px', }}>
        <span style={{color : 'red'}}>* </span><span style={{color : 'blue'}}>1주일간 근로시간 입력</span><span style={{fontSize : '8px'}}>(설명은 오른쪽 동영상을 참조하세요.)</span>
        <span style={{position: "absolute", right: 0, marginRight:'160px', cursor:'pointer'}}><Label style={{color:'red'}} onClick={() => workerRegStep2YTOpen()}><Icon size='big' name='youtube' />기본시간 설정</Label></span>
        <span style={{position: "absolute", right: 0, marginRight:'10px', cursor:'pointer'}}><Label style={{color:'red'}} onClick={() => workerRegStep2_1YTOpen()}><Icon size='big' name='youtube' />요일별 시간설정</Label></span>
      </div>
        <>
          <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
            {/* 근로시간이 같은 첫번째 요일들 설정 */}
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MySelectInput
                onChangeCallback = {setPaidHoli} 
                label={<span style={{color: 'blue'}}>* (1) 법정 유급 주휴일 (하루만 선택)</span>}                
                placeholder='예) 일요일' 
                name='paidOffdays' 
                options={selectPaidWeekHolidays} 
              />
            </div>
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MySelectInput
                multiple
                label={<span style={{color: 'blue'}}>* (2) 출퇴근이 같은 근무요일 (복수선택 가능)</span>}                
                placeholder='예) 월, 화, 수, 목, 금' 
                name='selectWorkDays1' 
                onChangeCallback={setWorkDays1}
                options={selectWorkDays} 
              />
            </div>
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MySelectInput 
                label='(2-1)격주 근무 여부'
                name ='selectWorkDays1Info.skipWeek' 
                placeholder='매주근무' 
                options={skipWeekType} 
              />
            </div>
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MyTimeInput 
                label={<span style={{color: 'blue'}}>* (3) 출근시간</span>}                
                name='selectWorkDays1Info.workingStartTime' 
                autoComplete='off'
              />
            </div>
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MySelectInput 
                label={<span style={{color: 'blue'}}>* (4) 퇴근일(당일 또는 익일)</span>}                
                placeholder='당일' 
                name='selectWorkDays1Info.workingEndTimeNextDayOrNot' 
                options={dayType} 
              />
            </div>
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MyTimeInput 
                label={<span style={{color: 'blue'}}>* (5) 퇴근시간</span>}                
                name='selectWorkDays1Info.workingEndTime' 
                autoComplete='off'
              />
            </div>
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MyNumberInput 
                label={<span style={{color: 'blue'}}>* (6) 22시이전 주간근무 휴게시간 합계(분 단위)</span>}                
                name='selectWorkDays1Info.dayBreakTimes' 
                placeholder='없으면 "0"'
                autoComplete='off'
              />
            </div>
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MyNumberInput 
                label={<span style={{color: 'blue'}}>* (7) 22시~익일06시 야간근무 휴게시간 합계(분 단위)</span>}                
                name='selectWorkDays1Info.nightBreakTimes' 
                placeholder='없으면 "0"'
                autoComplete='off'
              />
            </div>
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MyTextInput 
                label={<span style={{color: 'blue'}}>* (8) 휴게시간 표시(단순 표시용임. 계산은(6),(7)에 입력.)</span>}                
                name='selectWorkDays1Info.breakTimeContents' 
                placeholder='12:00~13:00, 14:00~14:20, 23:00~ 02:00 등,'
                autoComplete='off'
              />
            </div>
          </div>  

          {/* 근로시간이 다른 두번째 요일들 설정 */}
          <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
            <>
              {
                workDays1?.length < 7 ?
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <Checkbox 
                      label={<label style={{color: 'red'}}>출퇴근시간이 다른 요일 추가 설정시</label>} 
                      onChange={plusWorkDay1Handler} 
                      name ='plusWorkDay1' 
                      type='checkbox' 
                    />
                  </div>
              : null
            }
            {
              plusWorkDay1 ?
                <>
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}> 
                    <MySelectInput
                      multiple
                      label='(2-1) 출퇴근시간이 같은 근무요일(들)' 
                      placeholder='예) 화요일' 
                      name='selectWorkDays2' 
                      onChangeCallback = {setWorkDays2}
                      options={selectWorkDays} 
                    />
                  </div>
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MySelectInput 
                      label='(2-1-1)격주 근무 여부'
                      name ='selectWorkDays2Info.skipWeek' 
                      placeholder='매주근무' 
                      options={skipWeekType} 
                    />
                  </div>
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MyTimeInput 
                      label='(3-1) 출근시간'
                      name='selectWorkDays2Info.workingStartTime' 
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MySelectInput 
                      label='(4-1) 퇴근일(당일/익일)' 
                      placeholder='당일' 
                      name='selectWorkDays2Info.workingEndTimeNextDayOrNot' 
                      options={dayType} 
                    />
                  </div>
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MyTimeInput 
                      label='(5-1) 퇴근시간'
                      name='selectWorkDays2Info.workingEndTime' 
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MyNumberInput 
                      label='(6-1) 22시 이전 휴게시간 합계(분)'
                      name='selectWorkDays2Info.dayBreakTimes' 
                      placeholder='없으면 "0"'
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MyNumberInput 
                      label='(7-1) 22시~익일06시 휴게시간 합계(분)'
                      name='selectWorkDays2Info.nightBreakTimes' 
                      placeholder='없으면 "0"'
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MyTextInput 
                      label='(8-1) 휴게시작 및 종료(근로계약서용)'
                      name='selectWorkDays2Info.breakTimeContents' 
                      placeholder='12:00~13:00, 14:00~14:20, 23:00~ 02:00 등,'
                      autoComplete='off'
                    />
                  </div>
                </>
                : null
              }
            </>
          </div>  

          {/* 근로시간이 다른 세번째 요일들 설정 */}
          <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
            <>
              {
                plusWorkDay1 && (workDays1?.length + workDays2?.length) < 7 ?              
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <Checkbox 
                      label={'출퇴근시간이 다른 요일 추가 설정시'}
                      onChange={plusWorkDay2Handler} 
                      name ='plusWorkDay2' 
                      type='checkbox' 
                    />
                  </div>
                : null
              }
              {
                plusWorkDay1 & plusWorkDay2 ?
                  <>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput
                        multiple
                        label='(2-2) 출퇴근시간이 같은 근무요일(들)' 
                        placeholder='예) 수요일' 
                        name='selectWorkDays3' 
                        onChangeCallback = {setWorkDays3}
                        options={selectWorkDays} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label='(2-2-1)격주 근무 여부'
                        name ='selectWorkDays3Info.skipWeek' 
                        placeholder='매주근무' 
                        options={skipWeekType} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MyTimeInput 
                      label='(3-2) 출근시간'
                      name='selectWorkDays3Info.workingStartTime' 
                      autoComplete='off'
                    />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label='(4-2) 퇴근일(당일/익일)' 
                        placeholder='당일' 
                        name='selectWorkDays3Info.workingEndTimeNextDayOrNot' 
                        options={dayType} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTimeInput 
                        label='(5-2) 퇴근시간'
                        name='selectWorkDays3Info.workingEndTime' 
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(6-2) 22시 이전 휴게시간 합계(분)'
                        name='selectWorkDays3Info.dayBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(7-2) 22시~익일06시 휴게시간 합계(분)'
                        name='selectWorkDays3Info.nightBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTextInput 
                        label='(8-2) 휴게시작 및 종료(근로계약서용)'
                        name='selectWorkDays3Info.breakTimeContents' 
                        placeholder='12:00~13:00, 14:00~14:20, 23:00~ 02:00 등,'
                        autoComplete='off'
                      />
                    </div>
                  </>
                  : null
                }
              </>
          </div>  

          {/* 근무시간이 다른 네번째 요일들 설정 */}
          <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
            <>
              {
                (plusWorkDay1 & plusWorkDay2) & (workDays1?.length + workDays2?.length + workDays3?.length) < 7 ?              
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <Checkbox 
                      label={plusWorkDay3 ? '추가 근무요일 설정 : 주휴일 제외 6일을 초과 절대 불가 ':'출퇴근시간이 다른 요일 추가 설정'}
                      onChange={plusWorkDay3Handler} 
                      name ='plusWorkDay3' 
                      type='checkbox' 
                    />
                  </div>
                : null
              }
              {
                (plusWorkDay1 & plusWorkDay2 & plusWorkDay3) & (workDays1?.length + workDays2?.length + workDays3?.length + workDays4?.length) < 7 ? 
                  <>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput
                        multiple
                        label='(2-3) 출퇴근시간이 같은 근무요일(들)' 
                        placeholder='예) 목요일' 
                        name='selectWorkDays4' 
                        onChangeCallback = {setWorkDays4}
                        options={selectWorkDays} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label='(2-3-1)격주 근무 여부'
                        name ='selectWorkDays4Info.skipWeek' 
                        placeholder='매주근무' 
                        options={skipWeekType} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTimeInput 
                        label='(3-3) 출근시간'
                        name='selectWorkDays4Info.workingStartTime' 
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label='(4-3) 퇴근일(당일/익일)' 
                        placeholder='당일' 
                        name='selectWorkDays4Info.workingEndTimeNextDayOrNot' 
                        options={dayType} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTimeInput 
                        label='(5-3) 퇴근시간'
                        name='selectWorkDays4Info.workingEndTime' 
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(6-3) 22시 이전 휴게시간 합계(분)'
                        name='selectWorkDays4Info.dayBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(7-3) 22시~익일06시 휴게시간 합계(분)'
                        name='selectWorkDays4Info.nightBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTextInput 
                        label='(8-3) 휴게시작 및 종료(근로계약서용)'
                        name='selectWorkDays4Info.breakTimeContents' 
                        placeholder='12:00~13:00, 14:00~14:20, 23:00~ 02:00 등,'
                        autoComplete='off'
                      />
                    </div>
                  </>
                  : null
              }
            </>
          </div>  

          {/* 근로시간이 다른 다섯번째 요일들 설정 */}
          <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
            <>
              {
                (plusWorkDay1 & plusWorkDay2 & plusWorkDay3) & (workDays1?.length + workDays2?.length + workDays3?.length + workDays4?.length) < 7 ?              
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <Checkbox 
                      label={plusWorkDay4 ? '추가 근무요일 설정 : 주휴일 제외 6일을 초과 절대 불가 ':'출퇴근시간이 다른 요일 추가 설정'}
                      onChange={plusWorkDay4Handler} 
                      name ='plusWorkDay4' 
                      type='checkbox' 
                    />
                  </div>
                : null
              }
              {
                (plusWorkDay1 & plusWorkDay2 & plusWorkDay3 & plusWorkDay4) & (workDays1?.length + workDays2?.length + workDays3?.length + workDays4?.length + workDays5?.length) < 7 ? 
                  <>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput
                        multiple
                        label='(2-4) 출퇴근이 같은 근무 요일(들)' 
                        placeholder='예) 금요일' 
                        name='selectWorkDays5' 
                        onChangeCallback = {setWorkDays5}
                        options={selectWorkDays} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label='(2-4-1)격주 근무 여부'
                        name ='selectWorkDays5Info.skipWeek' 
                        placeholder='매주근무' 
                        options={skipWeekType} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTimeInput 
                        label='(3-4) 출근시간'
                        name='selectWorkDays5Info.workingStartTime' 
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label='(4-4) 퇴근일(당일/익일)' 
                        placeholder='당일' 
                        name='selectWorkDays5Info.workingEndTimeNextDayOrNot' 
                        options={dayType} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTimeInput 
                        label='(5-4) 퇴근시간'
                        name='selectWorkDays5Info.workingEndTime' 
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(6-4) 22시 이전 휴게시간 합계(분)'
                        name='selectWorkDays5Info.dayBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(7-4) 22시~익일06시 휴게시간 합계(분)'
                        name='selectWorkDays5Info.nightBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTextInput 
                        label='(8-4) 휴게시작 및 종료(근로계약서용)'
                        name='selectWorkDays5Info.breakTimeContents' 
                        placeholder='12:00~13:00, 14:00~14:20, 23:00~ 02:00 등,'
                        autoComplete='off'
                      />
                    </div>
                  </>
                : null
              }
            </>
          </div>  

          {/* 근로시간이 다른 여섯번째 요일들 설정 */}
          <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
            <>
              {
                (plusWorkDay1 & plusWorkDay2 & plusWorkDay3 & plusWorkDay4) & (workDays1?.length + workDays2?.length + workDays3?.length + workDays4?.length + workDays5?.length) < 7 ?              
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <Checkbox 
                    label={plusWorkDay5 ? '추가 근무요일 설정 : 주휴일 제외 6일을 초과 절대 불가 ':'출퇴근시간이 다른 요일 추가 설정'}
                    onChange={plusWorkDay5Handler} 
                    name ='plusWorkDay5' 
                    type='checkbox' 
                  />
                </div>
                : null
              }
              {
                (plusWorkDay1 & plusWorkDay2 & plusWorkDay3 & plusWorkDay4 & plusWorkDay5) & (workDays1?.length + workDays2?.length + workDays3?.length + workDays4?.length + workDays5?.length + workDays6?.length) < 7 ? 
                  <>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput
                        multiple
                        label='(2-5) 출퇴근시간이 같은 근무요일(들)' 
                        placeholder='예) 토요일' 
                        name='selectWorkDays6' 
                        onChangeCallback = {setWorkDays6}
                        options={selectWorkDays} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label='(2-2-1)격주 근무 여부'
                        name ='selectWorkDays6Info.skipWeek' 
                        placeholder='매주근무' 
                        options={skipWeekType} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTimeInput 
                        label='(3-5) 출근시간'
                        name='selectWorkDays6Info.workingStartTime' 
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label='(4-5) 퇴근일(당일/익일)' 
                        placeholder='당일' 
                        name='selectWorkDays6Info.workingEndTimeNextDayOrNot' 
                        options={dayType} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTimeInput 
                        label='(5-5) 퇴근시간'
                        name='selectWorkDays6Info.workingEndTime' 
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(6-5) 22시 이전 휴게시간 합계(분)'
                        name='selectWorkDays6Info.dayBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(7-5) 22시~익일06시 휴게시간 합계(분)'
                        name='selectWorkDays6Info.nightBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTextInput 
                        label='(8-5) 휴게시작 및 종료(근로계약서용)'
                        name='selectWorkDays6Info.breakTimeContents' 
                        placeholder='12:00~13:00, 14:00~14:20, 23:00~ 02:00 등,'
                        autoComplete='off'
                      />
                    </div>
                  </>
                : null
              }
            </>
          </div>
        </>
    </>
  )
};


