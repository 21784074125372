import React, { useEffect, useState } from 'react'
import { Checkbox } from 'semantic-ui-react'
import {
  selectWorkDays,
  dayType,
  includeAnnualWage,
  shiftWork,
} from '../../../../../app/api/dropdownOption';
import MySelectInput from '../../../../../app/common/form/MySelectInput';
import MyTextInput from '../../../../../app/common/form/MyTextInput';
import MyTimeInput from '../../../../../app/common/form/MyTimeInput';
import MyNumberInput from '../../../../../app/common/form/MyNumberInput';
import { timeErrorAlert } from '../../../../../app/common/util/util';

export default function CrwfExceptTimeLawLaborContractTimeInfo({
  setSumOfWorkDays, 
  selectedClientReq4In,
  values,
}) {

  const [alertShown, setAlertShown] = useState(false);
    
  const [expectYearlyHolidayWorks, setExpectYearlyHolidayWorks] = useState(
    selectedClientReq4In?.expectYearlyHolidayWorks
    ? true : false);
  const [expectWeekOverNightHodiworks, setExpectWeekOverNightHodiworks] = useState(
    (selectedClientReq4In?.expectWeeklyOverWorks 
      || selectedClientReq4In?.expectWeeklyNightWorks)
    ? true : false);

  const [plusWorkDayETL1, setPlusWorkDayETL1] = useState(
    selectedClientReq4In?.selectWorkDaysETL2
    ? true : false);
  const [plusWorkDayETL2, setPlusWorkDayETL2] = useState(
    selectedClientReq4In?.selectWorkDaysETL3
    ? true : false);
  const [plusWorkDayETL3, setPlusWorkDayETL3] = useState(
    selectedClientReq4In?.selectWorkDaysETL4
    ? true : false);
  const [plusWorkDayETL4, setPlusWorkDayETL4] = useState(    
    selectedClientReq4In?.selectWorkDaysETL5
    ? true : false);
  const [plusWorkDayETL5, setPlusWorkDayETL5] = useState(    
    selectedClientReq4In?.selectWorkDaysETL6
    ? true : false);



  const [workDays1, setWorkDays1] = useState([]);
  const [workDays2, setWorkDays2] = useState([]);
  const [workDays3, setWorkDays3] = useState([]);
  const [workDays4, setWorkDays4] = useState([]);
  const [workDays5, setWorkDays5] = useState([]);
  const [workDays6, setWorkDays6] = useState([]);
  const [workDays7, setWorkDays7] = useState([]);

  console.log('workDays7', workDays7)

  const sumOfWorkDays = workDays1?.length + workDays2?.length + workDays3?.length + workDays4?.length + workDays5?.length + workDays6?.length
  
  useEffect(() => {
    setSumOfWorkDays(sumOfWorkDays);
  }, [setSumOfWorkDays, sumOfWorkDays])

  function plusWorkDayETL1Handler () {
    setPlusWorkDayETL1(!plusWorkDayETL1);
    return ;
  }

  function plusWorkDayETL2Handler () {
    setPlusWorkDayETL2(!plusWorkDayETL2);
    return ;
  } 

  function plusWorkDayETL3Handler () {
    setPlusWorkDayETL3(!plusWorkDayETL3);
    return ;
  } 

  function plusWorkDayETL4Handler () {
    setPlusWorkDayETL4(!plusWorkDayETL4);
    return ;
  } 

  function plusWorkDayETL5Handler () {
    setPlusWorkDayETL5(!plusWorkDayETL5);
    return ;
  } 

  function expectHoliWorksHandler () {
    setExpectYearlyHolidayWorks(!expectYearlyHolidayWorks)
  }

  function expectOverWorksHandler () {
    setExpectWeekOverNightHodiworks(!expectWeekOverNightHodiworks)
  }

  useEffect(() => {
    timeErrorAlert(values?.selectWorkDaysETL1Info?.workingStartTime, values?.selectWorkDaysETL1Info?.workingEndTime, values?.selectWorkDaysETL1Info?.workingEndTimeNextDayOrNot)
  }, [values?.selectWorkDaysETL1Info?.workingStartTime, values?.selectWorkDaysETL1Info?.workingEndTime, values?.selectWorkDaysETL1Info?.workingEndTimeNextDayOrNot])
  
  useEffect(() => {
    timeErrorAlert(values?.selectWorkDaysETL2Info?.workingStartTime, values?.selectWorkDaysETL2Info?.workingEndTime, values?.selectWorkDaysETL2Info?.workingEndTimeNextDayOrNot)
  }, [values?.selectWorkDaysETL2Info?.workingStartTime, values?.selectWorkDaysETL2Info?.workingEndTime, values?.selectWorkDaysETL2Info?.workingEndTimeNextDayOrNot])
  
  useEffect(() => {
    timeErrorAlert(values?.selectWorkDaysETL3Info?.workingStartTime, values?.selectWorkDaysETL3Info?.workingEndTime, values?.selectWorkDaysETL3Info?.workingEndTimeNextDayOrNot)
  }, [values?.selectWorkDaysETL3Info?.workingStartTime, values?.selectWorkDaysETL3Info?.workingEndTime, values?.selectWorkDaysETL3Info?.workingEndTimeNextDayOrNot])
  
  useEffect(() => {
    timeErrorAlert(values?.selectWorkDaysETL4Info?.workingStartTime, values?.selectWorkDaysETL4Info?.workingEndTime, values?.selectWorkDaysETL4Info?.workingEndTimeNextDayOrNot)
  }, [values?.selectWorkDaysETL4Info?.workingStartTime, values?.selectWorkDaysETL4Info?.workingEndTime, values?.selectWorkDaysETL4Info?.workingEndTimeNextDayOrNot])
  
  useEffect(() => {
    timeErrorAlert(values?.selectWorkDaysETL5Info?.workingStartTime, values?.selectWorkDaysETL5Info?.workingEndTime, values?.selectWorkDaysETL5Info?.workingEndTimeNextDayOrNot)
  }, [values?.selectWorkDaysETL5Info?.workingStartTime, values?.selectWorkDaysETL5Info?.workingEndTime, values?.selectWorkDaysETL5Info?.workingEndTimeNextDayOrNot])
  
  useEffect(() => {
    timeErrorAlert(values?.selectWorkDaysETL6Info?.workingStartTime, values?.selectWorkDaysETL6Info?.workingEndTime, values?.selectWorkDaysETL6Info?.workingEndTimeNextDayOrNot)
  }, [values?.selectWorkDaysETL6Info?.workingStartTime, values?.selectWorkDaysETL6Info?.workingEndTime, values?.selectWorkDaysETL6Info?.workingEndTimeNextDayOrNot])
  

  const shiftWorker = values?.selectWorkDaysETL1Info?.shiftWork === '격일제 해당' ? true : false

  useEffect(() => {
    // 필수 입력란이 모두 채워졌는지 확인
    const allRequiredFieldsFilled = 
      values?.selectWorkDaysETL1Info?.shiftWork &&
      values?.selectWorkDaysETL1 &&
      values?.selectWorkDays1 &&
      values?.selectWorkDaysETL1Info?.workingStartTime &&
      values?.selectWorkDaysETL1Info?.workingEndTimeNextDayOrNot &&
      values?.selectWorkDaysETL1Info?.workingEndTime &&
      values?.selectWorkDaysETL1Info?.dayBreakTimes &&
      values?.selectWorkDaysETL1Info?.breakTimeContents 
    if (allRequiredFieldsFilled && !alertShown) {
      alert('2. 근로시간정보 필수 입력란을 모두 입력하셨습니다. "3.임금정보" 단계로 진행하셔도 됩니다.');
      setAlertShown(true); // 다시 뜨지 않도록 상태 업데이트
    }
  }, [
    values?.selectWorkDaysETL1Info?.shiftWork,
    values?.selectWorkDaysETL1,
    values?.selectWorkDays1,
    values?.selectWorkDaysETL1Info?.workingStartTime,
    values?.selectWorkDaysETL1Info?.workingEndTimeNextDayOrNot,
    values?.selectWorkDaysETL1Info?.workingEndTime,
    values?.selectWorkDaysETL1Info?.dayBreakTimes,
    values?.selectWorkDaysETL1Info?.breakTimeContents,
    alertShown
  ]);

  return (
    <>
      {!shiftWorker && sumOfWorkDays >= 7 ? alert("최대 6일까지만 설정하세요. 7일째 근무시간은 아래 별도로 입력하세요.") : null}
      {/* 7일 패턴 설정 */}
      <div  style={{display: 'flex', alignItems : 'center', alignContent : 'flex-end', width : '100%', marginBottom : "30px", marginTop : "30px", fontSize: '17px'}}>
        <span style={{color : 'red'}}>* </span><span style={{color : 'blue'}}> Step 2. 63조 근로시간,휴게,휴일 제외자 1주일간 근로시간 입력</span>
      </div>
        <>
          <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
            {/* 근로시간이 같은 첫번째 요일들 설정 */}

            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MySelectInput 
                label={<span style={{color: 'blue'}}>* (1-1-1) 격일제 여부</span>}                
                placeholder='해당 or 비해당' 
                name='selectWorkDaysETL1Info.shiftWork' 
                options={shiftWork} 
              />
            </div>
            <div className='margin' style={{width : '66%', marginRight : '3px'}}>
              <MySelectInput
                multiple
                label={shiftWorker ? 
                    <><span style={{color: 'blue'}}>* (1-1) 출퇴근이 같은 근무요일</span></>
                  : 
                    <><span style={{color: 'blue'}}>* (1-1) 출퇴근이 같은 근무요일 (최대 6일 선택 가능)</span><span style={{color : 'red'}}>- 7일차(예 일요일)가 있는 경우는은 아래 7일차 근로시간 설정을 이용하세요.</span></>
                }                
                placeholder={
                  shiftWorker ? 
                    '예) 월요일'
                  : 
                    '예) 월요일, 화요일, 수요일, 목요일, 금요일, 토요일'
                }     
                name='selectWorkDaysETL1' 
                onChangeCallback={setWorkDays1}
                options={selectWorkDays} 
              />
            </div>
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MyTimeInput 
                label={<span style={{color: 'blue'}}>* (1-2) 출근시간</span>}                
                name='selectWorkDaysETL1Info.workingStartTime' 
                autoComplete='off'
              />
            </div>
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MySelectInput 
                label={<span style={{color: 'blue'}}>* (1-3) 퇴근일(당일 또는 익일)</span>}                
                placeholder='당일' 
                name='selectWorkDaysETL1Info.workingEndTimeNextDayOrNot' 
                options={dayType} 
              />
            </div>
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MyTimeInput 
                label={<span style={{color: 'blue'}}>* (1-4) 퇴근시간</span>}                
                name='selectWorkDaysETL1Info.workingEndTime' 
                autoComplete='off'
              />
            </div>
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MyNumberInput 
                label={<span style={{color: 'blue'}}>* (1-5) 22시이전 주간근무 휴게시간 합계(분 단위)</span>}                
                name='selectWorkDaysETL1Info.dayBreakTimes' 
                placeholder='없으면 "0"'
                autoComplete='off'
              />
            </div>
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MyNumberInput 
                label={<span style={{color: 'blue'}}>* (1-6) 22시~익일06시 야간근무 휴게시간 합계(분 단위)</span>}                
                name='selectWorkDaysETL1Info.nightBreakTimes' 
                placeholder='없으면 "0"'
                autoComplete='off'
              />
            </div>
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MyTextInput 
                label={<span style={{color: 'blue'}}>* (1-7) 휴게시간 표시(단순 표시용임. 계산은(6),(7)에 입력.)</span>}                
                name='selectWorkDaysETL1Info.breakTimeContents' 
                placeholder='12:00~13:00, 14:00~14:20, 23:00~ 02:00 등,'
                autoComplete='off'
              />
            </div>
          </div>  

          {/* 근로시간이 다른 두번째 요일들 설정 */}
          <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
            <>
              {
                workDays1?.length < 7 ?
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <Checkbox 
                      label={'출퇴근시간이 다른 요일 추가 설정시'}
                      onChange={plusWorkDayETL1Handler} 
                      name ='plusWorkDayETL1' 
                      type='checkbox' 
                    />
                  </div>
              : null
            }
            {
              plusWorkDayETL1 ?
                <>
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MySelectInput 
                      label={<span style={{color: 'blue'}}>* (2-1-1) 격일제 여부</span>}                
                      placeholder='해당 or 비해당' 
                      name='selectWorkDaysETL2Info.shiftWork' 
                      options={shiftWork} 
                    />
                  </div>
                  <div className='margin' style={{width : '63%', marginRight : '3px'}}> 
                    <MySelectInput
                      multiple
                      label={shiftWorker ? 
                          <><span style={{color: 'blue'}}>* (2-1) 출퇴근이 같은 근무요일</span></>
                        : 
                          <><span style={{color: 'blue'}}>* (2-1) 출퇴근이 같은 근무요일 (최대 5일 선택 가능)</span><span style={{color : 'red'}}>- 7일차(예 일요일)가 있는 경우는은 아래 7일차 근로시간 설정을 이용하세요.</span></>
                      }                
                      placeholder={'예) 월요일, 화요일, 수요일, 목요일, 금요일, 토요일'}     
                      name='selectWorkDaysETL2' 
                      onChangeCallback = {setWorkDays2}
                      options={selectWorkDays} 
                    />
                  </div>
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MyTimeInput 
                      label='(2-2) 출근시간'
                      name='selectWorkDaysETL2Info.workingStartTime' 
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MySelectInput 
                      label='(2-3) 퇴근일(당일/익일)' 
                      placeholder='당일' 
                      name='selectWorkDaysETL2Info.workingEndTimeNextDayOrNot' 
                      options={dayType} 
                    />
                  </div>
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MyTimeInput 
                      label='(2-4) 퇴근시간'
                      name='selectWorkDaysETL2Info.workingEndTime' 
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MyNumberInput 
                      label='(2-5) 22시 이전 휴게시간 합계(분)'
                      name='selectWorkDaysETL2Info.dayBreakTimes' 
                      placeholder='없으면 "0"'
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MyNumberInput 
                      label='(2-6) 22시~익일06시 휴게시간 합계(분)'
                      name='selectWorkDaysETL2Info.nightBreakTimes' 
                      placeholder='없으면 "0"'
                      autoComplete='off'
                    />
                  </div>
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MyTextInput 
                      label='(2-7) 휴게시작 및 종료(근로계약서용)'
                      name='selectWorkDaysETL2Info.breakTimeContents' 
                      placeholder='12:00~13:00, 14:00~14:20, 23:00~ 02:00 등,'
                      autoComplete='off'
                    />
                  </div>
                </>
                : null
              }
            </>
          </div>  

          {/* 근로시간이 다른 세번째 요일들 설정 */}
          <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
            <>
              {
                plusWorkDayETL1 && (workDays1?.length + workDays2?.length) < 7 ?              
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <Checkbox 
                      label={'출퇴근시간이 다른 요일 추가 설정시'}
                      onChange={plusWorkDayETL2Handler} 
                      name ='plusWorkDayETL2' 
                      type='checkbox' 
                    />
                  </div>
                : null
              }
              {
                plusWorkDayETL1 & plusWorkDayETL2 ?
                  <>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label={<span style={{color: 'blue'}}>* (3-1-1) 격일제 여부</span>}                
                        placeholder='해당 or 비해당' 
                        name='selectWorkDaysETL3Info.shiftWork' 
                        options={shiftWork} 
                      />
                    </div>
                    <div className='margin' style={{width : '63%', marginRight : '3px'}}>
                      <MySelectInput
                        multiple
                        label={shiftWorker ? 
                            <><span style={{color: 'blue'}}>* (3-1) 출퇴근이 같은 근무요일</span></>
                          : 
                            <><span style={{color: 'blue'}}>* (3-1) 출퇴근이 같은 근무요일 (최대 4일 선택 가능)</span><span style={{color : 'red'}}>- 7일차(예 일요일)가 있는 경우는은 아래 7일차 근로시간 설정을 이용하세요.</span></>
                        }                
                        placeholder={'예) 수요일, 목요일, 금요일, 토요일'}     
                        name='selectWorkDaysETL3' 
                        onChangeCallback = {setWorkDays3}
                        options={selectWorkDays} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MyTimeInput 
                      label='(3-2) 출근시간'
                      name='selectWorkDaysETL3Info.workingStartTime' 
                      autoComplete='off'
                    />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label='(3-3) 퇴근일(당일/익일)' 
                        placeholder='당일' 
                        name='selectWorkDaysETL3Info.workingEndTimeNextDayOrNot' 
                        options={dayType} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTimeInput 
                        label='(3-4) 퇴근시간'
                        name='selectWorkDaysETL3Info.workingEndTime' 
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(4-5) 22시 이전 휴게시간 합계(분)'
                        name='selectWorkDaysETL3Info.dayBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(3-6) 22시~익일06시 휴게시간 합계(분)'
                        name='selectWorkDaysETL3Info.nightBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTextInput 
                        label='(3-7) 휴게시작 및 종료(근로계약서용)'
                        name='selectWorkDaysETL3Info.breakTimeContents' 
                        placeholder='12:00~13:00, 14:00~14:20, 23:00~ 02:00 등,'
                        autoComplete='off'
                      />
                    </div>
                  </>
                  : null
                }
              </>
          </div>  

          {/* 근무시간이 다른 네번째 요일들 설정 */}
          <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
            <>
              {
                (plusWorkDayETL1 & plusWorkDayETL2) & (workDays1?.length + workDays2?.length + workDays3?.length) < 7 ?              
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <Checkbox 
                      label={plusWorkDayETL3 ? '추가 근무요일 설정 : 주휴일 제외 6일을 초과 절대 불가 ':'출퇴근시간이 다른 요일 추가 설정'}
                      onChange={plusWorkDayETL3Handler} 
                      name ='plusWorkDayETL3' 
                      type='checkbox' 
                    />
                  </div>
                : null
              }
              {
                (plusWorkDayETL1 & plusWorkDayETL2 & plusWorkDayETL3) & (workDays1?.length + workDays2?.length + workDays3?.length + workDays4?.length) < 7 ? 
                  <>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label={<span style={{color: 'blue'}}>* (4-1-1) 격일제 여부</span>}                
                        placeholder='해당 or 비해당' 
                        name='selectWorkDaysETL4Info.shiftWork' 
                        options={shiftWork} 
                      />
                    </div>
                    <div className='margin' style={{width : '63%', marginRight : '3px'}}>
                      <MySelectInput
                        multiple
                        label={shiftWorker ? 
                            <><span style={{color: 'blue'}}>* (4-1) 출퇴근이 같은 근무요일</span></>
                          : 
                            <><span style={{color: 'blue'}}>* (4-1) 출퇴근이 같은 근무요일 (최대 3일 선택 가능)</span><span style={{color : 'red'}}>- 7일차(예 일요일)가 있는 경우는은 아래 7일차 근로시간 설정을 이용하세요.</span></>
                        }                
                        placeholder={'예) 목요일, 금요일, 토요일'
                        }     
                        name='selectWorkDaysETL4' 
                        onChangeCallback = {setWorkDays4}
                        options={selectWorkDays} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTimeInput 
                        label='(4-2) 출근시간'
                        name='selectWorkDaysETL4Info.workingStartTime' 
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label='(4-3) 퇴근일(당일/익일)' 
                        placeholder='당일' 
                        name='selectWorkDaysETL4Info.workingEndTimeNextDayOrNot' 
                        options={dayType} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTimeInput 
                        label='(4-4) 퇴근시간'
                        name='selectWorkDaysETL4Info.workingEndTime' 
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(4-5) 22시 이전 휴게시간 합계(분)'
                        name='selectWorkDaysETL4Info.dayBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(4-6) 22시~익일06시 휴게시간 합계(분)'
                        name='selectWorkDaysETL4Info.nightBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTextInput 
                        label='(4-7) 휴게시작 및 종료(근로계약서용)'
                        name='selectWorkDaysETL4Info.breakTimeContents' 
                        placeholder='12:00~13:00, 14:00~14:20, 23:00~ 02:00 등,'
                        autoComplete='off'
                      />
                    </div>
                  </>
                  : null
              }
            </>
          </div>  

          {/* 근로시간이 다른 다섯번째 요일들 설정 */}
          <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
            <>
              {
                (plusWorkDayETL1 & plusWorkDayETL2 & plusWorkDayETL3) & (workDays1?.length + workDays2?.length + workDays3?.length + workDays4?.length) < 7 ?              
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <Checkbox 
                      label={'출퇴근시간이 다른 요일 추가 설정'}
                      onChange={plusWorkDayETL4Handler} 
                      name ='plusWorkDayETL4' 
                      type='checkbox' 
                    />
                  </div>
                : null
              }
              {
                (plusWorkDayETL1 & plusWorkDayETL2 & plusWorkDayETL3 & plusWorkDayETL4) & (workDays1?.length + workDays2?.length + workDays3?.length + workDays4?.length + workDays5?.length) < 7 ? 
                  <>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label={<span style={{color: 'blue'}}>* (5-1-1) 격일제 여부</span>}                
                        placeholder='해당 or 비해당' 
                        name='selectWorkDaysETL5Info.shiftWork' 
                        options={shiftWork} 
                      />
                    </div>
                    <div className='margin' style={{width : '63%', marginRight : '3px'}}>
                      <MySelectInput
                        multiple
                        label={shiftWorker ? 
                            <><span style={{color: 'blue'}}>* (5-1) 출퇴근이 같은 근무요일 </span></>
                          : 
                            <><span style={{color: 'blue'}}>* (5-1) 출퇴근이 같은 근무요일 (최대 2일 선택 가능)</span><span style={{color : 'red'}}>- 7일차(예 일요일)가 있는 경우는은 아래 7일차 근로시간 설정을 이용하세요.</span></>
                        }                
                        placeholder={
                            '예) 금요일, 토요일'
                        }     
                        name='selectWorkDaysETL5' 
                        onChangeCallback = {setWorkDays5}
                        options={selectWorkDays} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTimeInput 
                        label='(5-2) 출근시간'
                        name='selectWorkDaysETL5Info.workingStartTime' 
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label='(5-3) 퇴근일(당일/익일)' 
                        placeholder='당일' 
                        name='selectWorkDaysETL5Info.workingEndTimeNextDayOrNot' 
                        options={dayType} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTimeInput 
                        label='(5-4) 퇴근시간'
                        name='selectWorkDaysETL5Info.workingEndTime' 
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(5-5) 22시 이전 휴게시간 합계(분)'
                        name='selectWorkDaysETL5Info.dayBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(5-6) 22시~익일06시 휴게시간 합계(분)'
                        name='selectWorkDaysETL5Info.nightBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTextInput 
                        label='(5-7) 휴게시작 및 종료(근로계약서용)'
                        name='selectWorkDaysETL5Info.breakTimeContents' 
                        placeholder='12:00~13:00, 14:00~14:20, 23:00~ 02:00 등,'
                        autoComplete='off'
                      />
                    </div>
                  </>
                : null
              }
            </>
          </div>  

          {/* 근로시간이 다른 여섯번째 요일들 설정 */}
          <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
            <>
              {
                (plusWorkDayETL1 & plusWorkDayETL2 & plusWorkDayETL3 & plusWorkDayETL4) & (workDays1?.length + workDays2?.length + workDays3?.length + workDays4?.length + workDays5?.length) < 7 ?              
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <Checkbox 
                    label={plusWorkDayETL5 ? '추가 근무요일 설정 : 주휴일 제외 6일을 초과 절대 불가 ':'출퇴근시간이 다른 요일 추가 설정'}
                    onChange={plusWorkDayETL5Handler} 
                    name ='plusWorkDayETL5' 
                    type='checkbox' 
                  />
                </div>
                : null
              }
              {
                (plusWorkDayETL1 & plusWorkDayETL2 & plusWorkDayETL3 & plusWorkDayETL4 & plusWorkDayETL5) & (workDays1?.length + workDays2?.length + workDays3?.length + workDays4?.length + workDays5?.length + workDays6?.length) < 7 ? 
                  <>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label={<span style={{color: 'blue'}}>* (6-1-1) 격일제 여부</span>}                
                        placeholder='해당 or 비해당' 
                        name='selectWorkDaysETL6Info.shiftWork' 
                        options={shiftWork} 
                      />
                    </div>
                    <div className='margin' style={{width : '63%', marginRight : '3px'}}>
                      <MySelectInput
                        multiple
                        label={shiftWorker ? 
                            <><span style={{color: 'blue'}}>* (6-1) 출퇴근이 같은 근무요일</span></>
                          : 
                            <><span style={{color: 'blue'}}>* (6-1) 출퇴근이 같은 근무요일 (최대 1일 선택 가능)</span><span style={{color : 'red'}}>- 7일차(예 일요일)가 있는 경우는은 아래 7일차 근로시간 설정을 이용하세요.</span></>
                        }                
                        placeholder={'예) 토요일' }     
                        name='selectWorkDaysETL6' 
                        onChangeCallback = {setWorkDays6}
                        options={selectWorkDays} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTimeInput 
                        label='(6-2) 출근시간'
                        name='selectWorkDaysETL6Info.workingStartTime' 
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MySelectInput 
                        label='(6-3) 퇴근일(당일/익일)' 
                        placeholder='당일' 
                        name='selectWorkDaysETL6Info.workingEndTimeNextDayOrNot' 
                        options={dayType} 
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTimeInput 
                        label='(6-4) 퇴근시간'
                        name='selectWorkDaysETL6Info.workingEndTime' 
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(6-5) 22시 이전 휴게시간 합계(분)'
                        name='selectWorkDaysETL6Info.dayBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyNumberInput 
                        label='(6-6) 22시~익일06시 휴게시간 합계(분)'
                        name='selectWorkDaysETL6Info.nightBreakTimes' 
                        placeholder='없으면 "0"'
                        autoComplete='off'
                      />
                    </div>
                    <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                      <MyTextInput 
                        label='(6-7) 휴게시작 및 종료(근로계약서용)'
                        name='selectWorkDaysETL6Info.breakTimeContents' 
                        placeholder='12:00~13:00, 14:00~14:20, 23:00~ 02:00 등,'
                        autoComplete='off'
                      />
                    </div>
                  </>
                : null
              }
            </>
          </div>
          <br />
          {/* 포괄역산 휴일근로 시간 설정 */}
          <div onClick={() => expectHoliWorksHandler()} style={{width : '100%', marginBottom : "5px", marginTop : "5px", fontSize: '13px',  color: 'red'}}>
          <span style={{cursor: "pointer"}}>
            ●  {!expectYearlyHolidayWorks ? '7일차(예 일요일) 근로시간 설정' : '7일차(예 일요일) 근로시간 설정'}
          </span>
          </div>
          <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
            {expectYearlyHolidayWorks &&
              <>
                <div className='margin' style={{width : '74%', marginRight : '3px'}}> 
                  <MySelectInput
                    label='(7-1) 근무시간이 다른 마지막 요일 설정' 
                    placeholder='예) 일요일' 
                    name='selectWorkDaysETL7' 
                    onChangeCallback = {setWorkDays7}
                    options={selectWorkDays} 
                  />
                </div>
                <div className='margin' style={{width : '25%', marginRight : '3px'}}>
                  <MyNumberInput
                    type = 'number'
                    label='(7-1) 7일차 년간 근무일 개수(52일 한도)'
                    name='expectYearly7thWorkDayCounts' 
                    placeholder='예) 년 12회 등' 
                    autoComplete='off'
                    min = "0"
                    max = "52"
                  />
                </div>
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <MyTimeInput 
                    label='(7-2) 출근시간'
                    name='expectYearly7thDaysETLInfo.workingStartTime' 
                    autoComplete='off'
                  />
                </div>
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <MySelectInput 
                    label='(7-3) 퇴근일(당일/익일)' 
                    placeholder='당일' 
                    name='expectYearly7thDaysETLInfo.workingEndTimeNextDayOrNot' 
                    options={dayType} 
                  />
                </div>
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <MyTimeInput 
                    label='(7-4) 퇴근시간'
                    name='expectYearly7thDaysETLInfo.workingEndTime' 
                    autoComplete='off'
                  />
                </div>
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <MyNumberInput 
                    label='(7-5) 22시 이전 휴게시간 합계(분)'
                    name='expectYearly7thDaysETLInfo.dayBreakTimes' 
                    placeholder='없으면 "0"'
                    autoComplete='off'
                  />
                </div>
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <MyNumberInput 
                    label='(7-6) 22시~익일06시 휴게시간 합계(분)'
                    name='expectYearly7thDaysETLInfo.nightBreakTimes' 
                    placeholder='없으면 "0"'
                    autoComplete='off'
                  />
                </div>
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <MyTextInput 
                    label='(7-7) 휴게시작 및 종료(근로계약서용)'
                    name='expectYearly7thDaysETLInfo.breakTimeContents' 
                    placeholder='12:00~13:00, 14:00~14:20, 23:00~ 02:00 등,'
                    autoComplete='off'
                  />
                </div>
              </>
            }
          </div>  

          <br/>
          {/* 포괄역산 연장, 야간, 휴일 시간 설정 */}
          <div onClick={() => expectOverWorksHandler()} style={{width : '100%', marginBottom : "5px", marginTop : "5px", fontSize: '13px', color: 'green'}}>
          <span style={{cursor: "pointer"}}>
            ●  {!expectWeekOverNightHodiworks ? '위 출퇴근시간이 정해진 근무시간외에 업무 특성상 근무일이 불특정한 연장, 야간 근로가 일정하게 있는 경우(1주일 단위) 및 연차15일 월급 포함여부' : '근무요일 불특정 초과 및 야간 근로시간 설정(1주일 단위) 및 연차15일 월급 포함여부'}
          </span>
          </div>
          <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
            {expectWeekOverNightHodiworks &&
              <>
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <MyNumberInput
                    label='(8-1) 1주간 고정 근무외 초과 근로(근무일 불특정) '
                    name='expectWeeklyOverETRWorks' 
                    placeholder='예) 1주간 12시간' 
                    autoComplete='off'
                    min = "0"
                  />
                </div>
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <MyNumberInput
                    label='(8-2) 1주간 고정 야간 근로시간(근무일 불특정) '
                    name='expectWeeklyNightETRWorks' 
                    placeholder='예) 1주간 00시간' 
                    autoComplete='off'
                    min = "0"
                  />
                </div>
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <MySelectInput
                    label='연차수당 월급 포함 여부 설정'                
                    placeholder='월급 총액에 포함' 
                    name='annualLeavesIncludeOrNot' 
                    options={includeAnnualWage} 
                  />
                </div>
              </>
            }
          </div>
          <br/>
        </>
    </>
  )
};


