import { useField } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormField, Label } from 'semantic-ui-react';

export default function MySelectInput({ label, options, onChangeCallback, multiple, ...props }) {
  const [field, meta, helpers] = useField(props);

  const handleChange = (selectedOption) => {
    const value = multiple 
      ? selectedOption.map(option => option.value) 
      : selectedOption ? selectedOption.value : '';
    helpers.setValue(value);
    if (onChangeCallback) {
      onChangeCallback(value);
    }
  };

  // react-select에 맞는 형식으로 변환
  const selectOptions = options.map(option => ({
    value: option.value,
    label: option.text,
  }));

  const selectedValue = multiple
    ? selectOptions.filter(option => Array.isArray(field.value) && field.value.includes(option.value))
    : selectOptions.find(option => option.value === field.value) || null;

  return (
    <FormField error={meta.touched && !!meta.error}>
      <label>{label}</label>
      <Select
        value={selectedValue}
        onChange={handleChange}
        onBlur={() => helpers.setTouched(true)}
        options={selectOptions}
        isClearable
        isMulti={multiple}
        closeMenuOnSelect={!multiple} // 여러 항목을 선택할 때 메뉴가 닫히지 않도록 설정
        {...props}
      />
      {meta.touched && meta.error ? (
        <Label basic color='red'>{meta.error}</Label>
      ) : null}
    </FormField>
  );
}

// 기본 props 설정
MySelectInput.defaultProps = {
  options: [], // options가 제공되지 않으면 빈 배열로 기본값 설정
  onChangeCallback: null, // 기본적으로 콜백 함수는 null로 설정
  multiple: false, // 기본적으로 multiple 선택은 false로 설정
};

// 유효성 검사를 위한 prop types 추가
MySelectInput.propTypes = {
  label: PropTypes.node.isRequired, // PropTypes.node로 변경하여 React 엘리먼트를 허용
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // key 타입을 string 또는 number로 허용
    text: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // value 타입을 string 또는 number로 허용
  })).isRequired, // options는 객체 배열이어야 하며 필수임
  onChangeCallback: PropTypes.func, // 선택된 값을 설정하는 콜백 함수 (옵션)
  name: PropTypes.string.isRequired, // name은 문자열이어야 하며 필수임
  multiple: PropTypes.bool, // multiple 선택을 위한 bool 타입
};
