
import React from 'react';
import { josaGaType, josaUnType } from '../../../../../app/common/util/util';

export default function ClientReqDLCDWage({worker, companyName, contractOldWorker}) {

  const companyUn = josaUnType(companyName)
  const workerGa = josaGaType(worker)

  const contractOldWorkerText = (`${companyUn} ${workerGa} 정년퇴직 후 촉탁 계약직으로 입사했기 때문에 고용상 연령차별금지 및 고령자고용촉진에 관한 법률 제21조에 의거 정년이후 퇴직금 계산을 위한 계속 근로 연수는 새로이 계산한다.`)
  
  return (
    <>
    <div className="jau__contents">
    <span style={{fontSize : '1rem'}}><strong>제7조 임금 및 퇴직금</strong></span>
      <ul>
        <li>
          ① {companyUn} 근로기준법 제17조에 따른 {worker}의 임금의 구성항목, 계산방법, 지급방법 및 
          퇴직급여보장법상의 퇴직금 등 임금 및 퇴직금과 관련하여 본 근로계약서의 내용이 반영된
          별도의 임금계약서를 {worker}에게 동 법률에 의거 교부하되, 두 당사자의 서명날인이 모두 있어야 근로계약의 효력이 발생된다.
        </li>
        {contractOldWorker &&
          <li>
            ② {contractOldWorkerText}
          </li>
        }
      </ul>
    </div>
    </>
  )
}



