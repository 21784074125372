import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from 'semantic-ui-react';
import CompanySelector from '../../../app/layout/CompanySelector';
import { freeComClient, isAgentF } from '../../../app/common/util/util';
import { superAdminForFree } from '../companyActions';

export default function PaydocuShortCut({ filter, clientReqs4In, realCount}) {
  const dispatch = useDispatch();

  const { currentUserProfile } = useSelector(state => state.profile);
  const { clientInfo, superAdminForFreeTrue } = useSelector(state => state.companyReducer);
  // const personalEvaluationYes = (clientInfo?.personalEvaluation ? clientInfo?.personalEvaluation : currentUserProfile?.personalEvaluation) === 'BSC 기반 인사고과 기능 사용' ? true : false;
  const history = useHistory();
  const superAdmin = currentUserProfile?.authLevel >= 101 ? true : false;
  const isAgent = isAgentF(currentUserProfile?.agentType, currentUserProfile?.authLevel) || currentUserProfile?.authLevel >= 100 ? true : false;

  const retirementPayDC = clientInfo?.retirementPay === 'DC형 퇴직연금' ? true : 
    currentUserProfile?.retirementPay === 'DC형 퇴직연금' ? true : false;

  const authLevel = clientInfo?.authLevel ? clientInfo?.authLevel : currentUserProfile?.authLevel;
  const ceoName = clientInfo?.ceoName ? clientInfo?.ceoName : currentUserProfile?.ceoName;

  const freeDeleteDisable = (!superAdminForFreeTrue && freeComClient(authLevel, ceoName)) ? true : false

  // 경로 이동을 위한 함수
  const navigateTo = (path) => history.push(path);

  useEffect(() => {
   dispatch(superAdminForFree(currentUserProfile?.authLevel))
  }, [dispatch, currentUserProfile?.authLevel])

      // 버튼 레이블과 경로 매핑
  const buttons = [
    { label: <span>직원<br />List</span>, path: '/clientReqs4In', color: 'blue' },
    authLevel < 10 ? null : { label: <span>정규직/계약직<br />단시간직 등록</span>, path: '/createClientReq4In', color: 'blue', disabled: freeDeleteDisable && clientReqs4In.length > 1},
    authLevel < 10 ? null : { label: <span>일용직/시급직<br />사업소득직 등록</span>, path: '/createClientReqDayWorker', color: 'blue', disabled: freeDeleteDisable && clientReqs4In.length > 1},
    authLevel < 10 ? null : { label: <span>급여<br />복사(월전체)</span>, path: '/paydocusCopy', color: 'blue', disabled: freeDeleteDisable },
    authLevel < 10 ? null : { label: <span>임금<br />명세서</span>, path: '/paydocus', color: 'yellow' },
    authLevel < 10 ? null : { label: <span>임금대장<br />(정규직 가로)</span>, path: '/paydocusTable', color: 'teal' },
    authLevel < 10 ? null : { label: <span>은행이체<br />{retirementPayDC ? "/DC 퇴직연금대장" : "임금대장"}</span>, path: '/paydocusTableBank', color: 'teal' },
    // { label: <span>임금대장<br />(3단형식)</span>, path: '/paydocusTable3Dan', color: 'teal' },
    // { label: <span>임금대장<br />(48조1항)</span>, path: '/paydocusTableLaw', color: 'teal' },
    // { label: <span>임금대장<br />(정규직 직원별)</span>, path: '/paydocusTableWorker', color: 'teal' },
    // { label: <span>임금대장<br />(일용직 직원별)</span>, path: '/paydocusTableDayWorker', color: 'teal' },
    // { label: <span>임금대장<br /><span style={{color:'yellow'}}>(일용직)</span></span>, path: '/paydocusDayWorkerTable', color: 'teal' },
    // { label: <span>임금대장<br /><span style={{color:'yellow'}}>(일용신고)</span></span>, path: '/paydocusDayWorkerTableReport', color: 'teal' },
    authLevel < 10 ? null : { label: <span>연차대장<br />(월급직 전체)</span>, path: '/annualLeavesTable', color: 'green' },
    // personalEvaluationYes && { label: <span>BSC<br />(월별)</span>, path: '/paydocusTableHr', color: 'purple' },
    // personalEvaluationYes && { label: <span>BSC<br />(직원별)</span>, path: '/paydocusTableWorkerHr', color: 'purple' },
    // { label: <span>세후급여<br />계산기</span>, path: '/netCalc', color: 'teal' },
    // { label: <span>연차 <br />계산기</span>, path: '/annualLeavesCalc', color: 'green' },
  ].filter(Boolean); // personalEvaluationYes가 false일 경우 해당 버튼을 제외

  return (
    <>
     {isAgent && <CompanySelector userUid={currentUserProfile?.id} filter={filter} superAdmin={superAdmin} clientInfo={clientInfo} clientReqs4In={clientReqs4In} realCount={realCount} />}
      {buttons.map(({ label, path, color, disabled }, index) => (
        <Button key={index} onClick={() => navigateTo(path)} color={color} disabled={disabled}>
          {label}
        </Button>
      ))}
      <hr />
      <br />
    </>
  );
};