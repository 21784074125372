import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown, Menu } from 'semantic-ui-react'

export default function PaydocuTableMenu({PiUserListFill, companyName, retirementPayDC}) {

  const history = useHistory();

    
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseOver = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };


  function reloadToPaydocusTable () {
    history.push('/paydocusTable');
  }

  function reloadToPaydocusTableBank () {
    history.push('/paydocusTableBank');
  }


  function reloadToPaydocusTableOverTime () {
    history.push('/paydocusTableOverTime');
  }

  function reloadToPaydocusTableWorker () {
    history.push('/paydocusTableWorker');
  }

  function reloadToPaydocusTableDayWorker () {
    history.push('/paydocusTableDayWorker');
  }


  function reloadToPaydocusTableBizWorker () {
    history.push('/paydocusTableBizWorker');
  }

  
  function reloadToPaydocusTableLaw () {
    history.push('/paydocusTableLaw');
  }


  function reloadToPaydocusDayWorkerTable () {
    history.push('/paydocusDayWorkerTable');
  }

  function reloadToPaydocusTableBiz () {
    history.push('/paydocusTableBiz');
  }

  function reloadToPaydocusDayWorkerTableReport () {
    history.push('/paydocusDayWorkerTableReport');
  }

  const childCompany = companyName.includes('어린이집') ? true : false;

  return (
    <>
      <Menu.Item className="menu-item" onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} onClick={handleDropdownToggle}>
      {!isMobile && <PiUserListFill className="icon-spacing" />}
        <Dropdown style={{width:'160px'}} pointing='top left' text='임금대장' open={dropdownOpen}>
          <Dropdown.Menu>
            <>
              <Dropdown.Item onClick={() => reloadToPaydocusTable()}  text='월급직(가로 형식)' />
              <Dropdown.Item onClick={() => reloadToPaydocusTableBank()}  text='월급직(계좌 이체용)' />
              <Dropdown.Item onClick={() => reloadToPaydocusTableLaw()}  text='월급직(48조1항)' />
              <Dropdown.Item onClick={() => reloadToPaydocusTableWorker()}  text='월급직(개인별)' />
              {retirementPayDC ? <Dropdown.Item onClick={() => reloadToPaydocusTableBank()}  text='DC 퇴직연금 대장' /> : null}
              {!childCompany ? null : 
                <>
                  <hr />
                  <p style={{color: "blue", textAlign: 'center'}}>어린이집용</p>
                  <Dropdown.Item onClick={() => reloadToPaydocusTableOverTime()}  text='연장근로 대장' />
                </>
              }
              <hr />
              <Dropdown.Item onClick={() => reloadToPaydocusDayWorkerTable()}  text='일용직(가로 형식)' />
              <Dropdown.Item onClick={() => reloadToPaydocusTableDayWorker()}  text='일용직(개인별)' />
              <Dropdown.Item onClick={() => reloadToPaydocusDayWorkerTableReport()}  text='일용직(신고용)' />
              <hr />
              <Dropdown.Item onClick={() => reloadToPaydocusTableBiz()}  text='사업소득직(가로 형식)' />
              <Dropdown.Item onClick={() => reloadToPaydocusTableBizWorker()}  text='사업소득직(개인별)' />
            </>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </>
  )
}
