import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useFirestoreDoc from '../../../../app/hooks/useFirestoreDoc';
import {
	addRetireFundClient4InToFirestore,
	listenToClientReq4InFromFirestore,
} from '../../../../app/firestore/firestoreService4In';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { toast } from 'react-toastify';
import { decrypto } from '../../../../app/firestore/firestoreService';
import {
	clearSelectedClientReq4In,
	listenToSelectedClientReq4In,
} from '../clientReqActions4In';
import { Button, Segment } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	averMonthDaysF,
	averMonthDaysUserInputF,
} from '../../../../app/common/util/retiredWageCalF';
import {
	clearPaydocu,
	fetchPaydocusFormFirestoreService4In,
} from '../../../companyManage/companyActions';
import MyNumberInput from '../../../../app/common/form/MyNumberInput';
import MyDateInput from '../../../../app/common/form/MyDateInput';
import MyTextInput from '../../../../app/common/form/MyTextInput';
import RetireWageCalFormItems from './RetireWageCalFormItems';
import { roundDown } from '../../../../app/common/util/util';

export default function RetireWageCalForm({ match, history, location }) {
	const dispatch = useDispatch();
	const { selectedClientReq4In } = useSelector(
		(state) => state.clientReq4InStore
	);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { yearMonth, paydocus, clientInfo } = useSelector(
		(state) => state.companyReducer
	);
	const { loading, error } = useSelector((state) => state.async);

	const [useUserInputButton, setUseUserInputButton] = useState(false);
	const [userInputData, setUserInputData] = useState({});

	// retiredDate 상태 관리
	const [retiredDate, setRetiredDate] = useState(
		selectedClientReq4In?.retiredDate || null
	);

	// retiredDate를 처음 한 번만 설정
	useEffect(() => {
		if (!retiredDate) {
			setRetiredDate(new Date()); // retiredDate가 없으면 현재 날짜로 설정
		}
	}, [retiredDate]);

	const workerEnteredDate = selectedClientReq4In?.workerEnteredDate;

	const hourOrdinaryWage = selectedClientReq4In?.lawBase?.hourOrdinaryWage
		? selectedClientReq4In?.lawBase?.hourOrdinaryWage
		: '';
	const paidHolidayLBTime = selectedClientReq4In?.lawBase?.paidHolidayLBTime
		? selectedClientReq4In?.lawBase?.paidHolidayLBTime
		: '';
	let retireFundTaxFreeValue;
	// 계산 로직: userInput 또는 paydocus 기반으로 처리

	let averMonthDays;
	if (useUserInputButton) {
		// 사용자 입력 기반 계산 로직
		averMonthDays = averMonthDaysUserInputF(
			hourOrdinaryWage,
			paidHolidayLBTime,
			retireFundTaxFreeValue,
			userInputData // userInputs로 사용
		);
	} else {
		// paydocus 데이터 기반 계산 로직
		averMonthDays = averMonthDaysF(
			retiredDate,
			workerEnteredDate,
			paydocus,
			hourOrdinaryWage,
			paidHolidayLBTime,
			retireFundTaxFreeValue
		);
	}

	const initialValues = useUserInputButton
		? {
				midCalDays: userInputData.midCalDays || '',
				midCalDaysComment: userInputData.midCalDaysComment || '',
				workerName: userInputData.workerName || '',
				retiredDate: userInputData.retiredDate || '',
				workerEnteredDate: userInputData.workerEnteredDate || '',
				totalWorkingDays: roundDown(averMonthDays.totalWorkingDays) || '',
				retiredMonthDay: userInputData.retiredMonthDay || '',
				retiredMonthDayEnd: userInputData.retiredMonthDayEnd || '',
				realAverWageOfRetiredMonth:
					userInputData.realAverWageOfRetiredMonth || '',
				retiredMonthDayCount: userInputData.retiredMonthDayCount || '',
				retiredMonthDay01: userInputData.retiredMonthDay01 || '',
				retiredMonthDayEnd01: userInputData.retiredMonthDayEnd01 || '',
				retireMonth1TotalWage: userInputData.retireMonth1TotalWage || '',
				retiredMonthDayCount01: userInputData.retiredMonthDayCount01 || '',
				retiredMonthDay02: userInputData.retiredMonthDay02 || '',
				retiredMonthDayEnd02: userInputData.retiredMonthDayEnd02 || '',
				retireMonth2TotalWage: userInputData.retireMonth2TotalWage || '',
				retiredMonthDayCount02: userInputData.retiredMonthDayCount02 || '',
				retiredMonthDay03: userInputData.retiredMonthDay03 || '',
				retiredMonthDayEnd03: userInputData.retiredMonthDayEnd03 || '',
				retireMonth3TotalWage: userInputData.retireMonth3TotalWage || '',
				retiredMonthDayCount03: userInputData.retiredMonthDayCount03 || '',
				retireYearTotalAnnualLeavesWages:
					userInputData.retireYearTotalAnnualLeavesWages || '',
				retireYearTotalBonusWages:
					userInputData.retireYearTotalBonusWages || '',
				exceptAverPeriodAndWageMemo: '',
				retireFundTaxFree: '',
				dayOrdinaryWage: userInputData.dayOrdinaryWage || '',
				retireAverageDayWage: averMonthDays.averageFinalDayWage || '',
				finalRetiredFund: averMonthDays.finalRetiredFund || '',
				retiredFundTax: averMonthDays.finalRetiredFundTax || '',
				retiredFundRegionTax: averMonthDays.finalRetiredFundRegionTax || '',
				retireFund: averMonthDays.retireFund || '',
		  }
		: {
				midCalDays: '',
				midCalDaysComment: '',
				workerName: selectedClientReq4In?.worker?.name || '',
				retiredDate: retiredDate || '',
				workerEnteredDate: workerEnteredDate || '',
				totalWorkingDays: averMonthDays.totalWorkingDays || '',
				retiredMonthDay: averMonthDays.retireMonthFirstDateString || '',
				retiredMonthDayEnd: averMonthDays.retireDateString || '',
				realAverWageOfRetiredMonth: averMonthDays.averWageOfOneMonth || '',
				retiredMonthDayCount: roundDown(averMonthDays.retireMonthDays) || '',
				retiredMonthDay01:
					averMonthDays.retireOneMonthBeforeFirstDateString || '',
				retiredMonthDayEnd01:
					averMonthDays.retireOneMonthBeforeEndDateString || '',
				retireMonth1TotalWage: averMonthDays.averWageOfOneMonth1 || '',
				retiredMonthDayCount01:
					averMonthDays.retireOneMonthBeforeMonthDays || '',
				retiredMonthDay02:
					averMonthDays.retireTwoMonthBeforeFirstDateString || '',
				retiredMonthDayEnd02:
					averMonthDays.retireTwoMonthBeforeEndDateString || '',
				retireMonth2TotalWage: averMonthDays.averWageOfOneMonth2 || '',
				retiredMonthDayCount02:
					averMonthDays.retireTwoMonthBeforeMonthDays || '',
				retiredMonthDay03:
					averMonthDays.retireThreeMonthBeforeFirstDateString || '',
				retiredMonthDayEnd03:
					averMonthDays.retireThreeMonthBeforeEndDateString || '',
				retireMonth3TotalWage: averMonthDays.averWageOfOneMonth3 || '',
				retiredMonthDayCount03:
					averMonthDays.retireThreeMonthBeforeMonthDays || '',
				retireYearTotalAnnualLeavesWages:
					averMonthDays.averAnnualLeavesWages || '',
				retireYearTotalBonusWages: averMonthDays.averBonusWages || '',
				exceptAverPeriodAndWageMemo: '',
				retireFundTaxFree: '',
				dayOrdinaryWage: averMonthDays.dayOrdinaryWage || '',
				retireAverageDayWage: averMonthDays.averageFinalDayWage || '',
				finalRetiredFund: averMonthDays.finalRetiredFund || '',
				retiredFundTax: averMonthDays.finalRetiredFundTax || '',
				retiredFundRegionTax: averMonthDays.finalRetiredFundRegionTax || '',
				retireFund: averMonthDays.retireFund || '',
		  };

	// 페이지 이동과 관련된 useEffect를 초기화 시킬 때만 실행되도록 변경
	useEffect(() => {
		if (location.pathname === '/createClientReq4In') {
			dispatch(clearSelectedClientReq4In());
		}
	}, [location.pathname, dispatch]);

	// 데이터베이스 관련 로직만 의존성 정리
	useEffect(() => {
		if (selectedClientReq4In?.id) {
			dispatch(
				fetchPaydocusFormFirestoreService4In(
					'isWorker',
					yearMonth,
					selectedClientReq4In?.id,
					clientInfo
				)
			);
		}
		return () => {
			dispatch(clearPaydocu());
		};
	}, [dispatch, yearMonth, selectedClientReq4In?.id, clientInfo]);

	const data = selectedClientReq4In;
	const decryptoData = {};
	for (const prop in data) {
		decryptoData[prop] =
			prop === 'workerSocialNumberFront' || prop === 'workerSocialNumberBack'
				? decrypto(data[prop], String(process.env.CRYPTO_KEY))
				: data[prop];
	}

	const validationSchemaEmployee4In = Yup.object({
		retireAverageDayWage: Yup.string().required('필수항목'),
		retireFund: Yup.string().required('필수항목'),
		finalRetiredFund: Yup.string().required('필수항목'),
	});

	useFirestoreDoc({
		shouldExcute:
			match.params.id !== selectedClientReq4In?.id &&
			location.pathname !== '/createClientReq4In',
		query: () => listenToClientReq4InFromFirestore(match.params.id),
		data: (clientReq4In) =>
			dispatch(listenToSelectedClientReq4In(clientReq4In)),
		deps: [match.params.id, dispatch],
	});

	if (loading)
		return <LoadingComponent content='Loading Worker Info edit ...' />;
	if (error) return <Redirect to='/error' />;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchemaEmployee4In}
			onSubmit={async (values, { setSubmitting }) => {
				try {
					await addRetireFundClient4InToFirestore(
						selectedClientReq4In.id,
						values
					);
					history.push(`/paydocus/`);
				} catch (error) {
					console.log(error.message);
					toast.error(error.message);
					setSubmitting(false);
				}
			}}
			enableReinitialize>
			{({ isSubmitting, dirty, isValid, values }) => (
				<Form className='ui form'>
					<Segment clearing>
						{currentUserProfile?.authLevel >= 10 && (
							<>
								<Button
									toggle
									active={useUserInputButton}
									onClick={() => setUseUserInputButton(!useUserInputButton)}
									type='button' // 이 부분을 추가하여 폼 제출을 방지합니다.
									color={useUserInputButton ? 'grey' : 'yellow'} // 조건에 따라 색상 변경
									content={`${
										useUserInputButton
											? '데이터베이스 기준(값 변경 불가)'
											: '중간정산 등 직접입력(값 변경 가능)'
									}`}
								/>
								<RetireWageCalFormItems
									selectedClientReq4In={selectedClientReq4In}
									MyDateInput={MyDateInput}
									MyTextInput={MyTextInput}
									MyNumberInput={MyNumberInput}
									Button={Button}
									isSubmitting={isSubmitting}
									Link={Link}
									isValid={isValid}
									values={values}
									retiredDate={selectedClientReq4In?.retiredDate}
									setUserInputData={setUserInputData}
									useUserInputButton={useUserInputButton}
								/>
							</>
						)}
					</Segment>
				</Form>
			)}
		</Formik>
	);
}
