import React, { useEffect, useState } from 'react';
import { Icon, Label } from 'semantic-ui-react';

import {
  eiOut4Type,
  retiredPaperType,
  firedPaperType,
  noticeFireType,
  noticeFireExceptionType,
  eiOutDetailReason,
} from '../../../../../app/api/dropdownOption';
import MyTextInput from '../../../../../app/common/form/MyTextInput';
import MySelectInput from '../../../../../app/common/form/MySelectInput';
import MyDateInputRetired from '../../../../../app/common/form/MyDateInputRetired';
import moment from 'moment/moment';
import { numberToCommaString } from '../../../../../app/common/util/util';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../../app/common/modals/modalReducer';


export default function CrwfWorkerRetireInfo41( {
  numOfWorkers,
  selectedClientReq4In,
  eiOutDetailType, 
  setEiOutDetailType,

  noticeFireOrNot, 
  setNoticeFireOrNot,
  firedPaperOrNot, 
  setFiredPaperOrNot,
  paydocus,
  workerEnteredDate,
  workerRetiredDate,
  setRetiredDate,
}) {

  const [selectedEiOutType, setSelectedEiOutType] = useState('');
  const dispatch = useDispatch();

  // 두 번째 Select의 옵션을 필터링된 상태로 저장
  const [filteredDetailReasons, setFilteredDetailReasons] = useState([]);  

  // 첫 번째 Select에서 선택된 값이 변경될 때마다 호출될 함수
  const handleEiOutTypeChange = (newValue) => {
    setSelectedEiOutType(newValue);
  };

  // 선택된 eiOutType에 따라 DetailReasons 필터링
  useEffect(() => {
    const filteredOptions = eiOutDetailReason.filter(option => 
      option.key.startsWith(selectedEiOutType.split('.')[0])
    );
    setFilteredDetailReasons(filteredOptions);
  }, [selectedEiOutType]);

  const enteredDateM = moment(workerEnteredDate)
  const retiredDateM = moment(workerRetiredDate) 
  const hiredPeriod = retiredDateM.diff(enteredDateM, 'month')

  const underThreeMonth = hiredPeriod < 3 ? true : false


  const hourOrdWageArray = paydocus ? paydocus.map(m => m?.fixedWage?.hourOrdinaryWage) : []
  const lastHourOrdWage = hourOrdWageArray ? hourOrdWageArray[hourOrdWageArray.length - 1] : ''
  const dayLawBaseTime = selectedClientReq4In?.realWorkTime?.days1LBTime

  function checkTerminationWithoutResignation(eiOutDetailType) {
    const hasTermination = eiOutDetailType.includes("해고");
    const hasResignation = eiOutDetailType.includes("사직");
    const hasSelfOut = eiOutDetailType.includes("이직");

    return hasTermination && !(hasResignation || hasSelfOut);
  }

  function workerRegStep4_1YTOpen() {
    dispatch(openModal({modalType:'WorkerRegStep4_1'}))
  }
  const fireYes = checkTerminationWithoutResignation(eiOutDetailType);

  const fireMoney = numberToCommaString(lastHourOrdWage * dayLawBaseTime * 30)

  return (
    <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
      <div style={{display: 'flex', alignItems : 'center', alignContent : 'flex-end', width : '100%', marginBottom : "30px", marginTop : "30px", fontSize: '17px'}}>
        <span style={{color : 'red'}}>* </span><span style={{color : 'blue'}}>퇴직/사직/해고 입력</span ><span style={{fontSize : '8px'}}>(설명은 오른쪽 동영상을 참조하세요.)</span>
        <span style={{position: "absolute", right: 0, marginRight:'10px', cursor:'pointer'}}><Label style={{color:'red'}} onClick={() => workerRegStep4_1YTOpen()}><Icon size='big' name='youtube' />퇴직구분 설명</Label></span>
      </div>
      <div className='margin' style={{width : '10%', marginRight : '3px'}}>
        <MyDateInputRetired 
          name='retiredDate'
          label='퇴직일 등(마지막 근무일)'
          placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
          timeCaption='time'
          dateFormat='yyyy-MM-dd'
          autoComplete='off'
          setRetiredDate={setRetiredDate}
        />
      </div>
      <div className='margin' style={{width : '30%', marginRight : '3px'}}>
        <MySelectInput
          onChangeCallback={handleEiOutTypeChange}
          label='고용보험 상실코드(필수)' 
          name='eiOutType' 
          placeholder='사직/권고사직/해고 등' 
          options={eiOut4Type} 
        />
      </div>
      <div className='margin' style={{width : '45%', marginRight : '3px'}}>
        <MySelectInput
          onChangeCallback={setEiOutDetailType}
          label='고용 상실 구체적 사유(필수)' 
          name='eiOutDetailType'
          placeholder='사직/권고사직/해고 등' 
          options={filteredDetailReasons} 
        />
      </div>
      <div className='margin' style={{width : '14%', marginRight : '3px'}}>
        <MyTextInput
          label='상실사유 코멘트(선택)' 
          name='eiOutReason' 
          placeholder='구체적인 상실사유'
        />
      </div>
      <div className='margin' style={{width : '33%', marginRight : '3px'}}>
        <MySelectInput
          label='사직서 서면 절차' 
          name='retiredPaperType' 
          placeholder='사직서  등' 
          options={retiredPaperType} 
          disabled={fireYes} 
        />
      </div>
        {numOfWorkers >= 5 &&
          <>
            <div className='margin' style={{width : '33%', marginRight : '3px'}}>
              <MySelectInput
                onChangeCallback = {setFiredPaperOrNot}
                label='해고통보서 서면 절차' 
                name='firedPaperType' 
                placeholder='해고통보서 발부' 
                options={firedPaperType} 
                disabled={!fireYes} 
              />
              {
                (fireYes && firedPaperOrNot  === '해고장 미교부' && numOfWorkers >= 5) ?
                <Label name='judgeIlligalFire' color='red'>근로기준법 제27조 해고서면통보 조항을 참조하세요.</Label> : null
              }
            </div>
            {
              fireYes && underThreeMonth 
                ?                         
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <Label name='under3MonthWorker' color='red'>근속기간 3개월 미만 직원은 해고 예고 대상자가 아닙니다.</Label>
                  </div>
                :
                  <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                    <MySelectInput
                      onChangeCallback={setNoticeFireOrNot}
                      label='해고예고' 
                      name='noticeFire' 
                      placeholder={lastHourOrdWage ? `${fireMoney}` : '30일분의 통상임금'} 
                      options={noticeFireType} 
                      disabled={!fireYes || underThreeMonth}
                    />
                    {fireYes && paydocus && lastHourOrdWage ? 
                      <Label color='blue'>해고예고수당(30일분의 통상임금) : {lastHourOrdWage ? `${fireMoney}`.toLocaleString('en-GB') : ''}원</Label>
                      : null}
                    </div>
            }
            {
              noticeFireOrNot === '해고예고 안함' &&
                <div className='margin' style={{width : '33%', marginRight : '3px'}}>
                  <MySelectInput
                    label='해고예고 예외사유' 
                    name='noticeFireException' 
                    placeholder='예) 근속3개월 미만자' 
                    options={noticeFireExceptionType} 
                  />
                </div>
            }
        </>
      }
    </div>
  )
}