import React, { useState, useMemo, useEffect } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && (
						<div className='no-print'>
							<ul>
								{amended.map((amendment, i) => (
									<li
										key={i}
										style={{
											color: amendment.includes('개정') ? 'red' : 'black',
										}}>
										{amendment}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function Roe4OperationRules({
	serviceStartDate,
	// profile,
	// authLevel,
	company,
	companyUn,
	companyGa,
	allOpen,
	previousSectionCount1,
	previousSectionCount2,
	previousSectionCount3,
	setSectionCount,
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `협의회 회의`,
				content: [
					`협의회의 정기회의는 매분기 말월 마지막주 수요일에 개최하되 노사간의 협의에 따라 다른 날에 정기회의를 개최할 수 있다.`,
					`의장은 노사일방의 대표자가 회의의 목적 등을 문서로 명시하여 회의의 소집을 요구할 때에는 이에 응하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `회의소집`,
				content: [
					`협의회의 회의는 의장이 소집한다.`,
					`의장은 노사일방의 대표자가 회의의 목적 등을 문서로 명시하여 회의의 소집을 요구할 때에는 이에 응하여야 한다.`,
					`의장은 회의 개최 7일전에 회의일시, 장소, 의제 등을 각 위원에게 통보하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `사전 자료 제공`,
				content: [
					`근로자위원은 회의의제로 통보된 의제 중 협의사항 및 의결사항과 관련된 자료를 협의회 회의 개최 전에 사용자에게 요구할 수 있으며 사용자는 이에 성실히 응하여야 한다.`,
					`제1항에도 불구하고 근로자위원의 요구자료가 ${company}의 경영∙영업상의 비밀 또는 개인정보에 해당하는 경우에는 그러하지 아니한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `정족수`,
				content: [
					`회의는 근로자위원과 사용자위원 각 과반수의 출석으로 개최하고 출석위원 3분의 2 이상의 찬성으로 의결한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `회의의 공개`,
				content: [
					`협의회의 회의는 공개한다.`,
					`제1항에도 불구하고 협의회의 의결로 공개하지 아니할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `비밀의 유지`,
				content: [
					`협의회의 위원은 협의회에서 지득한 비밀을 누설하여서는 아니 된다. 다만, 비밀의 범위는 매 회의에서 정한다.`,
					`협의회위원이 비밀을 누설한 경우에는 징계위원회에 회부한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `회의록의 비치`,
				content: [
					`회의록은 노사쌍방의 간사중 1인이 작성하여 각 1부씩 보관한다.`,
					`협의회는 다음 각호의 사항을 기록한 회의록을 작성∙비치한다.`,
					[
						`개최 일시 및 장소`,
						`출석 위원`,
						`협의 내용 및 의결된 사항`,
						`그 밖의 토의사항`,
					],
					`회의록에는 출석위원 전원이 서명하거나 날인한다.`,
					`회의록은 작성일로부터 3년간 보존하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
		];

		// if (['대한민국', '한국', '일본'].includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles.includes(section.title)
			);
		}

		return baseSections;
	}, [company]);

	useEffect(() => {
		setSectionCount(sections.length);
	}, [sections, setSectionCount]);

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section?.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className='jang sectionup'>제4장 협의회의 운영</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={
							index +
							1 +
							previousSectionCount1 +
							previousSectionCount2 +
							previousSectionCount3
						}
						title={section?.title}
						content={section?.content}
						isOpen={!!openSections[section?.title]}
						toggleOpen={() => toggleSection(section?.title)}
						amended={section?.amended}
					/>
				</div>
			))}
		</>
	);
}
