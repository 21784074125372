import React, { useState, useMemo, useEffect } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && (
						<div className='no-print'>
							<ul>
								{amended.map((amendment, i) => (
									<li
										key={i}
										style={{
											color: amendment.includes('개정') ? 'red' : 'black',
										}}>
										{amendment}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function RoeBS5WorkerManageRules({
	serviceStartDate,
	// profile,
	// authLevel,
	company,
	companyUn,
	companyGa,
	allOpen,
	previousSectionCount1,
	previousSectionCount2,
	previousSectionCount3,
	previousSectionCount4,
	setSectionCount,
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `서약서`,
				content: [
					`신규입사사원은 다음 각 호의 서약서를 영업비밀 총괄책임자에게 3일 이내에 제출하여야 한다.`,
					[`비밀유지서약서`, `경업금지서약서`, `동종업종 취업금지 서약서`],
					`전항 제2호와 제3호는 사업자등록증 상 동종 업태와 종목을 말한다.`,
					`경력자로서 입사한 사원은 다음 각 호의 서약서를 영업비밀 총괄책임자에게 3일 이내에 제출하여야 한다.`,
					[
						`비밀유지서약서`,
						`경업금지서약서`,
						`동종업종 취업금지 서약서`,
						`전직회사의 영업비밀을 재직 중에 사용하지 않겠다는 서약서`,
					],
					`전항 제2호와 제3호는 사업자등록증 상 동종 업태와 종목을 말한다.`,
					`경력자로서 입사한 사원에 대해서는 전직회사의 영업비밀 보유여부, 영업비밀유지서약의 내용 등을 조사하여야 한다.`,
					`경력자로서 입사한 사원은 전직 회사와 맺은 영업비밀유지서약의 개요를 영업비밀 총괄책임자에게 3일 이내에 제출하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `전직 영업비밀 유지 의무자`,
				content: [
					`전직 회사 영업비밀 유지 의무자는 전 직장의 직무와 동일한 직무를 전직 회사 영업비밀 유지기간 동안 담당할 수 없다.`,
					`전직 회사 영업비밀 유지의무자는 전 직장의 영업비밀을 전직 회사 영업비밀 유지기간 동안 사용하여서는 안된다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `신입사원(경력사원 포함) 영업비밀 관리교육`,
				content: [
					`신입 사원은 1주일 이내에 1시간 이상의 영업비밀관리교육을 받아야 한다.`,
					`영업비밀 관리교육은 영업비밀 총괄책임자 또는 각 부서의 영업비밀 관리책임자가 실시한다.`,
					`신입 사원은 영업비밀관리교육수료증을 1주일 이내에 영업비밀 총괄책임자에게 제출하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `재직 중 영업비밀누설 금지`,
				content: [
					`사원은 재직 시 취득한 영업비밀에 대하여는 이 규정에 따라 취급·관리해야 하며, 허가 없이 이를 유출·공개 또는 사용할 수 없다.`,
					`연구개발 결과, 신제품 등을 발표하거나 전람회 등에 출품하여 부득이 하게 영업비밀을 공개하게 되는 경우에는 사전에 승인을 얻어야 한다.`,
					`업무상 또는 계약상 타인의 영업비밀을 보유한 때에는 계약내용, 사용범위, 사용기간, 사용방법 및 취급절차 등을 명확히 하고 최대한으로 보호하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `재직자 영업비밀 보호교육`,
				content: [
					`모든 사원은 매년 6월 영업비밀 관리의 날에 영업비밀 총괄책임자가 시행하는 영업비밀보호에 대한 교육을 받아야 한다.`,
					`모든 사원은 영업비밀의 보호에 특별히 필요한 경우 영업비밀 총괄책임자가 시행하는 영업비밀보호에 대한 교육을 받아야 한다.`,
					`모든 사원은 영업비밀 보호교육수료증을 1주일 이내에 영업비밀 총괄책임자에게 제출하여야 한다.`,
					`영업비밀을 취급하는 모든 사원은 매월 영업비밀 관리의 날에 영업비밀 관리책임자가 시행하는 영업비밀 직무교육을 받아야 한다.`,
					`영업비밀을 취급하는 모든 사원은 영업비밀의 보호에 특별히 필요한 경우 영업비밀 관리책임자가 시행하는 영업비밀 직무교육을 받아야 한다.`,
					`영업비밀을 취급하는 모든 사원은 영업비밀 직무교육수료증을 1주일 이내에 영업비밀 총괄책임자에게 제출하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀 신고 및 보상`,
				content: [
					`모든 사원은 직무수행과정에서 발견 또는 창출된 영업비밀을 영업비밀 관리책임자에게 신고하여야 한다.`,
					`영업비밀 관리책임자는 신고된 영업비밀을 영업비밀 총괄책임자에게 3일 이내에 보고하여야 한다.`,
					`우수한 영업비밀을 연구개발 또는 창안한 자는 각 부서 영업비밀 관리책임자의 추천을 받아 영업비밀 관리위원회의 심의를 거쳐 적정한 보상을 하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀 인계인수`,
				content: [
					`모든 사원은 다음 각 호의 경우에 영업비밀을 인계하여야 한다.`,
					[
						`조직의 개편 또는 업무의 조정으로 영업비밀의 소관부서가 변동될 때`,
						`영업비밀취급자가 인사 발령될 때`,
						`영업비밀취급자가 휴직할 때`,
					],
					`제1항 제1호의 사유가 발생하면 다음과 각 호와 같이 영업비밀을 인계한다.`,
					[
						`영업비밀을 인수할 부서에 2일 이내에 인계한다.`,
						`영업비밀을 인수할 부서가 없을 때에는 영업비밀생산부서에 반납한다.`,
						`자체에서 작성한 영업비밀은 영업비밀총괄책임자와 협의하여 처리한다.`,
						`영업비밀을 인계인수한 사실을 관련부서에 통보하여야 한다.`,
					],
					`제1항 제2호 또는 제3호의 사유로 영업비밀취급자의 변동이 있을 때에는 영업비밀 문서관리대장의 마지막 기록란 밑에 인계인수자 인적 사항, 등급별 비밀건수, 인계인수 년월일, 부서장 등 관계자 확인을 받아 인계인수한다.`,
					`제1항의 사유로 영업비밀을 인계한 전임자는 인계일로부터 1개월 동안 후임자의 업무를 지원한다.`,
					`영업비밀의 인계를 해태 또는 불이행하여 ${company}의 손해가 발생한 경우는 당해 사원은 징계책임을 부담한다.`,
					`영업비밀의 인계를 해태 또는 불이행하여 ${company}의 손해가 발생한 경우는 당해 사원은 이와 상당인과관계가 있는 실제 손해액에 대한 배상책임을 부담한다.`,
					`손해배상의 청구여부 및 절차는 민사절차에 따른다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `인사관리`,
				content: [
					`영업비밀 총괄책임자 또는 영업비밀 관리책임자는 영업비밀 당연취급자와 영업비밀 인가취급자의 영업비밀 취급이력서를 작성하여야 한다.`,
					`제1항의 영업비밀취급 이력서에는 다음 각 호의 내용을 기재하여야 한다.`,
					[`취급한 영업비밀의 내용 요약`, `취급기간`, `영업비밀 유지기간`],
					`영업비밀 취급자의 인사이동이 발생한 경우 영업비밀 취급이력서를 관계부서장에게 송부하여야 한다.`,
					`전항의 영업비밀 취급이력서를 송부 받은 관계부서장은 이를 인사기록카드에 기입하여야 한다.`,
					`영업비밀 취급이력서는 퇴직 시 영업비밀유지 서약서 작성에 참고한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀누설 금지`,
				content: [
					`${company}의 사원이었던 자는 ${company}의 사전승인 없이 재직 시 취득한 영업비밀을 공개·유출 또는 사용할 수 없다.`,
					`연구개발 결과, 신제품 등을 발표하거나 전람회 등에 출품하여 부득이 하게 영업비밀을 공개하게 되는 경우에는 사전에 승인을 얻어야 한다.`,
					`퇴직할 경우 사원은 보유하고 있는 모든 영업비밀을 반납하고, 퇴직자 영업비밀유지 서약서를 작성하여 영업비밀 총괄책임자에게 제출하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `경업금지`,
				content: [
					`${company}의 사원이었던 자는 퇴사한 후 1년간 사업자등록증상 ${company}와 동일한 업태와 종목의 사업을 할 수 없다.`,
					`퇴직할 경우 사원은 퇴직자 경업금지 서약서를 작성하여 영업비밀 총괄책임자에게 제출하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `동종업체 취업금지`,
				content: [
					`${company}의 사원이었던 자는 퇴사한 후 1년간 사업자등록증상 ${company}와 동일한 업태와 종목의 사업을 영위하는 회사에 취업할 수 없다.`,
					`퇴직할 경우 사원은 퇴직자 취업금지 서약서를 작성하여 영업비밀 총괄책임자에게 제출하여야 한다.`,
					`퇴직한 사원이 동종의 경쟁업체에 취업한 경우 영업비밀 보호협조 통지서를 당해 경쟁업체에 송부한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀 보호교육`,
				content: [
					`퇴직 사원은 영업비밀 총괄책임자가 시행하는 영업비밀보호에 대한 교육을 받아야 한다.`,
					`퇴직 사원은 영업비밀 총괄책임자가 시행하는 부정경쟁방지 및 영업비밀 보호에 관한 법률의 교육을 받아야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀 인계인수`,
				content: [
					`퇴직 사원은 다음과 각 호와 같이 영업비밀을 인계한다.`,
					[
						`영업비밀을 인수할 후임자에 2일 이내에 인계한다.`,
						`영업비밀을 인수할 후임자가 없을 때에는 영업비밀 관리책임자에게 영업비밀을 인계한다.`,
						`스스로 작성한 영업비밀은 영업비밀 총괄책임자와 협의하여 처리한다.`,
					],
					`영업비밀을 인계할 때에는 영업비밀 문서관리대장의 마지막 기록란 밑에 인계인수자 인적 사항, 등급별 비밀건수, 인계인수 년월일, 부서장 등 관계자 확인을 받아 인계인수한다.`,
					`영업비밀의 인계를 해태 또는 불이행하여 ${company}의 손해가 발생한 경우는 퇴직 사원은 이와 상당인과관계가 있는 실제 손해액에 대한 배상책임을 부담한다.`,
					`손해배상의 청구여부 및 절차는 민사절차에 따른다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `영업비밀관련 서류 등의 반납`,
				content: [
					`퇴직 사원은 영업비밀 관리책임자에게 재직 중 관리하였던 영업비밀 관련 서류 및 기기 등 일체를 반납하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `벌칙`,
				content: [
					`사원이 본 규정을 위반했을 때는 관계법령에 의한 민·형사 책임과는 별도로 사규에 의한 징계를 받을 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
		];

		// if (['대한민국', '한국', '일본'].includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles.includes(section.title)
			);
		}

		setSectionCount(baseSections.length); // 조항 수 설정

		return baseSections;
	}, [serviceStartDate, company, companyUn, companyGa, setSectionCount]); // eslint-disable-line react-hooks/exhaustive-deps

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className='jang sectionup'>제5장 사원 신분제도</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={
							index +
							1 +
							previousSectionCount1 +
							previousSectionCount2 +
							previousSectionCount3 +
							previousSectionCount4
						}
						title={section?.title}
						content={section?.content}
						isOpen={!!openSections[section?.title]}
						toggleOpen={() => toggleSection(section?.title)}
						amended={section?.amended}
					/>
				</div>
			))}
		</>
	);
}
