import React from 'react'
import { Table } from 'semantic-ui-react'

export default function ClientReqDLCWageTableAddWageDetail({
  handelInputChange,
  centerStyle,
  inputTimeStyle,
  plusRegularBonus,
  plusIrregularBonus,
  plusUnusedAnnualLeavesDays,
  plusRetiredUnusedAnnualLeavesWage,
  plusSpecialRandomWage,
  plusLastMonthAverWage,
  plusPersonalIncentive,
  plusRegularBonusCommentAlert,
  plusLastMonthAverWageAlert,
  plusPersonalIncentiveAlert,
  plusAnnualLeavesCommentAlert,
  plusRetiredAnnualLeavesCommentAlert,
  plusSpecialRandomWageAlert,
  isRetireMonth,
  perDayMealTaxFree,
  plusIrregularDayMealTFDays
}) {
  
  return (
    <Table.Row>
      <Table.Cell className='gubun' style={centerStyle}><input onClick={plusRegularBonusCommentAlert} style={inputTimeStyle} name = "plusRegularBonus"  value = {plusRegularBonus || ''} onChange= {(e) => handelInputChange(e)} autoComplete='off' /></Table.Cell>
      {/* <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = "plusIrregularBonus" value = {plusIrregularBonus || ''} onChange= {(e) => handelInputChange(e)} autoComplete='off'  /></Table.Cell> */}
      <Table.Cell className='gubun' style={centerStyle}><input onClick={plusAnnualLeavesCommentAlert} style={inputTimeStyle} name = 'plusUnusedAnnualLeavesDays' value = {plusUnusedAnnualLeavesDays || ''} onChange= {(e) => handelInputChange(e)} autoComplete='off'/></Table.Cell> 
      <Table.Cell className='gubun' style={centerStyle}><input onClick={plusPersonalIncentiveAlert} style={inputTimeStyle} name = "plusPersonalIncentive" value = {plusPersonalIncentive || ''} onChange= {(e) => handelInputChange(e)} autoComplete='off'  /></Table.Cell>
      <Table.Cell className='gubun' style={centerStyle}><input onClick={plusLastMonthAverWageAlert} style={inputTimeStyle} name = 'plusLastMonthAverWage' value = {plusLastMonthAverWage || ''} onChange= {(e) => handelInputChange(e)} autoComplete='off'/></Table.Cell> 
      {perDayMealTaxFree ? <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = "plusIrregularDayMealTFDays" value = {plusIrregularDayMealTFDays || ''} onChange= {(e) => handelInputChange(e)} autoComplete='off'  /></Table.Cell> : <Table.Cell></Table.Cell>}
      <Table.Cell></Table.Cell>
      <Table.Cell></Table.Cell>
      <Table.Cell className='gubun' style={centerStyle}><input onClick={plusSpecialRandomWageAlert} style={inputTimeStyle} name = 'plusSpecialRandomWage' value = {plusSpecialRandomWage || ''} onChange= {(e) => handelInputChange(e)} autoComplete='off'/></Table.Cell> 
      {isRetireMonth ? <Table.Cell className='gubun' style={centerStyle}><input onClick={plusRetiredAnnualLeavesCommentAlert} style={inputTimeStyle} name = 'plusRetiredUnusedAnnualLeavesWage' value = {plusRetiredUnusedAnnualLeavesWage || ''} onChange= {(e) => handelInputChange(e)} autoComplete='off'/></Table.Cell> : <Table.Cell></Table.Cell>}
    </Table.Row>
  )
}
