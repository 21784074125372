import React from 'react'

function ClientReqDLCDDayOrTimeWorkerWage({
  numOfWorkers,
  daylyPay,
  realTimeWage,
  week14OverBLTime, 
  dayTotalPaidConversedTime,
  company,
  companyUn,
  worker,
  workerGa,
  workingTimeDayBasic,
  workingTimeDayOver,
  workingTimeDayNight,
  dayWorkerPaidHolidayLBTime,
  daylyTimePay,
  numberToCommaString, 
  roundUp, 
  roundUp2,
  daylyPayDay,
}) {
  const basicTimeWage = week14OverBLTime === '주휴수당 포함' ? daylyTimePay / 1.2 : daylyTimePay
  const confirmedPaidHoliWage = week14OverBLTime === '주휴수당 포함' ? daylyTimePay - basicTimeWage : 0
  return (
    <div className="jau__contents">
    <div style={{fontSize : '1.5rem'}}><strong>제6조 임금 및 금품청산</strong></div>
      {daylyTimePay > 0
        ? 
          <ul>
            <>
              {week14OverBLTime === '주휴수당 포함' 
                ? 
                  <>
                    <li>
                      ① 임금의 구성항목 및 계산방법 : 전체 시급 {numberToCommaString(roundUp(daylyTimePay))}원[기본시급 ({numberToCommaString(roundUp(basicTimeWage))})원 + 주휴수당(시급 {numberToCommaString(roundUp(confirmedPaidHoliWage))})원] * 해당일 또는 해당기간 근무시간 
                    </li>
                    <li>
                      ② 제1항에 따른 시급에 근로시간을 곱한 임금은 당일 지급을 원칙으로 한다. 다만 {company}의 자금 집행 스케줄상 매월 특정일에 인건비가 집행되는 경우 {workerGa} 동의하면 시급으로 계산된 임금 지급을 특정일로 변경할 수 있다.
                    </li>
                    <li style={{color: 'blue'}}>
                      * 지급연장일 : 매월 ({daylyPayDay ? daylyPayDay : `&nbsp;&nbsp;&nbsp;&nbsp;`})일, {'  '} *동의자 : {worker}(인 또는 사인)
                    </li>
                    {numOfWorkers >= 5
                    ?
                      <li>
                        ③ 1일 소정근로시간 8시간, 1주 40시간을 초과하는 연장근로와 저녁 10시이후 오전 06시 이전의 야간근로, 휴일근로수당은 발생시 지급한다.
                      </li>
                    :
                      <li>
                        ③ {companyUn} 상시근로자수 5인 미만 사업장으로  저녁 10시이후 오전 06시 이전의 야간근로 외에 1일 소정근로시간 8시간, 1주 40시간을 초과하는 연장근로와, 휴일근로수당은 지급하지 아니한다.
                      </li>
                    }
                    <li>
                      ④ {companyUn} {worker}에게 임금지급시 근로소득세, 지방소득세 및 고용보험료 등이 발생하면 이를 원천징수하고 지급하되, 시급직이지만 1개월간 8일이상 근무하는 경우 등은 국민연금법, 건강보험법 등에 의거 국민연금과 건강보험료를 원천징수 하고 임금을 지급한다.
                    </li>
                  </>
                :
                  <>
                    <li>
                      ① 임금의 구성항목 및 계산방법 : 시급 {numberToCommaString(roundUp(daylyTimePay))}원 * 해당일 또는 해당기간 근무시간 + (1주 실근무시간이 15시간 이상인 경우 제5조 제1항에 따른 주휴수당 추가) 
                    </li>
                    <li>
                      ② 제1항에 따른 시급에 근로시간을 곱한 임금은 당일 지급을 원칙으로 한다. 다만 {company}의 자금 집행 스케줄상 매월 특정일에 인건비가 집행되는 경우 {workerGa} 동의하면 시급으로 계산된 임금 지급을 특정일로 변경할 수 있다.
                    </li>
                    <li style={{color: 'blue'}}>
                      * 지급연장일 : 매월 ({daylyPayDay ? daylyPayDay : `&nbsp;&nbsp;&nbsp;&nbsp;`})일, {'  '} *동의자 : {worker}(인 또는 사인)
                    </li>
                    {numOfWorkers >= 5
                    ?
                      <li>
                        ③ 1일 소정근로시간 8시간, 1주 40시간을 초과하는 연장근로와 저녁 10시이후 오전 06시 이전의 야간근로, 휴일근로수당은 발생시 지급한다.
                      </li>
                    :
                      <li>
                        ③ {companyUn} 상시근로자수 5인 미만 사업장으로  저녁 10시이후 오전 06시 이전의 야간근로 외에 1일 소정근로시간 8시간, 1주 40시간을 초과하는 연장근로와, 휴일근로수당은 지급하지 아니한다.
                      </li>
                    }
                    <li>
                      ④ {companyUn} {worker}에게 임금지급시 근로소득세, 지방소득세 및 고용보험료 등이 발생하면 이를 원천징수하고 지급하되, 시급직이지만 1개월간 8일이상 근무하는 경우 등은 국민연금법, 건강보험법 등에 의거 국민연금과 건강보험료를 원천징수 하고 임금을 지급한다.
                    </li>
                  </>
              }
            </>
          </ul>
        : 
          <ul>
            {numOfWorkers < 5 
              ?
                <>
                  <li>
                      ① 임금의 구성항목 : 일급 {numberToCommaString(roundUp(daylyPay))}원
                  </li>
                  <li>
                      ② 시급의 계산방법 : 시급 {numberToCommaString(roundUp(realTimeWage))}원 = 일급 {numberToCommaString(roundUp(daylyPay))}원 / 유급 {roundUp2(dayTotalPaidConversedTime)}시간
                  </li>
                  {week14OverBLTime === '주휴수당 포함' 
                    ?
                      <>
                        <li>
                          ③ 유급시간 계산 : 1일 근로시간(휴게시간 제외) {roundUp2(dayTotalPaidConversedTime)}시간[주휴수당 {roundUp2(dayWorkerPaidHolidayLBTime)}시간 포함, 5인미만 사업장 가산임금 미포함]
                        </li>
                        <li>
                          ④ 제1항에 따른 일급은 당일 지급을 원칙으로 한다. 다만 {company}의 자금 집행 스케줄상 매월 특정일에 인건비가 집행되는 경우 {workerGa} 동의하면 일급 지급을 특정일로 변경할 수 있다.
                        </li>
                        <li>
                          ⑤ {companyUn} {worker}에게 임금지급시 근로소득세, 지방소득세 및 고용보험료 등이 발생하면 이를 원천징수하고 지급하되, 일용직이지만 1개월간 8일이상 근무하는 경우 등은 국민연금법, 건강보험법 등에 의거 국민연금과 건강보험료를 원천징수 하고 임금을 지급한다.
                        </li>
                      </>
                    : 
                      <>
                        <li>
                          ③ 유급시간 계산 : 1일 근로시간(휴게시간 제외) {roundUp2(dayTotalPaidConversedTime)}[시간5인미만 사업장 가산임금 미포함]
                        </li>
                        <li>
                          ④ 전조 각항의 예외(유급주휴, 유급연차휴가) 발생시 {companyUn} {worker}에게 해당 주휴수당 및 연차유급휴가 수당을 별도로 지급한다.
                        </li>
                        <li>
                          ⑤ {companyUn} {worker}에게 제1항에 따른 일급에 대해 당일 지급을 원칙으로 한다. 다만 {company} 자금 집행 스케줄상 매월 특정일에 인건비가 집행되는 경우 {workerGa} 동의하면 일급 지급을 특정일로 변경할 수 있다.
                        </li>
                        <li>
                          ⑥ {companyUn} {worker}에게 임금지급시 근로소득세, 지방소득세 및 고용보험료 등이 발생하면 원천징수하고 지급하되, 일용직이지만 1개월간 8일이상 근무하는 경우 등은 국민연금법, 건강보험법 등에 의거 국민연금과 건강보험료를 원천징수 하고 임금을 지급한다.
                        </li>
                      </>
                    }
                </>
              :
                <>
                  <li>
                    ① 임금의 구성항목 : 일급 {numberToCommaString(roundUp(daylyPay))}원
                  </li>
                  <li>
                    ② 시급의 계산방법 : 시급 {numberToCommaString(roundUp(realTimeWage))}원 = 일급 {numberToCommaString(roundUp(daylyPay))}원 / (유급 {roundUp2(dayTotalPaidConversedTime)}시간)
                  </li>
                  <li>
                    {`③ 유급시간 계산 : ${dayTotalPaidConversedTime}시간 <= 기본 ${workingTimeDayBasic}시간`}
                    {workingTimeDayOver > 0 ? `+ 연장 ${roundUp2(workingTimeDayOver)}시간 * 1.5배`: null} 
                    {workingTimeDayNight > 0 ? `+ 야간 ${roundUp2(workingTimeDayNight)}시간 * 0.5배`: null}
                    {week14OverBLTime === '주휴수당 포함' ? `+ 주휴수당(${roundUp2(dayWorkerPaidHolidayLBTime)}시간) 포함` : null}
                  </li>
                  {week14OverBLTime === '주휴수당 포함' 
                    ?
                      <>
                        <li>
                          ④ {companyUn} {worker}에게 제1항에 따른 일급에 대해 당일 지급을 원칙으로 한다. 다만 {company} 자금 집행 스케줄상 매월 특정일에 인건비가 집행되는 경우 {workerGa} 동의하면 일급 지급을 해당 특정일로 변경할 수 있다.
                        </li>
                        <li>
                          ⑤ {companyUn} {worker}에게 임금지급시 근로소득세, 지방소득세 및 고용보험료 등이 발생하면 이를 원천징수하고 지급하되, 일용직이지만 1개월간 8일이상 근무하는 경우 등은 국민연금법, 건강보험법 등에 의거 국민연금과 건강보험료를 원천징수 하고 임금을 지급한다.
                        </li>
                      </>

                    : 
                      <>
                        <li>
                          ④ 전조 각항의 예외(유급주휴, 유급연차휴가) 발생시 {companyUn} {worker}에게 해당 주휴수당 및 연차유급휴가 수당을 별도로 지급한다.
                        </li>
                        <li>
                          ⑤ {companyUn} {worker}에게 제1항에 따른 일급에 대해 당일 지급을 원칙으로 한다. 다만 {company} 자금 집행 스케줄상 매월 특정일에 인건비가 집행되는 경우 {workerGa} 동의하면 일급 지급을 해당 특정일로 변경할 수 있다.
                        </li>
                        <li>
                          ⑥ {companyUn} {worker}에게 임금지급시 근로소득세, 지방소득세 및 고용보험료 등이 발생하면 원천징수하고 지급하되, 일용직이지만 1개월간 8일이상 근무하는 경우 등은 국민연금법, 건강보험법 등에 의거 국민연금과 건강보험료를 원천징수 하고 임금을 지급한다.
                        </li>
                      </>
                  }              
                </>
            }
            <li style={{color: 'blue'}}>
              * 지급연장일 : 매월 ({daylyPayDay ? daylyPayDay : `&nbsp;&nbsp;&nbsp;&nbsp;`})일, {'  '} *동의자 : {worker}(인 또는 사인)
            </li>
          </ul>
      }
    </div>
  )
}

export default ClientReqDLCDDayOrTimeWorkerWage