import React from 'react';
import { josaGaType, josaUlType, josaUnType, josaWaType } from '../../../../../app/common/util/util';

export default function ClientReqDLCEndContract({numOfWorkers, companyName, worker}) {
  const companyUn = josaUnType(companyName);
  const companyGa = josaGaType(companyName);
  const companyWa = josaWaType(companyName);

  const workerGa = josaGaType(worker);
  const workerUn = josaUnType(worker);
  const workerUl = josaUlType(worker);

  const resignPeriodText = (`① ${workerUn} 민법 제660조(고용의 해지통고) 제1항에 의거 ${companyName}에게 언제든지 근로계약의 해지 통고를 할 수 있으나, 
  ${companyGa} 사직서를 수리하지 않은 이상, ${workerGa} 동법 제3항에 의거 근로계약의 해지를 통고 한 해당월이 아닌 다음달 말일이 경과하면 ${workerGa} 통고한 근로계약 해지의 효력이 생긴다.`)

  const unauthorizedResignationText = (`② 제1항의 해지의 효력이 발생하기 전에 ${worker}의 근로계약 해지 통고를 받은 ${companyGa} 사직서를 수리하지 아니한 상태에서 
    ${workerGa} 결근하여 ${companyGa} 출근독촉을 했음에도 불구하고 ${workerGa} 퇴직한다는 것은 ${companyWa} ${worker}의 근로관계가 존속하는 상태에서 ${workerGa} 고의로 근로제공을 
    거부하는 것으로 이는 퇴직이 아닌 무단 결근이고 그 무단 결근으로 인해 ${companyName}에 손해가 발생하는 경우 ${workerUn} 해당 실 손해에 대해 ${companyName}에게 배상하여야 한다.`)
  const noFireNoticeText = 
  (            
    <>      
      <li>1. {workerGa} 계속 근로한 기간이 3개월 미만인 경우</li>
      <li>2. 천재사변, 그 밖의 부득이한 사유로 {companyGa} 사업을 계속하는 것이 불가능한 경우</li>
      <li>3. {workerGa} 납품업체로부터 금품이나 향응을 받고 불량품을 납품받아 생산에 차질을 가져온 경우</li>
      <li>4. {workerGa} 영업용 차량을 임의로 타인에게 대리운전하게 하여 교통사고를 일으킨 경우</li>
      <li>5. {workerGa} 사업의 기밀이나 그 밖의 정보를 경쟁관계에 있는 다른 사업자 등에게 제공하여 사업에 지장을 가져온 경우</li>
      <li>6. {workerGa} 허위 사실을 날조하여 유포하거나 불법 집단행동을 주도하여 사업에 막대한 지장을 가져온 경우</li>
      <li>7. {workerGa} 영업용 차량 운송 수입금을 부당하게 착복하는 등 직책을 이용하여 공금을 착복, 장기유용, 횡령 또는 배임한 경우 </li>
      <li>8. {workerGa} 제품 또는 원료 등을 몰래 훔치거나 불법 반출한 경우</li>
      <li>9. 인사ㆍ경리ㆍ회계담당 직원이 근무상황 실적을 조작하거나 허위 서류 등을 작성하여 사업에 손해를 끼친 경우</li>
      <li>10. {workerGa} 사업장의 기물을 고의로 파손하여 생산에 막대한 지장을 가져온 경우</li>
      <li>11. 그 밖에 사회통념상 {workerGa} 고의로 사업에 막대한 지장을 가져오거나 재산상 손해를 끼쳤다고 인정되는 경우</li>
    </> 
  )

  return (
    <>
      {numOfWorkers >=5 
        ? 
          <div className="jau__contents">
            <span style={{fontSize : '1rem'}}><strong>제9조 근로관계의 종료(해고, 해지, 해고예고)</strong></span>
            <ul>
            <li>{`${resignPeriodText}`}</li>
            <li>{`${unauthorizedResignationText}`}</li>    
            <li>{`③ ${companyUn} 근로계약 기간의 유무와 관계 없이 ${worker}에게 근로기준법 제23조(해고 등의 제한), 제24조(경영상 이유에 의한 해고의 제한) 
              규정에 따른 정당한 해고 사유가 있는 경우에는 ${workerUl} 해고할 수 있다. 단, ${workerUl} 해고할 때에는 동법 제27조(해고 사유 등의 서면통지)에 따라 
              해고통보서에 해고 사유와 시기를 서면으로 명시하여 ${worker}에게 통보해야 한다.`}
            </li>
            <li>{`④ 제3항에 따른 ${worker}의 해고사유는 ${companyName}의 취업규칙 및 관련 내규가 있으면 그에 따라야 하고, 없다면 개별 근로계약(서)에 그 근거가 있어야 한다. 
              다만, 취업규칙 등에 근거한 해고사유가 사회통념에 반한 때에는 해당 조항은 무효로 한다. 
              반대로 ${companyName}의 취업규칙 또는 내규에 해고사유가 없더라도 ${worker} 본조 제5항 제3호 내지 제11호에 해당하는 경우에는 ${worker}에게 정당한 해고사유가 있는 것으로 본다. `}
            </li> 
            <li>{`⑤ ${companyGa} 제3항과 제4항에 따른 정당한 해고를 할 경우라도 근로기준법 제26조(해고의 예고) 조항에 따라 
              다음의 경우에만 해고예고의 의무를 면한다.`}
            </li>
            {noFireNoticeText}
            </ul>
          </div>
        : 
        <div className="jau__contents">
          <span style={{fontSize : '1rem'}}><strong>제9조 근로관계의 종료(해고조항 제외 및 해고예고)</strong></span>
          <ul>          
            <li>{`${resignPeriodText}`}</li>
            <li>{`${unauthorizedResignationText}`}</li>   
            <li>{`③ ${companyUn} 5인미만 사업장으로 근로기준법 제23조(해고 등의 제한) 동법 제24조(경영상 이유에 의한 해고 의 제한) 
              동법 제27조(해고 사유 등의 서면통지) 조항이 적용되지 않는 사업장으로 해당 조항의 준수 의무는 없으나, 동법 제26조 해고예고 조항은 적용되므로 
              ${workerUl} 해고할 때에는 해고예고를 하거나 30일분의 해고예고 수당을 지급해야 한다. 다만 다음의 경우에는 ${companyName}에게 해고예고의 의무가 없다.`}</li>       
              {noFireNoticeText}
          </ul>
        </div>
      } 
    
    </>
  )
}



