import moment from 'moment';
import React from 'react';
import { josaGaType, josaUlType, josaUnType, josaWaType } from '../../../../../app/common/util/util';

export default function ClientReqDLDCHoliUp15Worker5Up({contractOldWorker, selectedClientReq4In, companyName, worker, isEtcWorker, isExceptTimeLawWorker}) {
  const weekLBHoliday = selectedClientReq4In?.etc?.paidOffdays ? selectedClientReq4In?.etc?.paidOffdays : selectedClientReq4In?.paidOffdays;
  const alDate = moment(new Date(selectedClientReq4In?.finBaseDate))
  const finBaseDateMonth = moment(moment().set({ 'month': alDate.get('month') })).format('MM');
  const finBaseDateDate = moment(moment().set({ 'date': alDate.get('date') })).format('DD');

  const accountAL = selectedClientReq4In?.annualLeaves;

  const companyUn = josaUnType(companyName);
  const companyGa = josaGaType(companyName);
  const companyWa = josaWaType(companyGa);
  
  const workerGa = josaGaType(worker);
  const workerUl = josaUlType(worker);
  const workerUn = josaUnType(worker);

  const gongHoliText = (`${companyUn} 근로기준법 제55조 제2항에 따라 ${worker}에게 관공서의 공휴일에 관한 규정(제2조 제1호(일요일)은 제외)에 따른 공휴일과 대체공휴일을 유급 휴일로 부여하되, 근로자대표와의 서면합의가 있는 경우 휴일을 특정한 근로일로 대체할 수 있다.`)
  const mayDateText = (`${companyUn} ${worker}에게 5월1일 근로자의 날을 근로자의날에 관한 법률에 따라 유급 휴일로 부여한다.`)

  const contractOldWorkerText = (`${companyUn} ${workerGa}가 정년퇴직 후 촉탁 계약직으로 입사했기 때문에 고용상 연령차별금지 및 고령자고용촉진에 관한 법률 제21조에 의거 정년이후 연차계산을 위한 계속 근로 연수는 새로이 계산한다.`)

  const weekHoliText = isEtcWorker 
    ? 
      (`${companyUn} 근로기준법 제55조 제1항에 따라 ${worker}에게 유급(1주 소정근로일을 개근하지 아니한 경우는 무급) 주휴일을 
      ${weekLBHoliday}에 부여하되, 
      업무상 필요한 경우 특정 주휴일 48시간 전에 ${worker}에게 근무를 명하고, 해당 주휴일로부터 7일 이내에 대체 주휴일을 부여하는 것에 대해 동의한다.`)
    : 
      (`${companyUn} 근로기준법 제55조 제1항에 따라 ${worker}에게 유급(1주 소정근로일을 개근하지 아니한 경우는 무급) 주휴일을 
      매주 ${weekLBHoliday}요일에 부여하되, 
      업무상 필요한 경우 특정 주휴일 48시간 전에 ${worker}에게 근무를 명하고, 해당 주휴일로부터 7일 이내에 대체 주휴일을 부여하는 것에 대해 동의한다.`)
  const becomeNomalDayText = (`${companyGa} 제2항에 따른 주휴일 대체를 한 경우 해당 주휴일은 근무일이 되므로 해당일에 근무해도 해당 근무는 휴일근로에 해당되지 아니하나, 대체 휴일이 해당 근무일로부터 7일 이내에 부여되지 아니하는 경우에 해당 근무일은 휴일근무가 되어 ${companyUn} ${worker}에게 가산임금 내지 보상휴가를 부여해야 한다.`)

  const annualLeavesIncludeMonthWageTime = selectedClientReq4In?.annualLeavesIncludeMonthWageTime;

  return (
    <>
      <div className="jau__contents">        
        <span style={{fontSize : '1rem'}}><strong>제6조 휴일 및 휴가</strong></span>
        <ul>
          <li>① {mayDateText}</li>
          {isExceptTimeLawWorker 
            ? 
              <li>② {companyUn} 근로기준법 제63조에 따라 {worker}에게 근로기준법 제55조의 휴일(주휴일 포함)조항을 적용하지 아니한다.</li>
            : 
              <>
                <li>② {weekHoliText}</li>
                <b>
                  <li style={{color : 'blue'}}>
                    위 ②항 동의자 : {worker} (인 또는 sign)
                  </li>
                </b>
                <li>③ {becomeNomalDayText}</li>
              </>
          }

          {(accountAL === '회계년도기준' || accountAL === '특정일(회계년도 등)기준')
          ? 
            <>
              <li>
                ④ {companyUn} {worker}에게 근로기준법 제60조에 따른 연차유급휴가를 부여하되 
                효율적인 연차관리를 위해 회계년도(매년 {finBaseDateMonth}월 {finBaseDateDate}일, 매월 {finBaseDateDate}일) 
                기준으로 년 단위 연차와 월 단위 연차를 부여(회사는 회계년도 기준으로 연차를 부여하므로 월 단위 연차의 소멸시기는 근기법상 입사일부터 1년이 되는 날이 아닌 2년차 회계년도 기말 말일에 소멸함.)하며, 동법 제61조에 따라 적법하게 연차유급휴가를 사용촉진한 경우 
                연차유급휴가미사용 수당 지급의무를 면하며, 근로자대표와의 서면합의에 의거 연차유급휴가일을 갈음하여 
                특정근로일에 {workerUl} 휴무시킬 수 있다.
              </li>
              <li>
                ⑤ {workerUn} {companyGa} 전항에 따라 부여한 회계년도 단위로 부여 연차가 {worker}의 입사일 기준으로 산정하는 법정연차보다 적게 부여된 경우 {companyUn} {worker}에게
                법정연차에 따른 추가 연차를 부여해야 하며, {companyGa} {worker}에게 회계년도 단위로 부여한 연차가 {worker}의 입사일 기준으로 부여하는
                법정 연차보다 많이 부여된 경우 {workerUn} 이를 마지막달 임금 또는 퇴직금 지급시 정산 공제하는데 동의한다.
              </li>
              <b><li style={{color : 'blue'}}>위 ⑤항 동의자 : {worker} (인 또는 sign)</li></b>
              {isExceptTimeLawWorker 
                ? 
                  annualLeavesIncludeMonthWageTime 
                    ? 
                      <>
                          <li>⑥ {companyWa} {workerUn} {companyName} 업무상 필요에 따라 년간 연차휴가 15일분을 임금으로 매월 분할하여 받는 약정에 따라 유급연차휴가 수당이 매월 지급되지만, 
                          {workerUn} 발생한 연차휴가를 사용할 수 있고, 
                          {workerGa} 연차휴가를 사용하면 {companyUn} 사용한 일수에 해당하는 유급연차수당을 공제하고 임금을 지급한다.</li>
                        <b><li style={{color : 'blue'}}>위 ⑥항 동의자 : {worker} (인 또는 sign)</li></b>
                      </>
                    : 
                      null 
                : <li>⑥ {gongHoliText}</li>
              }
              {contractOldWorker 
                ? 
                  <>
                    <li>⑦ {contractOldWorkerText}</li>  
                  </>
                : 
                  null
              }
            </>
          : 
            <>
              <li>
                ④ {companyUn} {worker}에게 근로기준법 제60조에 따른 {worker}의 입사일 단위로 계산되는 연차유급휴가를 부여하고, 
                {companyGa} {worker}에게 동법 제61조에 따라 적법하게 연차유급휴가 사용촉진을 한 경우 연차유급휴가미사용 수당 지급의무를 면하며, 
                {companyUn} 근로자대표와의 서면합의에 의거 {worker}의 연차유급휴가일을 갈음하여 특정 근로일에 {workerUl} 휴무시킬 수 있다.
              </li>
              {isExceptTimeLawWorker 
                ? 
                  annualLeavesIncludeMonthWageTime 
                    ? 
                      <>
                        <li>⑤ {companyWa} {workerUn} {companyName}업무상 필요에 따라 년간 연차휴가 15일분을 임금으로 매월 분할하여 받는 약정에 따라 유급연차휴가 수당이 매월 지급되지만, 
                          {workerUn} 발생한 연차휴가를 사용할 수 있고, 
                          {workerGa} 연차휴가를 사용하면 {companyUn} 사용한 일수에 해당하는 유급연차수당을 공제하고 임금을 지급한다.</li>
                        <b><li style={{color : 'blue'}}>위 ⑤항 동의자 : {worker} (인 또는 sign)</li></b>
                      </>
                    : 
                      null 
                : 
                  <li>⑤ {gongHoliText}</li>
              }
              {contractOldWorker 
                ? 
                  <li>⑥ {contractOldWorkerText}</li> 
                : 
                  null
              }                    
            </>
          }
        </ul>
      </div>
    </>
  )
}
