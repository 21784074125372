import React, { useMemo, useState } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';
import { db } from '../../../app/config/firebase';
import { doc, writeBatch } from 'firebase/firestore';
import * as XLSX from 'xlsx';

import { format } from 'date-fns';
import SortableTable from '../../../app/common/form/SortableTable';
import { safeStringCompare } from '../../../app/common/util/util';
import { decrypto } from '../../../app/firestore/firestoreService';

export default function InsureManageListDayWorkerEntered({
	searchResults,
	companyName,
	authLevel,
	insureManageType,
	searchTerm,
}) {
	const [reportedRows, setReportedRows] = useState({});
	const [selectedRows, setSelectedRows] = useState([]);
	const [showReported, setShowReported] = useState(false);

	async function handleReportSelected() {
		const updatePayload = {
			insureInResponse: true,
			insureInResponseDate: new Date(),
		};

		const batch = writeBatch(db);

		selectedRows.forEach((row) => {
			const workerRef = doc(db, 'workers', row.id);
			batch.update(workerRef, updatePayload);
			setReportedRows((prev) => ({
				...prev,
				[row.id]: true,
			}));
		});

		try {
			await batch.commit();
			alert('신고가 완료되었습니다.');
		} catch (error) {
			console.error('Error updating documents:', error);
		}
	}

	function pensionInF(pensionInV) {
		if (!pensionInV) return 1;
		return +pensionInV.match(/\d+/);
	}

	function healthInF(healthInV) {
		if (!healthInV) return '07';
		return healthInV.match(/\d+/);
	}

	function exportSelectedToExcel() {
		const headers1 = [
			'가입자정보',
			'',
			'',
			'',
			'',
			'',
			'국민연금(소득월액상이사유는 국민연금 소속 직원이 접수하는 경우에만 입력합니다.)',
			'',
			'',
			'',
			'',
			'',
			'',
			'건강보험',
			'',
			'',
			'',
			'',
			'',
			'',
			'고용보험',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'산재보험',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'비고',
			'',
			'',
		];

		const headers2 = [
			'*주민등록번호',
			'*성명',
			'*대표자여부',
			'영문성명(외국인)',
			'국적',
			'체류자격',
			'*소득월액',
			'*자격취득일',
			'*취득월납부여부',
			'*자격취득부호',
			'특수직종부호',
			'소득월액상이사유(1.국외근로수당 , 2.사후정산)',
			'직역연금구분(1.직역연금가입자, 2.직역연금수급권자, 0.없음)',
			'*피부양자신청',
			'*보수월액',
			'*자격취득일',
			'*자격취득부호',
			'보험료/감면부호',
			'공무원/교직원(회계명)',
			'공무원/교직원(직종명)',
			'*월평균보수',
			'*자격취득일',
			'*직종부호',
			'*주소정근로시간',
			'보험료부과구분(부호)',
			'보험료부과구분(사유)',
			'*계약직여부',
			'계약종료년월',
			'*월평균보수',
			'*자격취득일',
			'직종부호',
			'주소정근로시간',
			'보험료부과구분(부호)',
			'보험료부과구분(사유)',
			'계약직여부',
			'계약종료년월',
			'오류메세지',
			'경고메세지',
		];

		const data = [headers1, headers2];

		selectedRows
			.filter((row) => {
				const noPensionPay = row?.dayWorkerCalPension > 0 ? false : true;
				const noHealthCarePay = row?.dayWorkerCalHealthCare > 0 ? false : true;
				return !(noPensionPay && noHealthCarePay); // 둘 다 대상 아님인 경우 제외
			})
			.forEach((row) => {
				const noPensionPay = row?.dayWorkerCalPension > 0 ? false : true;
				const noHealthCarePay = row?.dayWorkerCalHealthCare > 0 ? false : true;

				const dataRow = [
					row.workerSocialNumberFront,
					row.workerName,
					'N',
					row.englisName,
					row.foreigner,
					row.visa,
					noPensionPay ? '' : row.taxWageSum,
					noPensionPay ? '' : row.enteredDate,
					noPensionPay ? '' : 1,
					noPensionPay ? '' : pensionInF(row.pensionIn),
					noPensionPay ? '' : row.pensionIn4PublicJob,
					'',
					'',
					noHealthCarePay ? '' : 'N',
					noHealthCarePay ? '' : row.taxWageSum,
					noHealthCarePay ? '' : row.enteredDate,
					noHealthCarePay ? '' : healthInF(row.healthIn),
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
				];

				data.push(dataRow);
			});

		const worksheet = XLSX.utils.aoa_to_sheet(data);

		const merges = [
			{ s: { c: 0, r: 0 }, e: { c: 5, r: 0 } },
			{ s: { c: 6, r: 0 }, e: { c: 12, r: 0 } },
			{ s: { c: 13, r: 0 }, e: { c: 19, r: 0 } },
			{ s: { c: 20, r: 0 }, e: { c: 27, r: 0 } },
			{ s: { c: 28, r: 0 }, e: { c: 35, r: 0 } },
			{ s: { c: 36, r: 0 }, e: { c: 37, r: 0 } },
		];

		worksheet['!merges'] = merges;

		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, '입사');
		XLSX.writeFile(workbook, `${companyName}_입사처리.xlsx`);
	}

	const formattedData = searchResults
		.map((result) => {
			const workerSocialNumberFront = result?.workerSocialNumberFront
				? decrypto(
						result.workerSocialNumberFront,
						String(process.env.CRYPTO_KEY)
				  )
				: '';
			const workerSocialNumberBack = result?.workerSocialNumberBack
				? decrypto(
						result.workerSocialNumberBack,
						String(process.env.CRYPTO_KEY)
				  )
				: '';

			return {
				id: result.workerId, // Ensure ID is properly assigned here
				agentWorkerName: result?.agentWorkerName,
				segment: '입사',
				companyName: companyName,
				dayWorkerCalPension: result?.dayWorkerCalPension,
				dayWorkerCalHealthCare: result?.dayWorkerCalHealthCare,
				companyBizNumber: result?.companyBizNumber,
				workerName: result?.worker,
				workerSocialNumberFront:
					workerSocialNumberFront + workerSocialNumberBack,

				registerDate: result?.isInsureInRequestDate
					? format(new Date(result?.isInsureInRequestDate), 'yyyy-MM-dd')
					: null,
				responseDate: result?.insureInResponseDate
					? format(new Date(result?.insureInResponseDate), 'yyyy-MM-dd')
					: null,
				enteredDate: result?.enteredDate,
				numOfWorkers:
					typeof result?.numOfWorkers === 'string'
						? result?.numOfWorkers
						: result?.numOfWorkers?.toString(),
				taxWageSum: result?.dayWorkerTaxWageSum,
				workField: result?.workField,

				netAge: result?.netAge,

				foreigner: result?.nationality,
				englisName: result?.englishName,
				visa: result?.visaType,

				pensionIn: result?.pensionIn,
				pensionIn4PublicJob: result?.pensionIn4PublicJob,
				pension: result.dayWorkerCalPension > 0 ? '대상' : '대상아님',
				pensionPayType: result?.dayWorkerCalPension > 0 ? '대상' : '대상아님',

				healthIn: result?.healthIn,
				healthCare: result?.dayWorkerCalHealthCare > 0 ? '대상' : '대상아님',
				healthCarePayType: result?.healthCarePayType,

				insureInResponse: result?.insureInResponse,
				insureInResponseDate: result?.insureInResponseDate,
			};
		})
		.filter((row) => {
			const isReported = row.insureInResponse || reportedRows[row.id];
			const matchesSearchTerm =
				row.workerName.includes(searchTerm) ||
				row.companyName.includes(searchTerm);

			return showReported
				? isReported && matchesSearchTerm
				: !isReported && matchesSearchTerm;
		});

	const headers = useMemo(() => {
		const checkboxHeader = (
			<Checkbox
				checked={
					selectedRows.length === formattedData.length &&
					selectedRows.length > 0
				}
				onChange={(e, { checked }) => {
					if (checked) {
						setSelectedRows(formattedData);
					} else {
						setSelectedRows([]);
					}
				}}
			/>
		);

		if (authLevel >= 100) {
			return showReported
				? [
						{ key: 'checkbox', label: checkboxHeader, custom: true },
						{ key: 'registerDate', label: '처리요청일' },
						{ key: 'responseDate', label: '처리일' },
						{ key: 'agentWorkerName', label: '담당자명' },
						{ key: 'companyName', label: '회사명' },
						{ key: 'companyBizNumber', label: '사업자번호' },
						{ key: 'workerName', label: '신고한 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
				  ]
				: [
						{ key: 'checkbox', label: checkboxHeader, custom: true },
						{ key: 'registerDate', label: '처리요청일' },
						{ key: 'agentWorkerName', label: '담당자명' },
						{ key: 'companyName', label: '회사명' },
						{ key: 'companyBizNumber', label: '사업자번호' },
						{ key: 'workerName', label: '신고할 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
				  ];
		} else if (insureManageType === '4대보험 자체관리') {
			return showReported
				? [
						{ key: 'checkbox', label: checkboxHeader, custom: true },
						{ key: 'segment', label: '신고 구분' },
						{ key: 'registerDate', label: '정보 등록일' },
						{ key: 'responseDate', label: '신고일' },
						{ key: 'workerName', label: '신고한 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
				  ]
				: [
						{ key: 'checkbox', label: checkboxHeader, custom: true },
						{ key: 'segment', label: '신고 구분' },
						{ key: 'registerDate', label: '정보등록일' },
						{ key: 'workerName', label: '신고할 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'selfNoJobPay', label: '임의가입여부' },
						{ key: 'healthCarePeople', label: '부양가족' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
				  ];
		} else if (
			insureManageType === '4대보험 노무사 위탁관리' ||
			insureManageType === '4대보험 최상인업노무법인 위탁관리'
		) {
			return showReported
				? [
						// { key: 'checkbox', label: checkboxHeader, custom: true },
						{ key: 'segment', label: '신고 구분' },
						{ key: 'registerDate', label: '신고 요청일' },
						{ key: 'responseDate', label: '신고일 완료일' },
						{ key: 'workerName', label: '신고한 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
				  ]
				: [
						// { key: 'checkbox', label: checkboxHeader, custom: true },
						{ key: 'segment', label: '신고 구분' },
						{ key: 'registerDate', label: '신고 요청일' },
						{ key: 'workerName', label: '신고할 직원명' },
						{ key: 'enteredDate', label: '입사일' },
						{ key: 'netAge', label: '연령(만)' },
						{ key: 'foreigner', label: '국적' },
						{ key: 'visa', label: '비자' },
						{ key: 'pension', label: '국민연금' },
						{ key: 'healthCare', label: '건강보험' },
				  ];
		}
	}, [
		showReported,
		insureManageType,
		authLevel,
		selectedRows.length,
		formattedData,
	]);

	const sortFunctions = {
		registerDate: (a, b) =>
			new Date(b.registerDate).getTime() - new Date(a.registerDate).getTime(),
		responseDate: (a, b) =>
			new Date(b.responseDate).getTime() - new Date(a.responseDate).getTime(),
		enteredDate: (a, b) =>
			new Date(b.enteredDate).getTime() - new Date(a.enteredDate).getTime(),

		agentWorkerName: (a, b) =>
			safeStringCompare(a.agentWorkerName, b.agentWorkerName),
		segment: (a, b) => safeStringCompare(a.segment, b.segment),
		companyName: (a, b) => safeStringCompare(a.companyName, b.companyName),
		companyBizNumber: (a, b) =>
			safeStringCompare(a.companyBizNumber, b.companyBizNumber),
		workerName: (a, b) => safeStringCompare(a.workerName, b.workerName),
		netAge: (a, b) => parseInt(a.netAge) - parseInt(b.netAge),
		taxWageSum: (a, b) => parseInt(a.taxWageSum) - parseInt(b.taxWageSum),

		pensionOutType: (a, b) =>
			parseInt(a.pensionOutType) - parseInt(b.pensionOutType),
		healthOutType: (a, b) =>
			parseInt(a.healthOutType) - parseInt(b.healthOutType),

		workField: (a, b) => safeStringCompare(a.workField, b.workField),
		foreigner: (a, b) => safeStringCompare(a.foreigner, b.foreigner),
		englishName: (a, b) => safeStringCompare(a.englishName, b.englishName),
		visa: (a, b) => safeStringCompare(a.visa, b.visa),

		pension: (a, b) => safeStringCompare(a.pension, b.pension),
		healthCare: (a, b) => safeStringCompare(a.healthCare, b.healthCare),
		action: (a, b) => {
			const isReportedA = a.insureInResponse || reportedRows[a.id] ? 1 : 0;
			const isReportedB = b.insureInResponse || reportedRows[b.id] ? 1 : 0;
			return isReportedA - isReportedB;
		},
	};

	const title = '입사한 직원 목록';

	const customRenderers = {
		checkbox: (row) => (
			<Checkbox
				checked={selectedRows.some((selected) => selected.id === row.id)}
				onChange={(e, { checked }) => {
					if (checked) {
						setSelectedRows((prev) => [...prev, row]);
					} else {
						setSelectedRows((prev) => prev.filter((r) => r.id !== row.id));
					}
				}}
			/>
		),
	};

	const noExcelButton = false;

	const buttonView =
		authLevel >= 100 || insureManageType === '4대보험 자체관리' ? true : false;

	return (
		<>
			<h3>입사 신고</h3>

			<Button
				color={showReported ? 'green' : 'blue'}
				onClick={() => setShowReported(!showReported)}>
				{showReported ? '퇴사 신고 전 정보 보기' : '퇴사 신고 완료 정보 보기'}
			</Button>
			{buttonView && (
				<>
					<Button color='blue' onClick={exportSelectedToExcel}>
						엑셀다운
					</Button>
					<Button color='red' onClick={handleReportSelected}>
						신고완료
					</Button>
				</>
			)}
			<SortableTable
				headers={headers}
				data={formattedData}
				sortFunctions={sortFunctions}
				customRenderers={customRenderers}
				title={title}
				noExcelButton={noExcelButton}
			/>
		</>
	);
}
