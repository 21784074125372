import React, { useEffect, useState, useCallback } from 'react';
import { Button, Confirm } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import SortableTable from '../../../app/common/form/SortableTable';
import {
	deleteViewUserInFirestore,
	againViewUserInFirestore,
} from '../../../app/firestore/firestoreService4In';
import { safeStringCompare } from '../../../app/common/util/util';

export default function CompanyList({
	companies,
	searchResults,
	setSearchResults,
	searchTerm,
}) {
	const currentUserProfile = useSelector(
		(state) => state.profile.currentUserProfile
	);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [selectedId, setSelectedId] = useState(null);
	const [showDeleted, setShowDeleted] = useState(false);
	const history = useHistory();

	useEffect(() => {
		const searchedCompanies = companies.filter((company) => {
			const joinDate = format(new Date(company?.createdAt), 'yyyy-MM-dd');
			const withoutCeo = company?.ceoName ? true : false;
			return (
				(company?.companyName?.includes(searchTerm) ||
					company?.salesStaff?.includes(searchTerm) ||
					company?.email?.includes(searchTerm) ||
					joinDate?.includes(searchTerm) ||
					currentUserProfile?.companyName?.includes(searchTerm) ||
					currentUserProfile?.agentName?.includes(searchTerm)) &&
				company?.isWorker !== true &&
				withoutCeo
			);
		});
		setSearchResults(searchedCompanies);
	}, [searchTerm, setSearchResults, companies, currentUserProfile]);

	const handleDeleteToggle = useCallback(async () => {
		setLoadingDelete(true);
		try {
			await deleteViewUserInFirestore(selectedId);
			setLoadingDelete(false);
			setConfirmOpen(false);
			// 삭제 후 검색 결과에서 해당 항목 제거
			setSearchResults((prevResults) =>
				prevResults.filter((company) => company.id !== selectedId)
			);
			setSelectedId(null);
		} catch (error) {
			console.error('Error deleting document:', error);
			setLoadingDelete(false);
			setSelectedId(null);
		}
	}, [selectedId, setSearchResults]);

	const handleRestoreAndEdit = useCallback(
		async (id) => {
			setLoadingDelete(true);
			try {
				await againViewUserInFirestore(id);
				console.log('Document successfully restored!');
				setLoadingDelete(false);
				history.push(`/manageCompany/${id}`);
			} catch (error) {
				console.error('Error restoring document:', error);
				setLoadingDelete(false);
			}
		},
		[history]
	);

	const openConfirm = (id) => {
		setSelectedId(id);
		setConfirmOpen(true);
	};

	useEffect(() => {
		const handleKeyDown = (e) => {
			if (e.key === 'Enter' && confirmOpen) {
				handleDeleteToggle();
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [confirmOpen, handleDeleteToggle]);

	const liveHeaders = [
		{ key: 'createdAt', label: '회원가입일' },
		{ key: 'serviceStartDate', label: '서비스시작일' },
		{ key: 'serviceEndDate', label: '서비스종료일' },
		{ key: 'companyName', label: '회사명' },
		{ key: 'numOfWorkers', label: '직원수' },
		{ key: 'workerInputCompanyName', label: '직원의 회사명' },
		{ key: 'workerName', label: '직원명' },
		{ key: 'email', label: '가입 이메일' },
		{ key: 'salesStaff', label: '가입경로' },
		{ key: 'authLevel', label: '등급' },
		{ key: 'action', label: '수정' },
		{ key: 'delete', label: '삭제' },
	];

	const deletedHeaders = [
		{ key: 'createdAt', label: '회원가입일' },
		{ key: 'serviceStartDate', label: '서비스시작일' },
		{ key: 'serviceEndDate', label: '서비스종료일' },
		{ key: 'companyName', label: '회사명' },
		{ key: 'numOfWorkers', label: '직원수' },
		{ key: 'workerInputCompanyName', label: '직원의 회사명' },
		{ key: 'workerName', label: '직원명' },
		{ key: 'email', label: '가입 이메일' },
		{ key: 'salesStaff', label: '가입경로' },
		{ key: 'authLevel', label: '등급' },
		{ key: 'action', label: '수정' },
	];

	const sortFunctions = {
		createdAt: (a, b) => safeStringCompare(a.createdAt, b.createdAt),
		serviceStartDate: (a, b) =>
			safeStringCompare(a.serviceStartDate, b.serviceStartDate),
		serviceEndDate: (a, b) =>
			safeStringCompare(a.serviceEndDate, b.serviceEndDate),
		companyName: (a, b) => safeStringCompare(a.companyName, b.companyName),
		numOfWorkers: (a, b) => safeStringCompare(a.numOfWorkers, b.numOfWorkers),
		workerInputCompanyName: (a, b) =>
			safeStringCompare(a.workerInputCompanyName, b.workerInputCompanyName),
		workerName: (a, b) => safeStringCompare(a.workerName, b.workerName),
		email: (a, b) => safeStringCompare(a.email, b.email),
		salesStaff: (a, b) => safeStringCompare(a.salesStaff, b.salesStaff),
		authLevel: (a, b) => safeStringCompare(a.authLevel, b.authLevel),
	};

	const deletedCompanyData = (searchResults) => {
		return searchResults.filter((row) => row?.deletedView === true);
	};

	const liveCompanyData = (searchResults) => {
		return searchResults.filter((row) => row?.deletedView !== true);
	};

	const liveformattedData = liveCompanyData(searchResults).map((result) => ({
		createdAt: result?.createdAt
			? format(new Date(result.createdAt), 'yyyy-MM-dd')
			: null,
		serviceStartDate: result?.serviceStartDate
			? format(new Date(result.serviceStartDate), 'yyyy-MM-dd')
			: null,
		serviceEndDate: result?.serviceEndDate
			? format(new Date(result.serviceEndDate), 'yyyy-MM-dd')
			: null,
		companyName: result?.companyName,
		numOfWorkers:
			typeof result?.numOfWorkers === 'string'
				? result?.numOfWorkers
				: result?.numOfWorkers?.toString(),
		workerInputCompanyName: result?.workerInputCompanyName,
		workerName: result?.workerName,
		email: result?.email,
		salesStaff: result?.salesStaff,
		authLevel:
			typeof result?.authLevel === 'string'
				? result?.authLevel
				: result?.authLevel?.toString(),
		id: result.id,
		deletedView: result?.deletedView,
	}));

	const deletedformattedData = deletedCompanyData(searchResults).map(
		(result) => ({
			createdAt: result?.createdAt
				? format(new Date(result.createdAt), 'yyyy-MM-dd')
				: null,
			serviceStartDate: result?.serviceStartDate
				? format(new Date(result.serviceStartDate), 'yyyy-MM-dd')
				: null,
			serviceEndDate: result?.serviceEndDate
				? format(new Date(result.serviceEndDate), 'yyyy-MM-dd')
				: null,
			companyName: result?.companyName,
			numOfWorkers:
				typeof result?.numOfWorkers === 'string'
					? result?.numOfWorkers
					: result?.numOfWorkers?.toString(),
			workerInputCompanyName: result?.workerInputCompanyName,
			workerName: result?.workerName,
			email: result?.email,
			salesStaff: result?.salesStaff,
			authLevel:
				typeof result?.authLevel === 'string'
					? result?.authLevel
					: result?.authLevel?.toString(),
			id: result.id,
			deletedView: result?.deletedView,
		})
	);

	const title = '회사 목록';

	const customRenderers = {
		action: (row) => (
			<Button
				color='orange'
				floated='right'
				onClick={() => {
					if (row.deletedView) {
						handleRestoreAndEdit(row.id);
					} else {
						history.push(`/manageCompany/${row.id}`);
					}
				}}>
				수정
			</Button>
		),
		delete: (row) => (
			<Button
				color='red'
				floated='right'
				loading={loadingDelete && selectedId === row.id}
				onClick={() => openConfirm(row.id)}>
				삭제
			</Button>
		),
	};

	return (
		<>
			<Button color='purple' onClick={() => setShowDeleted(!showDeleted)}>
				{showDeleted ? '사용중인 회사 보기' : '중단한 회사 보기'}
			</Button>
			<br />
			<br />
			{showDeleted ? (
				<>
					{deletedformattedData.length > 0 && (
						<SortableTable
							headers={deletedHeaders}
							data={deletedformattedData}
							sortFunctions={sortFunctions}
							customRenderers={customRenderers}
							title='삭제된 회사 목록'
						/>
					)}
				</>
			) : (
				<>
					{liveformattedData.length > 0 && (
						<SortableTable
							headers={liveHeaders}
							data={liveformattedData}
							sortFunctions={sortFunctions}
							customRenderers={customRenderers}
							title={title}
						/>
					)}
				</>
			)}
			<Confirm
				open={confirmOpen}
				onCancel={() => setConfirmOpen(false)}
				onConfirm={handleDeleteToggle}
				content='정말로 이 항목을 삭제하시겠습니까?'
			/>
		</>
	);
}
