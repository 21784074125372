// import { Field, FieldArray } from 'formik';
import React from 'react'
import { Tab } from 'semantic-ui-react';
import MyDateInput from '../../../../../app/common/form/MyDateInput';
import MyTextInput from '../../../../../app/common/form/MyTextInput';
import MyTextArea from '../../../../../app/common/form/MyTextArea';
import CrwfWorkerBasicInfoSupportFund from './CrwfWorkerBasicInfoSupportFund';
import CrwfWorkerBasicInfoChildLeaves from './CrwfWorkerBasicInfoChildLeaves';
import { healthIn4DiscountType, healthIn4Type, pensionIn4PublicJobType, pensionIn4Type, workerSupportFundYesOrNot, workField } from '../../../../../app/api/dropdownOption';
import MySelectInput from '../../../../../app/common/form/MySelectInput';
import WorkerFormStep1 from './WorkerFormStep1';
import WorkerFormCompanyInfo from './WorkerFormCompanyInfo';
import WorkerFormStep11 from './WorkerFormStep11';
import WorkerFormStep1ContractTpye from './WorkerFormStep1ContractType';
import WorkerFormStep1Hr from './WorkerFormStep1Hr';

export default function CrwfWorkerBasicInfo({
  isCoWorkSemusa,
  setPerEvalOrNot,
  perEvalOrNot,
  setWorkerYouth,
  workerYouth,
  values,
  personalEvaluation,
  currentUserProfile,
  setAltWorker,
  altWorker,
  authLevel,
  setEnterDate,
  prevWorker,
  over52Permission,
  selectedClientReq4In,
  setEditingLeave,
  setFormValues,
}) {

  const workerYouthYes =  
  (
    workerYouth ===  '1. 연속하여 6개월 이상 실업상태인 청년' ||
    workerYouth ===  '2. 고졸이하 학력인 청년' ||
    workerYouth ===  '3. 고용촉진장려금 지급 대상이 되는 청년' ||
    workerYouth ===  '4. 국민취업지원제도에 참여하고 최초로 취업한 청년(국취 참여이후 최초 취업)' ||
    workerYouth ===  '5. 청년도전지원사업수료 청년' ||
    workerYouth ===  '6. 가정과 학교의 보호를 받지 못하여 안정적 자립을 위한 정부 지원 필요성이 인정되는 청년' ||
    workerYouth ===  '7. 북한이탈청년(북한이탈주민등록확인서 발급)' ||
    workerYouth ===  '8. 자영업 폐업 이후, 최초로 취업한 청년(생애최초가 아닌 자영업 폐업 이후 최초)' ||
    workerYouth ===  '9. 최종학교 졸업일 이후 채용일까지 고용보험 총 가입기간 12개월 미만인 청년(단, 대학(원) 졸업예정일 및 졸업일로부터 3개월이 지나지 않는 청년은 제외)' ||
    workerYouth ===  '10. 외국인의 경우 거주(F-2), 영주(F-5), 결혼이민자(F-6)비자 소지자 참여 가능' 
  ) ? true : false

  const MenuItemContent = () => (
    <>
      <span style={{ color: 'blue' }}>(1-1) 인사기초</span>
      <span style={{ color: 'red' }}>[필수]</span>
    </>
  );

  const MenuItemContent2 = () => (
    <>
      <span style={{ color: 'blue' }}>(1-2) 4대보험 취득</span>
      <span style={{ color: 'red' }}>[필수]</span>
    </>
  );

  const panes = []

  if (authLevel >= 100) {
    panes.push({
      menuItem: '회사정보',
      key: '회사정보',
      render: () => (
        <Tab.Pane>
          <WorkerFormCompanyInfo 
            currentUserProfile={currentUserProfile}
            MyTextInput={MyTextInput}
            MySelectInput={MySelectInput}
            over52Permission={over52Permission}
          />
        </Tab.Pane>
      ),
    });
  }

  panes.push(
    {
      menuItem: {
        content: <MenuItemContent />,
        key: '인사기초정보[필수]',
      },
      render: () => (
        <Tab.Pane>
          <WorkerFormStep1 
            isCoWorkSemusa={isCoWorkSemusa}
            values={values}
            MyTextInput = {MyTextInput}
            MyDateInput = {MyDateInput}
            workField = {workField}
            MyTextArea = {MyTextArea}
            authLevel={authLevel}
            setEnterDate={setEnterDate}
            prevWorker={prevWorker}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        content : <MenuItemContent2 />,
        key: '4대보험 취득정보[필수]',
      },
      render: () => (
        <Tab.Pane>
          <WorkerFormStep11  
            MyTextInput = {MyTextInput}
            MySelectInput = {MySelectInput}
            pensionIn4Type = {pensionIn4Type}
            healthIn4Type = {healthIn4Type}
            workerSupportFundYesOrNot = {workerSupportFundYesOrNot}
            MyTextArea = {MyTextArea}
            pensionIn4PublicJobType = {pensionIn4PublicJobType}
            healthIn4DiscountType = {healthIn4DiscountType}
            values = {values}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: '(1-3) 계약/정년촉탁/수습 설정',
      key: '계약직/수습직 설정',
      render: () => (
        <Tab.Pane>
          <WorkerFormStep1ContractTpye 
            MyDateInput = {MyDateInput}
            values={values}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: '(1-4) 고용지원금 정보',
      key: '고용지원금 정보',
      render: () => (
        <Tab.Pane>
          <CrwfWorkerBasicInfoSupportFund  //step 1-1
            setWorkerYouth={setWorkerYouth}
            workerYouthYes={workerYouthYes}
            values={values}
            setAltWorker={setAltWorker}
            altWorker={altWorker}
            authLevel={authLevel}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        content:'(1-5) 인사고과사용 설정',
        key: '인사고과사용 설정',
        title: '활성화를 위해 회사정보의 "인사고과" 메뉴를 활성화하세요.', // 마우스 오버시 표시될 텍스트
      },
      render: () => (
        <Tab.Pane>
          <WorkerFormStep1Hr
            MyTextInput = {MyTextInput}
            setPerEvalOrNot={setPerEvalOrNot}
            perEvalOrNot={perEvalOrNot}
            personalEvaluation={personalEvaluation}
            authLevel={authLevel}
            values={values}
            currentUserProfile={currentUserProfile}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: '(1-6) 산전후/육아휴직 등록',
      key: '산전후/육아휴직 등록',
      render: () => (
        <Tab.Pane>
          <CrwfWorkerBasicInfoChildLeaves //step 1-3
            values={values}
            MyDateInput = {MyDateInput}
            selectedClientReq4In={selectedClientReq4In}
            setEditingLeave={setEditingLeave}
            setFormValues={setFormValues}
          />
        </Tab.Pane>
      ),
    },
  )

  return (
    <>
      <Tab panes={panes} />
    </>
  )
}


