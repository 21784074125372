import React from 'react';
import {
	healthCare,
	numStringToNumber,
	oldCare,
} from '../../../../app/common/util/util';
import {
	sendFinalRetireHealthCareRecalFee,
	sendFinalRetireOldCareRecalFee,
} from '../../../companyManage/companyActions';
import { addRetiredInsureInfoToFirestore } from '../../../../app/firestore/firestoreService4In';
import { useSelector } from 'react-redux';

export default function HealthCareRecalFormItems({
	noHealthCare,
	selectedClientReq4In,
	values,
	MyDateInput,
	MyTextInput,
	MyNumberInput,
	thisYearTaxWagePaydocu,
	lastYearTaxWagePaydocu,
	calThisYearWorkedMonth,
	calLastYearWorkedMonth,
	thisYearHealthCarePaydocu,
	lastYearHealthCarePaydocu,
	thisYearOldCarePaydocu,
	lastYearOldCarePaydocu,
	numberToCommaString,
	thisYear,
	lastYear,
	lastYearHealthCareRecalPaidSum,
	lastYearOldCareRecalPaidSum,
	Button,
	isSubmitting,
	Link,
	dispatch,
	lastYearTaxWageUnder7Paydocu,
	lastYearTaxWageOver7Paydocu,
	currentUserProfile,
	isNot1day,
	firstMonthTaxWageSum,
}) {
	const { clientInfo } = useSelector((state) => state.companyReducer);

	const healthCareText = selectedClientReq4In?.healthCareText || '';
	const healthCarePayType = selectedClientReq4In?.healthCarePayType || '';

	function numTaxWageThisYearF(isNot1day, firstMonthTaxWageSum) {
		let numTaxWageThisYear = 0;
		if (isNot1day) {
			numTaxWageThisYear = values.taxWageThisYear
				? numStringToNumber(values.taxWageThisYear) - firstMonthTaxWageSum
				: 0;
		} else {
			numTaxWageThisYear = values.taxWageThisYear
				? numStringToNumber(values.taxWageThisYear)
				: 0;
		}
		return numTaxWageThisYear;
	}

	const numTaxWageThisYear = numTaxWageThisYearF(
		isNot1day,
		firstMonthTaxWageSum
	);

	const numWorkedMonthThisYear = values.workedMonthThisYear
		? numStringToNumber(values.workedMonthThisYear)
		: 0;
	const numPaidHealthFeeTotalThisYear = values.paidHealthFeeTotalThisYear
		? numStringToNumber(values.paidHealthFeeTotalThisYear)
		: 0;
	const numPaidOldFeeTotalThisYear = values.paidOldFeeTotalThisYear
		? numStringToNumber(values.paidOldFeeTotalThisYear)
		: 0;

	const numTaxWageLastYear = values.taxWageLastYear
		? numStringToNumber(values.taxWageLastYear)
		: 0;
	const numWorkedMonthLastYear = values.workedMonthLastYear
		? numStringToNumber(values.workedMonthLastYear)
		: 0;
	const numPaidHealthFeeTotalLastYear = values.paidHealthFeeTotalLastYear
		? numStringToNumber(values.paidHealthFeeTotalLastYear)
		: 0;
	const numPaidOldFeeTotalLastYear = values.paidOldFeeTotalLastYear
		? numStringToNumber(values.paidOldFeeTotalLastYear)
		: 0;

	const numLastYearHealthCareRecalPaid = values.lastYearHealthCareRecalPaid
		? numStringToNumber(values.lastYearHealthCareRecalPaid)
		: 0;
	const numLastYearOldCareRecalPaid = values.lastYearOldCareRecalPaid
		? numStringToNumber(values.lastYearOldCareRecalPaid)
		: 0;

	const averageMonthWageOfThisYear = numTaxWageThisYear
		? (numTaxWageThisYear + isNot1day) / numWorkedMonthThisYear
		: '';

	const thisYearOldMonthFee = oldCare(
		averageMonthWageOfThisYear,
		thisYear.toString()
	);
	const thisYearHealthMonthFee = healthCare(
		averageMonthWageOfThisYear,
		thisYear.toString()
	);

	// 정산월 구하기 : 입사월이 올해이고 1일입사자 아니면 재직월수에서 1개월 빼기
	const thisYearHealthFee =
		thisYearHealthMonthFee * (numWorkedMonthThisYear + isNot1day);
	const thisYearOldFee =
		thisYearOldMonthFee * (numWorkedMonthThisYear + isNot1day);

	const dutyHealthFeeThisYear =
		healthCareText ===
			'8일미만 , 소정근로 60시간 미만, 중도입사자는 건강보험료를 납부하지 않습니다.' ||
		healthCarePayType === '건강보험료 공제안함'
			? 0
			: thisYearHealthFee - numPaidHealthFeeTotalThisYear;

	const dutyOldFeeThisYear =
		healthCareText ===
			'8일미만 , 소정근로 60시간 미만, 중도입사자는 건강보험료를 납부하지 않습니다.' ||
		healthCarePayType === '건강보험료 공제안함'
			? 0
			: thisYearOldFee - numPaidOldFeeTotalThisYear;

	const taxWageLastYear = numTaxWageLastYear;
	const paidHealthFeeTotalLastYear = numPaidHealthFeeTotalLastYear;
	const paidOldFeeTotalLastYear = numPaidOldFeeTotalLastYear;

	const averageMonthWageOfLastYear = taxWageLastYear
		? taxWageLastYear / numWorkedMonthLastYear
		: '';

	const lastYearOldMonthFee = oldCare(
		averageMonthWageOfLastYear,
		lastYear.toString()
	);
	const lastYearHealthMonthFee = healthCare(
		averageMonthWageOfLastYear,
		lastYear.toString()
	);

	const lastYearHealthFee = lastYearHealthMonthFee * numWorkedMonthLastYear;
	const lastYearOldFee = lastYearOldMonthFee * numWorkedMonthLastYear;

	const dutyHealthFeeLastYear = lastYearHealthFee - paidHealthFeeTotalLastYear;
	const dutyOldFeeLastYear = lastYearOldFee - paidOldFeeTotalLastYear;

	const finalRetireHealthReCalFee =
		dutyHealthFeeThisYear +
		dutyHealthFeeLastYear -
		numLastYearHealthCareRecalPaid;
	const finalRetireOldReCalFee =
		dutyOldFeeThisYear + dutyOldFeeLastYear - numLastYearOldCareRecalPaid;

	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;
	const noPayReason = selectedClientReq4In?.eiOutType || '';
	const noPayDetailReason = selectedClientReq4In?.eiOutReason || '';
	const noPayDetailType = selectedClientReq4In?.eiOutDetailType || '';
	const pensionOutType = selectedClientReq4In?.pensionOutType || '';
	const healthOutType = selectedClientReq4In?.healthOutType || '';
	const noPayInsureYesType = selectedClientReq4In?.noJobPayYesType || '';

	const retireInfo = {
		registerDate: new Date(),
		isRetired: true,
		workerId: selectedClientReq4In?.id,
		workerName: values.workerName,
		companyName: companyName,
		retiredDate: values.retiredDate,
		lastYearTaxWagePaydocu: lastYearTaxWagePaydocu,
		thisYearTaxWagePaydocu: thisYearTaxWagePaydocu,
		noPayReason: noPayReason,
		noPayDetailType: noPayDetailType,
		noPayDetailReason: noPayDetailReason,
		noPayInsureYesType: noPayInsureYesType,
		pensionOutType: pensionOutType,
		healthOutType: healthOutType,
		lastYearTaxWageUnder7Paydocu: lastYearTaxWageUnder7Paydocu,
		lastYearTaxWageOver7Paydocu: lastYearTaxWageOver7Paydocu,
	};

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div
					style={{
						width: '100%',
						marginBottom: '10px',
						marginTop: '30px',
						fontSize: '20px',
						cursor: 'pointer',
					}}>
					✓ 건강보험료 및 고용보험 퇴직정산
				</div>

				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						alignContent: 'flex-end',
						width: '100%',
						marginBottom: '30px',
						marginTop: '30px',
						fontSize: '17px',
					}}>
					<span style={{ color: 'red' }}>* </span>
					<span style={{ color: 'blue' }}>정보 확인 및 입력</span>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						name='workerName'
						label='직원이름'
						disabled={selectedClientReq4In?.retiredDate}
					/>
				</div>

				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInput
						name='workerEnteredDate'
						label='입사일'
						placeholderText={selectedClientReq4In?.retiredDate}
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
						disabled={selectedClientReq4In?.retiredDate}
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyDateInput
						name='retiredDate'
						label='퇴직일 등(마지막 근무일)'
						placeholderText={selectedClientReq4In?.retiredDate}
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
						disabled={selectedClientReq4In?.retiredDate}
					/>
				</div>
			</div>
			<hr />
			<h3 style={{ color: 'blue' }}> * 건강보험 정산</h3>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='(올해)퇴사년도 비과세 제외 년간 보수총액'
						name='taxWageThisYear'
						defaultValue={thisYearTaxWagePaydocu}
						placeholder={
							thisYearTaxWagePaydocu
								? '등록된 금액합계 ' +
								  numberToCommaString(thisYearTaxWagePaydocu)
								: '꼭! 비과세 제외'
						}
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='(올해)퇴사년도 근무월수'
						name='workedMonthThisYear'
						placeholder={
							calThisYearWorkedMonth
								? calThisYearWorkedMonth
								: '1일이라도 걸치면 근무월수에 포함'
						}
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='(올해)퇴사년도에 납부한 년간 건강보험료 총액'
						name='paidHealthFeeTotalThisYear'
						placeholder={
							thisYearHealthCarePaydocu
								? '등록된 금액합계 ' +
								  numberToCommaString(thisYearHealthCarePaydocu)
								: '등록된 건보료가 없습니다.'
						}
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='(올해)퇴사년도에 납부한 년간 요양보험료 총액'
						name='paidOldFeeTotalThisYear'
						placeholder={
							thisYearOldCarePaydocu
								? '등록된 금액합계 ' +
								  numberToCommaString(thisYearOldCarePaydocu)
								: '등록된 요양보험료가 없습니다.'
						}
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='퇴사 전년도 비과세 제외 년간 보수총액'
						name='taxWageLastYear'
						placeholder={
							lastYearTaxWagePaydocu
								? '등록된 금액합계 ' +
								  numberToCommaString(lastYearTaxWagePaydocu)
								: '꼭! 비과세 제외'
						}
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='퇴사 전년도 근무월수'
						name='workedMonthLastYear'
						placeholder={
							calLastYearWorkedMonth
								? calLastYearWorkedMonth
								: '1일이라도 걸치면 근무월수에 포함'
						}
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<label style={{ fontWeight: 'bold' }}></label>
					<MyNumberInput
						label='퇴사 전년도에 납부한 년간 건강보험료 총액'
						name='paidHealthFeeTotalLastYear'
						placeholder={
							lastYearHealthCarePaydocu
								? '등록된 금액합계 ' +
								  numberToCommaString(lastYearHealthCarePaydocu)
								: '등록된 건보료가 없습니다.'
						}
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='퇴사 전년도에 납부한 년간 요양보험료 총액'
						name='paidOldFeeTotalLastYear'
						placeholder={
							lastYearOldCarePaydocu
								? '등록된 금액합계 ' +
								  numberToCommaString(lastYearOldCarePaydocu)
								: '등록된 요양보험료가 없습니다.'
						}
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<label style={{ fontWeight: 'bold' }}></label>
					<MyNumberInput
						label='전년도 확정 정산 중 올해 4월 이후 납부한 건보료'
						name='lastYearHealthCareRecalPaid'
						defaultValue={lastYearHealthCareRecalPaidSum}
						placeholder={
							lastYearHealthCareRecalPaidSum
								? '등록된 금액합계 ' +
								  numberToCommaString(lastYearHealthCareRecalPaidSum)
								: '등록된 건보료가 없습니다.'
						}
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='전년도 확정 정산 중 올해 4월 이후 납부한 요양료'
						defaultValue={lastYearOldCareRecalPaidSum}
						name='lastYearOldCareRecalPaid'
						placeholder={
							lastYearOldCareRecalPaidSum
								? '등록된 금액합계 ' +
								  numberToCommaString(lastYearOldCareRecalPaidSum)
								: '등록된 요양보험료가 없습니다.'
						}
						autoComplete='off'
					/>
				</div>
			</div>
			<br />
			<label>
				퇴사년 정산 건강보험료 : {numberToCommaString(dutyHealthFeeThisYear)}
			</label>
			<br />
			<label>
				퇴사년 정산 요양보험료 : {numberToCommaString(dutyOldFeeThisYear)}
			</label>
			<br />
			<label>
				작년도 확정 정산 건강보험료 :{' '}
				{numberToCommaString(dutyHealthFeeLastYear)}
			</label>
			<br />
			<label>
				전년도 확정 정산 요양보험료 : {numberToCommaString(dutyOldFeeLastYear)}
			</label>
			<br />
			<label>
				전년도 확정 정산 중 납부한 건강보험료 :{' '}
				{numberToCommaString(numLastYearHealthCareRecalPaid)}
			</label>
			<br />
			<label>
				전년도 확정 정산 중 납부한 요양보험료 :{' '}
				{numberToCommaString(numLastYearOldCareRecalPaid)}
			</label>
			<hr />
			<label>
				퇴직 정산 건강보험료 : {numberToCommaString(finalRetireHealthReCalFee)}
			</label>
			<br />
			<label>
				퇴직 정산 요양보험료 : {numberToCommaString(finalRetireOldReCalFee)}
			</label>
			<br />
			<br />
			{noHealthCare && (
				<label style={{ color: 'red' }}>
					* 건강보험 가입 대상자가 이닌것으로 확인됩니다. 대상자인 경우 등을
					확인하고 작업하세요.
				</label>
			)}
			<hr />
			<h3 style={{ color: 'blue' }}> * 고용보험 정산</h3>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<label style={{ fontWeight: 'bold' }}></label>
					<MyNumberInput
						label='전년도 7월 이전 보수총액'
						name='lastYearTaxWageUnder7Paydocu'
						defaultValue={lastYearTaxWageUnder7Paydocu}
						placeholder={
							lastYearTaxWageUnder7Paydocu
								? '등록된 금액합계 ' +
								  numberToCommaString(lastYearTaxWageUnder7Paydocu)
								: '등록된 보수총액이 없습니다.'
						}
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '24.5%', marginRight: '3px' }}>
					<MyNumberInput
						label='전년도 7월 이후 보수총액'
						defaultValue={lastYearTaxWageOver7Paydocu}
						name='lastYearTaxWageOver7Paydocu'
						placeholder={
							lastYearTaxWageOver7Paydocu
								? '등록된 금액합계 ' +
								  numberToCommaString(lastYearTaxWageOver7Paydocu)
								: '등록된 보수총액이 없습니다.'
						}
						autoComplete='off'
					/>
				</div>
			</div>
			{selectedClientReq4In ? (
				<>
					<Button
						loading={isSubmitting}
						// disabled={
						//   (currentUserProfile?.email === 'freya0207@gmail.com' || currentUserProfile?.email === 'dugiking@naver.com' ) ? false :
						//   (!isValid
						//   || !dirty
						//   || isSubmitting
						//   )}
						type='submit'
						onClick={async () => {
							dispatch(
								sendFinalRetireHealthCareRecalFee(finalRetireHealthReCalFee)
							);
							dispatch(sendFinalRetireOldCareRecalFee(finalRetireOldReCalFee));
							await addRetiredInsureInfoToFirestore(
								selectedClientReq4In?.id,
								retireInfo
							);
						}}
						floated='right'
						positive
						content='저장'
					/>
					<Button
						disabled={isSubmitting}
						as={Link}
						to='/clientReqs4In'
						type='submit'
						floated='right'
						content='취소'
					/>
				</>
			) : (
				<>
					<Button
						onClick={() => {
							dispatch(
								sendFinalRetireHealthCareRecalFee(finalRetireHealthReCalFee)
							);
							dispatch(sendFinalRetireOldCareRecalFee(finalRetireOldReCalFee));
						}}
						loading={isSubmitting}
						// disabled={!isValid || !dirty || isSubmitting}
						type='submit'
						floated='right'
						positive
						content='저장'
					/>
					<Button
						disabled={isSubmitting}
						as={Link}
						to='/clientReqs4In'
						type='submit'
						floated='right'
						content='취소'
					/>
				</>
			)}
		</>
	);
}
