import React from 'react'
import { josaGaType, josaUnType } from '../../../../../app/common/util/util';
import { useSelector } from 'react-redux';

export default function ClientReqDLCDJobAnalysis({companyName, worker, currentUserProfile}) {

  const { clientInfo } = useSelector((state) => state.companyReducer);
  const companyUn = josaUnType(companyName);
  const workerUn = josaUnType(worker);
  const workerGa = josaGaType(worker);

  const authLevel = clientInfo?.authLevel ? clientInfo?.authLevel : currentUserProfile?.authLevel;

  return (
    <>
      <div className="jau__contents">
        <span style={{fontSize : '1rem'}}><strong>제8조 직무관리</strong></span>
        <ul>
          <li>
            {`
              ① ${companyUn} 직원들의 원할한 업무 분장 및 유연근무제 등의 검토, 
              본 근로계약의 약정근로 외의 추가 근로 등의 원인 파악 및 승인 관리, 임금인상, 승진, 
              직무별 임금체계 운영, 비정규직 근로자 등의 차별대우 등을 방지 등 인사노무관리를 위한 
              업무의 양(근무내용)과 질(지식, 기술, 역량) 분석을 위한 직무분석을 위해
              ${worker}에게 업무 양과 질 등의 업무기록을 하게 할 수 있고, ${workerUn} 이에 응해야 한다. 
              만약 정당한 이유없이 ${workerGa} 동 의무를 이행하지 아니하는 경우는 징계의 대상이 된다. 
            `}
          </li>
          {
            authLevel >40 &&
            <>
              <li>
                {`
                  ② ${companyUn} 전조의 직무기술서, 경영환경분석, 동종업계의 인사고과 내용 및 관행, 
                  경영진의 인사고과 관점, 회사의 MISSION 등을 종합적으로 검토하여 따른 KSA와 R&R(Role & Responsibility)을 기반으로 직무 및 업무, 
                  성과평가를 위한 KPI(Key Performance Index)도출하여 MBO, BSC, OKR 등의 인사평가 기법으로 사원을 인사고과를 실시할 수 있다. 이경우 직무기술서가 없는 경우에는 
                  나머지 요소를 검토하여 평가를 실시한다.
                `}
              </li>
              <li>
                {`
                  ③ ${companyUn} 매년 또는 특정주기별로 ${worker}의 임금액, 또는 승진, 부서 이동, 직무변경 등을 결정할 때, 
                  제2항의 ${worker}의 인사고과 및 ${companyName}의 경영실적 등을 종합적으로 고려하여 인사를 결정한다.
                `}
              </li>
            </>
          }
        </ul>
      </div>
    </>
  )
}
