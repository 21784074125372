import React from 'react';

function ClientWageTableTopInfo({ isInterInWorker }) {
	return (
		<div>
			<div>
				{' '}
				* 본 임금명세서는 2021년 11월 19일 이후 시행되는 근로기준법
				제48조(임금명세서) 제2항 및 동법 시행령 제27조의 2(임금명세서의
				기재사항)에 따른{' '}
				<span style={{ fontSize: '1.1rem', color: 'blue' }}>
					기재사항을 안내한 명세서
				</span>
				입니다.
			</div>
			<div>
				{' '}
				*{' '}
				<span style={{ fontSize: '1.1rem', color: 'blue' }}>
					중도입퇴사 일할계산
				</span>
				과 세전 임금 및 세금, 4대보험 금액, 세후 임금은 모두 자동계산됩니다.
			</div>
			<div>
				{' '}
				* 추가 연장/야간/휴일 시간, 결근일자, 지각, 조퇴, 외출 시간 등이 있는
				경우{' '}
				<span style={{ fontSize: '1.1rem', color: 'blue' }}>
					일수와 시간만 입력하시면 일할계산 등은 자동계산
				</span>
				됩니다.
			</div>
			{isInterInWorker ? (
				<>
					<br />
					<div>
						<span style={{ fontSize: '1.1rem', color: 'blue' }}>
							{' '}
							* 국민연금법, 건강보험법상 중도입사자의 입사월 국민연금료와
							건강보험료, 요양보험료는 징수하지 않습니다.
						</span>
					</div>
				</>
			) : null}

			<br />
			<p style={{ fontSize: '1.3rem', color: 'blue' }}>
				* Premium 이용시 상단 임금(명세서)관리 메뉴 중 임금명세서 직원 등록
				코드를 직원에게 안내하시면 직원이 로그인해서 확인함으로 교부의무가
				이행됨을 안내해드리니다.{' '}
			</p>
		</div>
	);
}

export default ClientWageTableTopInfo;
