import { format } from 'date-fns';
import React, { useState } from 'react';
import {
	josaGaType,
	josaRoType,
	josaUnType,
	josaWaType,
	numberToCommaString,
	numStringToNumber,
	roundUp,
	roundUp2,
} from '../../../../../app/common/util/util';
import ClientReqDLCTail from './ClientReqDLCTail';
import ClientReqDLCDDayOrTimeWorkerWage from './ClientReqDLCDDayOrTimeWorkerWage';
import { Table } from 'semantic-ui-react';
import ClientReqDLCDDayOrTimeWorkerWagePaper from './ClientReqDLCDDayOrTimeWorkerWagePaper';
import { useSelector } from 'react-redux';

export default function ClientReqDLCDWageDay({
	daylyPay,
	currentUserProfile,
	selectedClientReq4In,
	daylyTimePay,
}) {
	const { clientInfo } = useSelector((state) => state.companyReducer);
	const initialValues = {
		dayWorkerTax: '',
		dayWorkerRegionTax: '',
		dayWorkerNoJob: '',
	};
	const [values, setValues] = useState(initialValues);

	function handelInputChange(e) {
		const { name, value } = e.target;
		setValues({ ...values, [name]: value });
	}

	const numDayWorkerTax = numStringToNumber(values.dayWorkerTax);
	const numDayWorkerRegionTax = numStringToNumber(values.dayWorkerRegionTax);
	const numDayWorkerNoJob = numStringToNumber(values.dayWorkerNoJob);

	function dayWorkerTax(daylyPay) {
		return (
			Math.floor(
				(daylyPay < 187000 ? 0 : ((daylyPay - 187000) * 2.7) / 100) / 10
			) * 10
		);
	}

	function regionTax(daylyPay) {
		return Math.floor((dayWorkerTax(daylyPay) * 0.1) / 10) * 10;
	}

	function noJob(daylyPay) {
		return Math.floor((daylyPay * 0.9) / 100 / 10) * 10;
	}

	function deductedPay(daylyPay) {
		return (
			daylyPay - dayWorkerTax(daylyPay) - regionTax(daylyPay) - noJob(daylyPay)
		);
	}

	function deductedPayHand(daylyPay) {
		return (
			daylyPay -
			(numDayWorkerTax ? +numDayWorkerTax : 0) -
			(numDayWorkerRegionTax ? +numDayWorkerRegionTax : 0) -
			(numDayWorkerNoJob ? +numDayWorkerNoJob : 0)
		);
	}

	const deductedPayOption =
		numDayWorkerTax > 0 || numDayWorkerRegionTax > 0 || numDayWorkerNoJob > 0
			? deductedPayHand(daylyPay)
			: deductedPay(daylyPay);

	const inputWageStyle = {
		border: 'none',
		borderRight: '0px',
		borderTop: '0px',
		borderLeft: '0px',
		borderBottom: '0px',
		textAlign: 'right',
		width: '5rem',
	};

	const numOfWorkers = selectedClientReq4In?.numOfWorkers;

	const company = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName
		? currentUserProfile?.companyName
		: selectedClientReq4In?.companyName;
	const companyUn = josaUnType(company);
	const companyGa = josaGaType(company);
	const companyWa = josaWaType(company);

	const worker = selectedClientReq4In?.worker?.name;
	const workerUn = josaUnType(worker);
	const workerGa = josaGaType(worker);

	const eDate = format(selectedClientReq4In?.workerEnteredDate, 'yyyy-MM-dd');
	const dTWorkerEndDate = selectedClientReq4In?.workerEndDate
		? format(selectedClientReq4In?.workerEndDate, 'yyyy-MM-dd')
		: null;

	const workStartTime = selectedClientReq4In?.daylyPay?.workingStartTime
		? selectedClientReq4In?.daylyPay?.workingStartTime
		: null;
	const workEndTime = selectedClientReq4In?.daylyPay?.workingEndTime
		? selectedClientReq4In?.daylyPay?.workingEndTime
		: null;
	const dayType = selectedClientReq4In?.daylyPay?.workingEndTimeNextDayOrNot
		? selectedClientReq4In?.daylyPay?.workingEndTimeNextDayOrNot
		: null;
	const dayRest = selectedClientReq4In?.daylyPay?.dayBreakTimes
		? selectedClientReq4In?.daylyPay?.dayBreakTimes
		: 0;
	const nightRest = selectedClientReq4In?.daylyPay?.nightBreakTimes
		? selectedClientReq4In?.daylyPay?.nightBreakTimes
		: 0;
	const breakTimeContents = selectedClientReq4In?.daylyPay?.breakTimeContents
		? selectedClientReq4In?.daylyPay?.breakTimeContents
		: null;
	const paidOffdays = selectedClientReq4In?.daylyPay?.paidOffdays
		? selectedClientReq4In?.daylyPay?.paidOffdays
		: null;

	const workingTimeDayNight =
		selectedClientReq4In?.dayWorker?.workingTimeDayNight;
	const workingTimeDayOver =
		selectedClientReq4In?.dayWorker?.workingTimeDayOver;
	const workingTimeDayBasic =
		selectedClientReq4In?.dayWorker?.workingTimeDayBasic;
	const week14OverBLTime = selectedClientReq4In?.week14OverBLTime;
	const dayWorkerPaidHolidayLBTime =
		selectedClientReq4In?.dayWorker?.dayWorkerPaidHolidayLBTime;

	const dayTotalPaidConversedTime =
		selectedClientReq4In?.dayWorker?.dayTotalPaidConversedTime;
	const realTimeWage = selectedClientReq4In?.dayWorker?.realTimeWage;
	const workFieldRo = josaRoType(selectedClientReq4In?.workDetailField);

	const dayWorkerOrTimeWorkerText = daylyTimePay ? '시급직' : '일용직(일당직)';
	const daylyPayDay = selectedClientReq4In?.daylyPay?.payDay
		? selectedClientReq4In?.daylyPay?.payDay
		: 'null';

	const specialWorkConditionContents = selectedClientReq4In?.daylyPay
		?.specialWorkConditionContents
		? selectedClientReq4In?.daylyPay?.specialWorkConditionContents
		: null;

	return (
		<>
			<Table width='100%' align='center'>
				<Table.Body>
					<Table.Row>
						<Table.Cell align='center'>
							<h1 style={{ textAlign: 'center', margin: '40px' }}>
								{dayWorkerOrTimeWorkerText} 근로계약서
							</h1>
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
			<div className='jau__title sectionup'>
				<ul>
					<li>
						{companyWa} {workerUn} 다음과 같이 {dayWorkerOrTimeWorkerText}{' '}
						근로계약을 체결하고 성실하게 이행할 것을 확인한다.
					</li>
				</ul>
			</div>
			<div className='jau__contents'>
				<div style={{ fontSize: '1.5rem' }}>
					<strong>제1조 근로일 및 근로관계</strong>
				</div>
				<ul>
					{daylyTimePay > 0 ? (
						<li>
							① {workerUn} 근로를 시간을 기준으로 제공하는 시급제 직원으로
							근로계약일은 {eDate}일 당일로 하고, 당일 시업시간에 근로관계가
							시작되고 종업시간에 근로관계가 종료됨을 원칙으로 한다.
						</li>
					) : (
						<li>
							① {worker}의 근로계약일은 {eDate}일(일당직인 경우 최초 시작일)
							당일로 하고, 당일 시업시간에 근로관계가 시작되고 종업시간에
							근로관계가 종료된다.(월 단위로 정산을 하는 경우 본 근로계약서의
							근로시간, 임금, 휴일 및 휴가 등 근로조건이 변경되는 경우는 그에
							맞는 근로계약서를 재작성하되, 그렇지 않고 근무 일자만 바뀌는
							경우의 근로계약서는 본 계약서로 갈음(근무일은 임금명세서로
							특정)한다.)
						</li>
					)}
					{dTWorkerEndDate ? (
						<li>
							② 제1항에도 불구하고 {companyWa} {workerGa} 계약기간을 정한 경우
							근로관계 시작일은 {eDate}, 종료일은 {dTWorkerEndDate}로 하고
							별도의 통지가 없는 이상 근로계약 종료일에 근로관계가 종료된다.
							다만, 근로관계 종료일을 {companyGa} 인지하지 못해 도과되는 경우 동
							종료일로터 1개월이 경과한 날 근로관계가 종료된 것으로 본다.
						</li>
					) : null}
				</ul>
			</div>
			<>
				<div className='jau__contents'>
					<div style={{ fontSize: '1.5rem' }}>
						<strong>제2조 업무의 내용</strong>
					</div>
					<ul>
						<li>
							{worker}의 담당업무는 {workFieldRo} 하고, {companyUn} 사업상
							필요에 따라 {worker}의 담당업무를 변경할 수 있다.
						</li>
					</ul>
				</div>
				<div className='jau__contents'>
					<div style={{ fontSize: '1.5rem' }}>
						<strong>제3조 근무장소</strong>{' '}
					</div>
					<ul>
						<li>
							{company}의 사업 또는 사업장 등을 본 계약서 제2조의 담당업무를
							수행할 수 있는 장소로 하고, {companyUn} 사업상 필요에 따라{' '}
							{worker}의 근무장소를 변경할 수 있다.
						</li>
					</ul>
				</div>
				<div className='jau__contents'>
					{numOfWorkers >= 5 ? (
						<div style={{ fontSize: '1.5rem' }}>
							<strong>제4조 근로일, 소정근로시간 및 휴게시간</strong>
						</div>
					) : (
						<div style={{ fontSize: '1.5rem' }}>
							<strong>제4조 근로일, 근로시간 및 휴게시간</strong>
						</div>
					)}
					{numOfWorkers < 5 ? (
						<ul>
							{daylyTimePay > 0 ? (
								<li>
									①{companyUn} 5인 미만 사업장으로 근로기준법 제11조 제2항, 동법
									시행령 제7조에 의거 {worker}에게 소정근로시간 관련 근로기준법
									제50조(소정근로시간), 제51조(탄력적근로시간제), 제52조(선택적
									근로시간제), 제53조(연장근로제한)을 적용하지 아니한다.
								</li>
							) : (
								<li>
									{` 
                      ${worker}의 시업 및 종업 시간은 ${workStartTime} ~ ${dayType} ${workEndTime}이며, 1일 소정근로시간은 5인미만 사업장이므로 적용하지 아니하며,
                      휴게시간은 ${
												parseInt(dayRest) + parseInt(nightRest)
											}분 (${breakTimeContents})로 한다. 다만, 휴게시간은 ${company}의 
                      업무 필요상 변경하거나 분할사용하게 할 수는 있으나, ${worker}의 근무시간 중 휴게시간의 총량은 보장한다.
                    `}
								</li>
							)}
						</ul>
					) : (
						<ul>
							{daylyTimePay > 0 ? (
								<li>
									{` 
                      ${company}의 업무 특성상 ${worker}의 근무일 및 근무시간을 사전에 특정하기 어려운 특징이 있는 점을 감안하여, 근무일 및 시업 및 종업 시간은 ${workerGa} 최소한 근무 시작 12시간 전에 스케줄표, 근무명령, 또는 상호 협의하에 등으로 정하되, 
                      ${companyUn} 1일 소정근로시간은 8시간을 초과하지 않게 정하며, 다수 근무일에 걸쳐 일하는 경우 협의 내용에 따라 1주 소정근로시간은 40시간을 초과할 수 없고, 
                      근무시간 중 휴게시간은 근무시간 4시간에 30분, 근무시간 8시간에 1시간 이상을 부여한다.
                      단, 근무시간 및 휴게시간은 업무 필요상 변경하거나 분할사용하게 할 수는 있으나, ${worker}의 근무시간 중 휴게시간의 총량은 보장한다.
                    `}
								</li>
							) : (
								<li>
									{` 
                      ${worker}의 시업 및 종업 시간은 ${workStartTime} ~ ${dayType} ${workEndTime}이며, 1일 소정근로시간은 ${workingTimeDayBasic} 시간,
                      휴게시간은 ${
												parseInt(dayRest) + parseInt(nightRest)
											}분 (${breakTimeContents})로 한다. 다만, 휴게시간은 ${company}의 
                      업무 필요상 변경하거나 분할사용하게 할 수는 있으나, ${worker}의 근무시간 중 휴게시간의 총량은 보장한다.
                    `}
								</li>
							)}
						</ul>
					)}
				</div>
				<div className='jau__contents'>
					<div style={{ fontSize: '1.5rem' }}>
						<strong>제5조 휴일 및 휴가</strong>
					</div>
					{numOfWorkers >= 5 ? (
						<ul>
							{dayWorkerPaidHolidayLBTime === '주휴수당 포함' ? (
								<>
									{daylyTimePay > 0 ? (
										<li>
											① 주휴수당 : {workerUn} 당일 근로관계가 시작되고
											소멸되므로 근로기준법 제55조의 유급주휴일 조항은 적용되지
											아니함이 원칙이되, 예외적으로 {workerGa} 연속된 특정
											7일(해당주가 7일이 되지 않는 경우(해당주 7일 이후 근무가
											예정되어 있지 않은 경우 포함)는 제외한다.) 이내(월요일부터
											기산할 필요는 없음.)에 근무일이 특정되면서 2일이상
											근무하면서 소정근로시간을 15시간 이상 근무하는 경우 1일의
											유급휴일(
											{paidOffdays
												? paidOffdays
												: '해당주 최초 근로일로부터 7일째 되는날 내에 있는 특정 1일'}
											)을 부여하고
											{companyUn} 매시간 주휴수당 1시간분(시급의 20%)을 {worker}
											의 시급에 포함하여 지급한다.
										</li>
									) : (
										<li>
											① {workerUn} 당일 근로관계가 시작되고 소멸되므로
											근로기준법 제55조의 유급주휴일 조항은 적용되지 아니함이
											원칙이되, 예외적으로 {workerGa} 연속된 특정 7일(해당주가
											7일이 되지 않는 경우(해당주 7일 이후 근무가 예정되어 있지
											않은 경우 포함)는 제외한다.) 이내(월요일부터 기산할 필요는
											없음.)에 근무일이 특정되면서 2일이상 근무하면서 근로시간을
											15시간 이상 근무하는 경우 1일의 유급휴일(
											{paidOffdays
												? paidOffdays
												: '해당주 최초 근로일로부터 7일째 되는날 내에 있는 특정 1일'}
											)을 부여하고, 일급에 주휴수당을 포함하여 지급한다.
										</li>
									)}
								</>
							) : (
								<>
									{daylyTimePay > 0 ? (
										<li>
											① 주휴수당 : {workerUn} 당일 근로관계가 시작되고 소멸되는
											경우가 많고, {companyWa} {worker} 각각의 사정으로 근로일
											및 근로시간에 특정되지 않는 관계로 근로기준법 제55조의
											유급주휴일 조항은 적용되지 아니함이 원칙이되, 예외적으로{' '}
											{workerGa} 연속된 특정 7일(해당주가 7일이 되지 않는
											경우(해당주 7일 이후 근무가 예정되어 있지 않은 경우
											포함)는 제외한다.) 이내(월요일부터 기산할 필요는 없음.)에
											근무일이 특정되면서 2일이상 근무하면서 소정근로시간을
											15시간 이상 근무하는 경우 1일의 유급휴일(
											{paidOffdays
												? paidOffdays
												: '해당주 최초 근로일로부터 7일째 되는날 내에 있는 특정 1일'}
											)을 부여하고 {companyUn} 해당일에 대한 주휴수당
											1일분(단시간 근로자 4주 소정근로시간 / 통상 근로자 4주
											근무일)을 {worker}의 일급을 주휴수당으로 지급한다.
										</li>
									) : (
										<li>
											① 주휴수당 : {workerUn} 당일 근로관계가 시작되고 소멸되는
											경우가 많고, {companyWa} {worker} 각각의 사정으로 근로일이
											특정되지 않는 관계로 근로기준법 제55조의 유급주휴일 조항은
											적용되지 아니함이 원칙이되, 예외적으로 {workerGa} 연속된
											특정 7일(해당주가 7일이 되지 않는 경우(해당주 7일 이후
											근무가 예정되어 있지 않은 경우 포함)는 제외한다.)
											이내(월요일부터 기산할 필요는 없음.)에 근무일이 특정되면서
											2일이상 근무하면서 소정근로시간을 15시간 이상 실근무하는
											경우 1일의 유급휴일(
											{paidOffdays
												? paidOffdays
												: '해당주 최초 근로일로부터 7일째 되는날 내에 있는 특정 1일'}
											)을 부여하고 {companyUn}
											해당일에 대한 주휴수당 1일분(단시간 근로자 4주
											소정근로시간 / 통상 근로자 4주 근무일)을 {worker}의 일급을
											주휴수당으로 지급한다.
										</li>
									)}
								</>
							)}
							<li>
								② {workerUn} 당일 근로관계가 시작되고 소멸되므로 근로기준법
								제60조의 연차유급휴가 조항은 적용되지 아니함이 원칙이되,
								예외적으로 {worker}의 각각의 근로가 동법의 법률 요건(1 개월 개근
								또는 1년간 80%이상 출근 등)이 충족되는 경우 연차유급휴가를
								부여한다.
							</li>
						</ul>
					) : (
						<ul>
							{dayWorkerPaidHolidayLBTime === '주휴수당 포함' ? (
								<>
									{daylyTimePay > 0 ? (
										<li>
											① 휴일, 휴가, 주휴수당 : {companyUn} 5인 미만 사업장으로
											근로기준법 제55조 제2항의 휴일과 제60조의 연차유급휴가
											조항이 적용되지 아니하며,
											{workerUn} 당일 근로관계가 시작되고 소멸되므로 근로기준법
											제55조의 유급주휴일 조항은 적용되지 아니함이 원칙이되,
											예외적으로 {workerGa} 연속된 특정 7일(해당주가 7일이 되지
											않는 경우(해당주 7일 이후 근무가 예정되어 있지 않은 경우
											포함)는 제외한다.) 이내(월요일부터 기산할 필요는 없음.)에
											근무일이 특정되면서 2일이상 근무하면서 소정근로시간을
											15시간 이상 근무하는 경우 1일의 유급휴일(
											{paidOffdays
												? paidOffdays
												: '해당주 최초 근로일로부터 7일째 되는날 내에 있는 특정 1일'}
											)을 부여하고 {companyUn} 매시간 주휴수당 1시간분(시급의
											20%)을 {worker}의 시급에 포함하여 지급한다.
										</li>
									) : (
										<li>
											① {workerUn} {companyUn} 5인 미만 사업장으로 근로기준법
											제55조 제2항의 휴일과 제60조의 연차유급휴가 조항이
											적용되지 아니하며,당일 근로관계가 시작되고 소멸되므로
											근로기준법 제55조의 유급주휴일 조항은 적용되지 아니함이
											원칙이되, 예외적으로 {workerGa} 연속된 특정 7일(해당주가
											7일이 되지 않는 경우(해당주 7일 이후 근무가 예정되어 있지
											않은 경우 포함)는 제외한다.) 이내(월요일부터 기산할 필요는
											없음.)에 근무일이 특정되면서 2일이상 근무하면서 근로시간을
											15시간 이상 근무하는 경우 1일의 유급휴일(
											{paidOffdays
												? paidOffdays
												: '해당주 최초 근로일로부터 7일째 되는날 내에 있는 특정 1일'}
											)을 부여하고, 일급에 주휴수당을 포함하여 지급한다.
										</li>
									)}
								</>
							) : (
								<>
									{daylyTimePay > 0 ? (
										<li>
											① 휴일, 휴가, 주휴수당 : {companyUn} 5인 미만 사업장으로
											근로기준법 제55조 제2항의 휴일과 제60조의 연차유급휴가
											조항이 적용되지 아니하며,
											{workerUn} 당일 근로관계가 시작되고 소멸되는 경우가 많고,{' '}
											{companyWa} {worker} 각각의 사정으로 근로일 및 근로시간에
											특정되지 않는 관계로 근로기준법 제55조의 유급주휴일 조항은
											적용되지 아니함이 원칙이되, 예외적으로 {workerGa} 연속된
											특정 7일(해당주가 7일이 되지 않는 경우(해당주 7일 이후
											근무가 예정되어 있지 않은 경우 포함)는 제외한다.)
											이내(월요일부터 기산할 필요는 없음.)에 근무일이 특정되면서
											2일이상 근무하면서 소정근로시간을 15시간 이상 실근무하는
											경우 1일의 유급휴일(
											{paidOffdays
												? paidOffdays
												: '해당주 최초 근로일로부터 7일째 되는날 내에 있는 특정 1일'}
											)을 부여하고 {companyUn} 해당일에 대한 주휴수당
											1일분(단시간 근로자 4주 소정근로시간 / 통상 근로자 4주
											근무일)을 {worker}의 일급을 주휴수당으로 지급한다.
										</li>
									) : (
										<li>
											① 휴일, 휴가, 주휴수당 : {workerUn} 당일 근로관계가
											시작되고 소멸되는 경우가 많고, {companyWa} {worker} 각각의
											사정으로 근로일이 특정되지 않는 관계로 근로기준법 제55조의
											유급주휴일 조항은 적용되지 아니함이 원칙이되, 예외적으로{' '}
											{workerGa} 연속된 특정 7일(해당주가 7일이 되지 않는
											경우(해당주 7일 이후 근무가 예정되어 있지 않은 경우
											포함)는 제외한다.) 이내(월요일부터 기산할 필요는 없음.)에
											근무일이 특정되면서 2일이상 근무하면서 소정근로시간을
											15시간 이상 실근무하는 경우 1일의 유급휴일(
											{paidOffdays
												? paidOffdays
												: '해당주 최초 근로일로부터 7일째 되는날 내에 있는 특정 1일'}
											)을 부여하고 {companyUn} 해당일에 대한 주휴수당
											1일분(단시간 근로자 4주 소정근로시간 / 통상 근로자 4주
											근무일)을 {worker}의 일급을 주휴수당으로 지급한다.
										</li>
									)}
								</>
							)}
						</ul>
					)}
				</div>
				<br />
				<ClientReqDLCDDayOrTimeWorkerWage
					numOfWorkers={numOfWorkers}
					numberToCommaString={numberToCommaString}
					roundUp={roundUp}
					daylyPay={daylyPay}
					realTimeWage={realTimeWage}
					week14OverBLTime={week14OverBLTime}
					roundUp2={roundUp2}
					dayTotalPaidConversedTime={dayTotalPaidConversedTime}
					company={company}
					companyUn={companyUn}
					worker={worker}
					workerGa={workerGa}
					workingTimeDayBasic={workingTimeDayBasic}
					workingTimeDayOver={workingTimeDayOver}
					workingTimeDayNight={workingTimeDayNight}
					dayWorkerPaidHolidayLBTime={dayWorkerPaidHolidayLBTime}
					daylyTimePay={daylyTimePay}
					daylyPayDay={daylyPayDay}
				/>
				<br />
				<div className='jau__contents'>
					<div style={{ fontSize: '1.5rem' }}>
						<strong>제7조 비밀유지 및 손해배상 등</strong>
					</div>
					<ul>
						<li>
							① {workerUn} 영업비밀 및 부당경쟁 방지법상의 비밀유지의무를 지고
							영업비밀을 누출할 경우 민사상, 형사상 책임을 진다.
						</li>
						<li>
							② {companyUn} 노동관계 법령의 준수 의무를 위반한 경우 그리고{' '}
							{workerUn} 고의 또는 과실로 회사에 손해를 입힌 경우 상대방에 대해
							민사상 책임을 진다.
						</li>
						<li>
							③ {workerUn} {companyGa} 지급한 장비 및 소모품에 대하여 선량한
							관리자의 주의의무를 부담하며, 어떠한 경우라도 분실, 훼손,
							미반납시에는 해당 물품의 현가(당사자가 합의한 금액 또는 소송으로
							확정된 금액)를 배상해야 한다.
						</li>
						<li>
							④ 본 계약서에 명기되지 아니한 사항에 대해서는 {company} 내규 및
							노동관계법령에 따른다.
						</li>
						<li>
							⑤ {companyWa} {worker}간 소송은 {company}의 소재지 관할법원으로
							한다.
						</li>
					</ul>
				</div>
				{specialWorkConditionContents ? (
					<div className='jau__contents'>
						<div style={{ fontSize: '1.5rem' }}>
							<strong>제8조 특약사항 등</strong>
						</div>
						<ul>
							<li>
								{workerUn} {specialWorkConditionContents}
							</li>
							<li style={{ color: 'blue' }}>
								* 동의자 : {worker}(인 또는 사인)
							</li>
						</ul>
					</div>
				) : null}
				<ClientReqDLCTail
					worker={worker}
					currentUserProfile={currentUserProfile}
					selectedClientReq4In={selectedClientReq4In}
				/>
				<br />
				<br />
				<hr />
				<ClientReqDLCDDayOrTimeWorkerWagePaper
					eDate={eDate}
					daylyPay={daylyPay}
					realTimeWage={realTimeWage}
					inputWageStyle={inputWageStyle}
					dayWorkerTax={dayWorkerTax}
					regionTax={regionTax}
					noJob={noJob}
					worker={worker}
					deductedPayOption={deductedPayOption}
					handelInputChange={handelInputChange}
					format={format}
					numberToCommaString={numberToCommaString}
					roundUp={roundUp}
					daylyTimePay={daylyTimePay}
					week14OverBLTime={week14OverBLTime}
				/>
			</>
		</>
	);
}
