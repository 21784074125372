import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Icon, Card, Table } from 'semantic-ui-react';
import PriceLaborContractType from './PriceLaborContractType';
import styled, { keyframes } from 'styled-components';
import { useSelector } from 'react-redux';

const BlinkAnimation = keyframes`
0% {opacity: 1;}
50% {opacity: 0.5;}
100% {opacity: 1;}
`;

const BlinkIcon = styled(Icon)`
	animation: ${BlinkAnimation} 0.5s linear infinite;
`;

const CardContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: center;
	align-items: flex-start; /* 카드 상단 정렬 */
`;

const StyledCard = styled(Card)`
	width: 320px;
	height: 250px;
	padding: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: transform 0.2s;
	cursor: pointer;
	border-radius: 8px;
	background-color: #e0f7fa !important; /* Force the background color */

	&:hover {
		transform: scale(1.4); /* Scale the card by 1.5 times */
		box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
		z-index: 10; /* Bring the hovered card to the front */
	}
`;

const StyledCardHeader = styled(Card.Header)`
	font-size: 1.3rem; /* Increased size */
	margin-bottom: 30px;
`;

export default function PricePolicyPaper() {
	const { currentUserProfile } = useSelector((state) => state.profile);

	const authLevel = currentUserProfile?.authLevel;
	const [openFeaturesTable, setOpenFeaturesTable] = useState(false);
	const history = useHistory();

	async function goBack() {
		history.goBack();
	}

	const pricingData = [
		{ employees: '5인 미만', systemPrice: 50000 },
		{ employees: '10인 미만', systemPrice: 80000 },
		{ employees: '30인 미만', systemPrice: 150000 },
		{ employees: '50인 미만', systemPrice: 350000 },
		{ employees: '100인 미만', systemPrice: 500000 },
		{
			employees: '100인 이상',
			systemPrice: '500,000 + 100명당 추가 300,000',
		},
	];

	const pricingData1 = [
		{
			category: '4대보험 입퇴사 신고 및 매월 4대보험료 정산 임금대장 작성 대행',
			description: '전산에 등록된 근로자 1인당 (월)',
			price: '5,000원',
		},
		{
			category: '입퇴사자 전산입력 대행',
			description: '전산에 등록된 근로자 1인당 (월)',
			price: '6,000원',
		},
		{
			category: '취업규칙 등 설계 대행',
			description: '규정당 ',
			price: '별도 협의',
		},
		{
			category: '법정의무 교육 대행',
			description: '1시간당',
			price: '별도 협의',
		},
		{
			category: '노동사건 대리',
			description: '1건당',
			price: '별도 협의',
		},
	];

	return (
		<>
			<Button color='blue' onClick={() => history.push('/clientReqs4In')}>
				메인페이지
			</Button>
			<Button color='facebook' onClick={() => goBack()}>
				이전페이지
			</Button>
			<br />
			<br />
			<br />
			<Grid>
				<Grid.Column width={16}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							alignContent: 'center',
							cursor: 'pointer',
						}}
						onClick={() => setOpenFeaturesTable(!openFeaturesTable)}>
						<span className='material-icons' style={{ fontSize: '25px' }}>
							notification_important
						</span>
						<span style={{ fontSize: '23px', cursor: 'pointer' }}>
							<span>
								직원별 입사정보 등록과 동시에
								<span style={{ color: 'red' }}>
									0.1초만에 구분하여 만들어지는 AI NOMUSA의 11종 근로계약서(안)
									종류 보기
								</span>
								<BlinkIcon name='hand point up' />
							</span>
						</span>
					</div>
					<br />
					{openFeaturesTable && (
						<PriceLaborContractType
							openFeaturesTable={openFeaturesTable}
							setOpenFeaturesTable={setOpenFeaturesTable}
						/>
					)}
				</Grid.Column>
			</Grid>
			<Grid>
				<Grid.Column width={16}>
					<br />
					<br />
					<CardContainer>
						<StyledCard
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={() => (window.location.href = '/clientReqs4In')} // Replace '/' with your initial route
						>
							{' '}
							<img
								src='/AINomusa.png'
								alt='Company Logo'
								style={{ width: '150px', height: 'auto' }}
							/>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>고용안정지원금 안내</StyledCardHeader>
							<Card.Description>
								- 감원방지기간 자동계산 <br />
								- 육아휴직 사용자 지원금 후보자 안내
								<br />
								- 2024 청년도약 장려금 후보자 안내
								<br />
								- 산전후휴가 대체인력 지원금 후보자 안내
								<br />- 육아기근로시간단축 대체인력지원금 후보자안내
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>근로계약서</StyledCardHeader>
							<Card.Description>
								- 소정근로시간, 휴일, 휴가 표시 <br />
								- 정규직, 수습직, 계약직 <br />
								- 정년이후 촉탁직, 단시간직 <br />
								- 초단시간직, 연소근로제 <br />
								- 일용직, 시급직 <br />
								- 월 단위 일당직 <br />
								- 2주단위 탄력적 근로시간제 <br />
								- 간주근로 시간제 <br />* 위 근로계약서 모두 자동 생성
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>임금(연봉) 계약서</StyledCardHeader>
							<Card.Description>
								- 통상임금 표시 <br />
								- 평균임금 표시 <br />
								- 약정 법정 수당 표시 <br />
								- 임금의 구성항목 자동 생성 <br />
								- 임금의 계산방법 자동 생성 <br />- 임금의 지급방법 자동 생성
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>임금명세서</StyledCardHeader>
							<Card.Description>
								- 통상임금/평균임금/기타금품 구분 표시 <br />
								- 통상시급 표시 <br />
								- 통상임금 기준 시간 수 계산법 표시 <br />
								- 중도입퇴사 자동일할 임금계산 <br />
								- 연장/야간/휴일 임금 자동 계산 <br />
								- 미사용 연차수당 자동 계산 <br />
								- 퇴직 건보 정산 자동 계산 <br />
								- 퇴직금 정산 자동 계산 <br />- 실수령액(Net) 자동 계산기
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>4대보험 관리</StyledCardHeader>
							<Card.Description>
								- 연령에 따른 4대보험 가입여부 자동 판단 <br />
								- 근로시간에 따른 4대보험 가입여부 자동 판단 <br />
								- 임금액에 따른 4대보험 가입여부 자동 판단 <br />
								- 근무일수에 따른 4대보험 가입여부 자동 판단 <br />
								- 외국인 비자에 따른 4대보험 가입여부 자동 판단 <br />
								- 일용/시급직 근로내역 신고 <br />
								- 월급직 입퇴사 신고 파일 다운로드 <br />
								- 일용직 국민연금 입퇴사 신고 파일 다운로드 <br />
								- 일용직 건강보험 입퇴사 신고 파일 다운로드 <br />
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>연차 관리</StyledCardHeader>
							<Card.Description>
								- 입사일 연차와 회계년도 연차 비교 확인 <br />
								- 4개년 연차 발생과 소멸 현황 안내 <br />
								- 월 단위 연차 자동 계산 <br />
								- 연 단위 연차 자동 계산 <br />
								- 회계년도 비례연차 자동 계산 <br />
								- 연차 수당 지급시 잔여 연차 자동 계산 <br />
								- 연차 휴가 사용시 잔여 연차 자동 계산 <br />
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>알람 서비스</StyledCardHeader>
							<Card.Description>
								- 주 52시간제 위반 가능성 <br />
								- 법정휴게시간 부여 위반 가능성 <br />
								- 최저임금제 위반 가능성 <br />- 최저임금 부족액 안내
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>인사관리 서비스</StyledCardHeader>
							<Card.Description>
								- BSC 기반 역량모델링 <br />
								- BSC 기반 인사평가 기능 <br />
								- 안전보건활동 모니터링 기능 <br />
								- 안전보건체계 구축 기능 <br />
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>인트라넷 서비스</StyledCardHeader>
							<Card.Description>
								- 소정근로시간,휴일,휴가 명시 근로계약서 교부 <br />
								- 임금구성,계산/지급 방법 명시 임금계약서 교부 <br />
								- 임금구성,계산/지급 방법 명시 임금명세서 교부 <br />
								- BSC 역량 모델링 Data 입력 <br />- 안전 보건 활동 모니터링 Data
								입력
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>인사서식 서비스</StyledCardHeader>
							<Card.Description>
								* 기본 서식 <br />
								- 근로자명부 <br />
								- 정보보호서약서 <br />
								- 개인정보보호동의서 <br />
								- 영업비밀보호서약서 <br />
								- 복무서약서 <br />
								<br /> * 맞춤 서식(별도 협의) <br />
							</Card.Description>
						</StyledCard>

						<StyledCard>
							<StyledCardHeader>규정서비스</StyledCardHeader>
							<Card.Description>
								* 맞춤 규정(별도 협의) <br />
								<br />
								- 일반 취업규칙 <br />
								- 가족친화인증용 취업규칙 <br />
								- 유연근무제용 취업규칙 <br />
								- 노사협의회 규정 <br />- 영업비밀보호규정
							</Card.Description>
						</StyledCard>
					</CardContainer>
					<div>재정일 : 2021년 8월 20일</div>
					<div>1차 개정일 : 2021년 12월 7일</div>
					<div>2차 개정일 : 2022년 12월 1일</div>
					<div>3차 개정일 : 2023년 10월 1일</div>
					<div>4차 개정일 : 2024년 02월 12일</div>
					<div>5차 개정일 : 2024년 08월 01일</div>
					<br />
					{(authLevel <= 10 || authLevel > 99) && (
						<>
							<span style={{ fontSize: '25px', color: 'blue' }}>
								{' '}
								AI NOMUSA의 가격정책
							</span>
							<br />
							<h2>서비스 가격</h2>
							<Table celled>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell textAlign='center' width={8}>
											회사 직원 수 (정규직, 비정규직, 사업소득직 포함)
										</Table.HeaderCell>
										<Table.HeaderCell textAlign='center'>
											전산 서비스 가격 (월)
										</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{pricingData.map((item, index) => (
										<Table.Row key={index}>
											<Table.Cell textAlign='center'>
												{item.employees}
											</Table.Cell>
											<Table.Cell textAlign='center'>
												{item.systemPrice.toLocaleString()}원
											</Table.Cell>
										</Table.Row>
									))}
								</Table.Body>
							</Table>
							<br />
							<Table celled>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell textAlign='center' width={8}>
											부가 서비스 카테고리
										</Table.HeaderCell>
										<Table.HeaderCell textAlign='center' width={4}>
											설명
										</Table.HeaderCell>
										<Table.HeaderCell textAlign='center' width={4}>
											가격
										</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{pricingData1.map((item, index) => (
										<Table.Row key={index}>
											<Table.Cell textAlign='center'>
												{item.category}
											</Table.Cell>
											<Table.Cell textAlign='center'>
												{item.description}
											</Table.Cell>
											<Table.Cell textAlign='center'>{item.price}</Table.Cell>
										</Table.Row>
									))}
								</Table.Body>
							</Table>
						</>
					)}
				</Grid.Column>
			</Grid>
			<br />
			<Button color='facebook' onClick={() => goBack()}>
				이전페이지
			</Button>
		</>
	);
}
