import React, { useState, useMemo, useEffect } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && (
						<div className='no-print'>
							<ul>
								{amended.map((amendment, i) => (
									<li
										key={i}
										style={{
											color: amendment.includes('개정') ? 'red' : 'black',
										}}>
										{amendment}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function Roe2Council({
	serviceStartDate,
	profile,
	company,
	companyUn,
	companyGa,
	companyWa,
	companyUl,
	allOpen,
	previousSectionCount1, // 이전 섹션 수를 전달받음
	setSectionCount,
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `구성`,
				content: [
					`본 협의회는 사원들을 대표하는 위원 (이하 “근로자 위원”이라 함)과 ${companyUl} 대표하는 위원 (이하 “사용자 위원”이라 함) 동수의 위원으로 구성하되 노·사 각 3인이상으로 한다.`,
					`사원을 대표하는 위원(이하 “근로자위원”이라 한다)은 사원 과반수가 참여하여 직접ㆍ비밀ㆍ무기명 투표로 선출한다. 다만, 사업 또는 사업장의 특수성으로 인하여 부득이한 경우에는 부서별로 사원 수에 비례하여 근로자위원을 선출할 사원(이하 이 조에서 “위원선거인”이라 한다)를 사원 과반수가 참여한 직접ㆍ비밀ㆍ무기명 투표로 선출하고 위원선거인 과반수가 참여한 직접ㆍ비밀ㆍ무기명 투표로 근로자위원을 선출할 수 있다. 단, 사업 또는 사업장에 사원의 과반수로 조직된 노동조합이 있는 경우에는 근로자위원은 노동조합의 대표자와 그 노동조합이 위촉한 자로 한다.`,
					`사용자의원은 ${company} 대표와 기타 ${company} 대표가 위촉하는 임직원으로 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate >= '2021-11-19'
						? null
						: [
								`2022년 06월 10일 개정`,
								`변경전 : ② 근로자위원은 사원이 직접ㆍ비밀ㆍ무기명투표로 선출하되, 사원의 과반수로 조직된 노동조합이 있는 경우에는 노동조합의 대표자와 그 노동조합이 위촉하는 자로 한다.`,
								`변경후 : ② 사원을 대표하는 위원(이하 “근로자위원”이라 한다)은 사원 과반수가 참여하여 직접ㆍ비밀ㆍ무기명 투표로 선출한다. 다만, 사업 또는 사업장의 특수성으로 인하여 부득이한 경우에는 부서별로 사원 수에 비례하여 근로자위원을 선출할 사원(이하 이 조에서 “위원선거인”이라 한다)를 사원 과반수가 참여한 직접ㆍ비밀ㆍ무기명 투표로 선출하고 위원선거인 과반수가 참여한 직접ㆍ비밀ㆍ무기명 투표로 근로자위원을 선출할 수 있다. 단, 사업 또는 사업장에 사원의 과반수로 조직된 노동조합이 있는 경우에는 근로자위원은 노동조합의 대표자와 그 노동조합이 위촉한 자로 한다.`,
								`개정이유 : 2022년 6월 10일 근로자참여 및 협력증진에 관한 법률 제6조 개정 반영`,
						  ],
			},
			{
				title: `의장`,
				content: [
					`협의회의 의장은 위원중에서 호선한다. 다만, 필요하다고 인정될 때는 근로자위원과 사용자위원 중 각 1인을 공동의장으로 할 수 있다.`,
					`의장은 협의회를 대표하고 회의를 원만히 진행하며 통보 및 보고의 대표자가 된다.`,
					`의장의 임기는 3년으로 하되 연임할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `간사`,
				content: [
					`노사 쌍방은 회무의 기록, 협의회 업무의 통보 등을 위하여 각 1인씩의 간사를 둔다.`,
					`간사는 노사협의회(이하 “협의회”라 한다)의 사원을 대표하는 위원들과 ${companyUl} 대표하는 위원들이 근로자위원과 사용자위원 중에서 각각 1명씩 선출한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `위원의 임기`,
				content: [
					`각 위원의 임기는 3년으로 하되 연임할 수 있다.`,
					`임기가 만료된 위원이라도 그 후임자가 선출될 때까지 계속 그 직무를 담당한다.`,
					`보궐 위원의 임기는 전임 위원의 잔여기간으로 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `위원의 신분`,
				content: [
					`각 위원과 간사는 비상임, 무보수로 한다.`,
					`${companyUn} 협의회 위원으로서의 직무 수행과 관련하여 근로자위원에게 불이익을 주는 처분을 하여서는 아니 된다.`,
					`${companyUn} 위원의 협의회 출석에 소요되는 시간에 대하여는 근로한 것으로 본다. 아울러, 협의회 출석과 직접 관련된 협의회 출석을 위한 이동시간 및 자료검토시간(회의 1회당 최대 4시간)에 대해서도 근로한 것으로 본다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
		];

		// if (['대한민국', '한국', '일본'].includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles.includes(section.title)
			);
		}

		return baseSections;
	}, [company, companyUl, companyUn, serviceStartDate]);

	useEffect(() => {
		setSectionCount(sections.length);
	}, [sections, setSectionCount]);

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className='jang sectionup'>제2장 협의회의 구성</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={index + previousSectionCount1 + 1}
						title={section.title}
						content={section.content}
						isOpen={!!openSections[section.title]}
						toggleOpen={() => toggleSection(section.title)}
						amended={section.amended}
					/>
				</div>
			))}
		</>
	);
}
