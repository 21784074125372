// import { format } from 'date-fns';
import React, { useState } from 'react';
import { Grid, Tab, Table } from 'semantic-ui-react'

import CmsProgramContractContents from './CmsProgramContractContents';


export default function ProgramUseContractContents({profile}) {
  const [price, setPrice] = useState('기본형');

  return (
    <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
      <Tab.Pane>
        <Grid >
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Cell style={{backgroundColor: 'yellow'}}>
                  <label>계약유형 선택 : </label>
                  <select style={{backgroundColor : 'blue', width : "10rem", height : "2.5rem", color: 'white'}}
                    onChange = {(event) => setPrice(event.target.value)} 
                  >
                    <option value='전산.Basic'>전산.Basic</option>
                    <option value='전산.Premium'>전산.Premium</option>
                  </select>
                  &nbsp;&nbsp;<label>{(price === '전산.Premium') ? '전산.Basic + (일용직 근로계약서(임금명세서), 취업규칙(안), 노사협의회 규정(안))' : null }</label>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <CmsProgramContractContents 
            profile={profile}
            price={price}
          />
        </Grid>
      </Tab.Pane>
    </div>
  );
};




// {/* <div>Member since: {format(profile?.createdAt, 'yyyy-MM-dd')}</div> */}
// <div style={{ marginBottom: 10 }}>
// <strong>
//   <br />
//   <div>대표자 성명 : {profile?.ceoName || null}</div>
//   <div>사업자등록번호 : {profile?.companyBizNumber || null}</div>
//   <div>업태 : {profile?.bizType || null}</div>
//   <div>종목 : {profile?.bizItem || null}</div>
// </strong>
// <br />
// <div>팩스번호 : {profile?.companyFax || null}</div>
// <div>주소 : {profile?.companyAddress || null}</div>
// <div>담당자 이름 : {profile?.staffName || null}</div>
// <div>담당자 이메일 : {profile?.staffEmail || null}</div>
// </div>