import React, { useState, useMemo, useEffect } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && (
						<div className='no-print'>
							<ul>
								{amended.map((amendment, i) => (
									<li
										key={i}
										style={{
											color: amendment.includes('개정') ? 'red' : 'black',
										}}>
										{amendment}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function Roe5WorkerStatusRules({
	serviceStartDate,
	// profile,
	// authLevel,
	company,
	companyUn,
	companyGa,
	companyWa,
	allOpen,
	previousSectionCount1,
	previousSectionCount2,
	previousSectionCount3,
	previousSectionCount4,
	setSectionCount,
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `대기발령`,
				content: [
					`${companyUn} 다음 각 호에 해당되는 사원에게 대기발령을 명할 수 있다.`,
					[
						`직무수행능력이 현저히 부족한 사원`,
						`징계심의 계류 중인 사원`,
						`형사사건으로 기소된 사원`,
						`기구개편 등으로 보직이 없는 사원`,
						`직장 내 성희롱을 행한 사원`,
						`직장 내 괴롭힘을 발생시킨 사원`,
					],
					`전항의 규정에 의한 대기발령은 3개월 이내로 한다.`,
					`${companyUn} 사원에게 대기발령 사유와 종류 및 기간을 서면으로 명시하여 3일 전까지 통보한다.`,
					`${companyUn} 대기발령자에게 자택대기를 명하거나 ${company}의 출근대기를 명할 수 있다.`,
					`자택대기발령 중의 급여는 휴업수당을 지급한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `가족돌봄휴직`,
				content: [
					`${companyUn} 사원이 조부모, 부모, 배우자, 배우자의 부모, 자녀 또는 손자녀(이하 "가족"이라 한다)의 질병, 사고, 노령으로 인하여 그 가족을 돌보기 위한 가족돌봄휴직을 신청하는 경우 이를 허용해야 한다.`,
					`${companyUn} 다음 각 호의 경우에 가족돌봄휴직을 허용하지 아니할 수 있다.`,
					[
						`가족돌봄휴직을 시작하려는 날의 전날까지 ${company}에서 계속 근로한 기간이 6개월 미만인 경우`,
						`사원의 부모, 자녀, 배우자 등 가족이 돌봄이 필요한 가족을 돌볼 수 있는 경우`,
						`조부모 또는 손자녀를 돌보기 위하여 가족돌봄휴직을 신청한 사원 외에도 조부모의 직계비속 또는 손자녀의 직계존속이 있는 경우. 다만, 조부모의 직계비속 또는 손자녀의 직계존속에게 질병, 노령, 장애 또는 미성년 등의 사유가 있어 신청한 사원이 돌봐야 하는 경우에는 가족돌봄휴직을 허용해야 한다.`,
						`{companyGa} 직업안정기관에 구인신청을 하고 14일 이상 대체인력을 채용하기 위하여 노력하였으나 대체인력을 채용하지 못한 경우. 다만, 직업안정기관의 장의 직업소개에도 불구하고 정당한 이유 없이 2회 이상 채용을 거부한 경우는 가족돌봄휴직을 허용해야 한다.`,
						`사원의 가족돌봄휴직으로 인하여 정상적인 사업 운영에 중대한 지장이 초래되는 경우로서 {companyGa} 이를 증명하는 경우`,
						`기타 관계법령이 정한 사유가 발생한 경우`,
					],
					`{companyGa} 가족돌봄휴직을 허용하지 아니하는 경우에는 해당 사원에게 그 사유를 서면으로 통보하고, 다음 각 호의 어느 하나에 해당하는 조치를 하도록 노력해야 한다.`,
					[
						`업무를 시작하고 마치는 시간 조정`,
						`연장근로의 제한`,
						`근로시간의 단축, 탄력적 운영 등 근로시간의 조정`,
						`그 밖에 사업장 사정에 맞는 지원조치`,
					],
					`가족돌봄휴직 기간은 연간 최장 90일[가족돌봄휴가 포함]로 하며, 이를 나누어 사용할 수 있다. 이 경우 나누어 사용하는 1회의 기간은 30일 이상이 되어야 한다.`,
					`${companyUn} 가족돌봄휴직을 이유로 해당 사원을 해고하거나 근로조건을 악화시키는 등 불리한 처우를 하여서는 아니 된다.`,
					`가족돌봄휴직 기간은 근속기간에 포함한다. 다만, 평균임금 산정기간에서는 제외한다.`,
					`${companyUn} 해당 사원이 건전하게 직장과 가정을 유지하는 데에 도움이 될 수 있도록 필요한 심리상담 서비스를 제공하도록 노력한다.`,
					`사원은 가족돌봄휴직을 시작하려는 날(이하 “돌봄휴직개시예정일”이라 한다)의 30일 전까지 가족돌봄휴직 기간 중 돌보는 대상인 가족의 성명, 생년월일, 돌봄이 필요한 사유, 돌봄휴직개시예정일, 가족돌봄휴직을 종료하려는 날(이하 “돌봄휴직종료예정일”이라 한다), 가족돌봄휴직 신청 연월일, 신청인 등에 대한 사항을 적은 문서(전자문서를 포함한다)를 ${company}에게 제출해야 한다.`,
					`${companyUn} 사원이 제8항에 따른 기한이 지난 뒤에 가족돌봄휴직을 신청한 경우에는 그 신청일부터 30일 이내로 가족돌봄휴직 개시일을 지정하여 가족돌봄휴직을 허용해야 한다.`,
					`${companyUn} 가족돌봄휴직을 신청한 사원에게 돌봄이 필요한 가족의 건강 상태, 신청인 외의 가족 등의 돌봄 가능 여부 등 사원의 가족돌봄휴직의 필요성을 확인할 수 있는 서류의 제출을 요구할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `가족돌봄 등을 위한 근로시간 단축`,
				content: [
					`${companyUn} 사원이 다음 각 호의 어느 하나에 해당하는 사유로 근로시간의 단축을 신청하는 경우에 이를 허용해야 한다.`,
					[
						`사원이 가족의 질병, 사고, 노령으로 인하여 그 가족을 돌보기 위한 경우`,
						`사원 자신의 질병이나 사고로 인한 부상 등의 사유로 자신의 건강을 돌보기 위한 경우`,
						`55세 이상의 근로자가 은퇴를 준비하기 위한 경우`,
						`사원의 학업을 위한 경우`,
					],
					`${companyUn} 다음 각 호의 경우에 가족돌봄 근로시간 단축을 허용하지 아니할 수 있다.`,
					[
						`가족돌봄 근로시간 단축 개시 예정일의 전날까지 ${company}에서 계속 근로한 기간이 6개월 미만인 경우`,
						`{companyGa} 직업안정기관에 구인신청을 하고 14일 이상 대체인력을 채용하기 위하여 노력하였으나 대체인력을 채용하지 못한 경우. 다만, 직업안정기관의 장의 직업소개에도 불구하고 정당한 이유 없이 2회 이상 채용을 거부한 경우는 가족돌봄휴직을 허용해야 한다.`,
						`가족돌봄등 근로시간 단축을 신청한 사원의 업무 성격상 근로시간을 분할하여 수행하기 곤란하거나 그 밖에 가족돌봄등 근로시간 단축이 정상적인 사업 운영에 중대한 지장을 초래하는 경우로서 {companyGa} 이를 증명하는 경우`,
						`가족돌봄등 근로시간 단축 종료일부터 2년이 지나지 않은 사원이 신청한 경우`,
						`기타 관계법령이 정한 사유가 발생한 경우`,
					],
					`${companyGa} 가족돌봄등 근로시간 단축을 허용하지 아니하는 경우에는 해당 사원에게 그 사유를 서면으로 통보하고, 휴직을 사용하게 하거나 그 밖의 조치를 통하여 지원할 수 있는지를 해당 사원과 협의해야 한다.`,
					`${companyGa} 제1항에 따라 해당 사원에게 근로시간 단축을 허용하는 경우 단축 후 근로시간은 주당 15시간 이상이어야 하고 30시간을 넘어서는 아니 된다.`,
					`근로시간 단축의 기간은 1년 이내로 한다. 다만, 제1항 제1호부터 제3호까지의 어느 하나에 해당하는 사원은 합리적 이유가 있는 경우에 추가로 2년의 범위 안에서 근로시간 단축의 기간을 연장할 수 있다.`,
					`${companyUn} 근로시간 단축을 이유로 해당 근로자에게 해고나 그 밖의 불리한 처우를 하여서는 아니 된다.`,
					`${companyUn} 사원의 근로시간 단축기간이 끝난 후에 그 사원을 근로시간 단축 전과 같은 업무 또는 같은 수준의 임금을 지급하는 직무에 복귀시켜야 한다.`,
					`가족돌봄 등을 위한 근로시간 단축(이하 “가족돌봄등근로시간단축”이라 한다)을 신청하려는 사원은 가족돌봄등근로시간단축을 시작하려는 날(이하 “가족돌봄등단축개시예정일”이라 한다)의 30일 전까지 가족돌봄등근로시간단축 신청사유, 가족돌봄등단축개시예정일, 가족돌봄등근로시간단축을 종료하려는 날(이하 “가족돌봄등단축종료예정일”이라 한다), 가족돌봄등근로시간단축 기간 중 근무개시시각ㆍ근무종료시각, 가족돌봄등근로시간단축 신청 연월일 및 신청인 등에 대한 사항을 적은 문서(전자문서를 포함한다)를 ${company}에게 제출해야 한다.`,
					`${companyUn} 사원이 제8항에 따른 기한이 지난 후에 가족돌봄등근로시간단축을 신청한 경우 그 신청일부터 30일 이내로 가족돌봄등근로시간단축 개시일을 지정하여 가족돌봄등근로시간단축을 허용해야 한다.`,
					`${companyUn} 제8항 및 제9항에 따라 사원이 가족돌봄등근로시간단축을 신청했음에도 불구하고 그 신청일부터 30일 이내에 ${company}로부터 가족돌봄등근로시간단축 허용 여부에 대해 통지를 받지 못한 경우에는 사원이 신청한 내용대로 {companyGa} 가족돌봄등근로시간단축을 허용한 것으로 본다.`,
					`사원이 제8항 및 제9항에 따라 가족돌봄등근로시간단축을 신청한 경우 가족의 질병 등 해당 사유를 증명할 수 있는 서류의 제출해야 하며, 기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate >= `2024-01-05`
						? null
						: [
								`2024년 01월05일 신설`,
								`변경후 : 신설`,
								`개정이유 : 남녀고용평등과 일가정 양립 지원에 관한 법률 제22조의 3 (가족돌봄 등을 위한 근로시간 단축) 반영`,
						  ],
			},
			{
				title: `육아휴직`,
				content: [
					`${companyUn} 사원이 임신 중인 여성 근로자가 모성을 보호하거나 만 8세 이하 또는 초등학교 2학년 이하의 자녀(입양한 자녀를 포함한다)를 양육하는 사원에게 무급의 육아휴직을 허용해야 한다.`,
					`사원은 육아휴직을 2회에 한정하여 나누어 사용할 수 있다. 이 경우 임신 중인 여성 근로자가 모성보호를 위하여 육아휴직을 사용한 횟수는 육아휴직을 나누어 사용한 횟수에 포함하지 아니한다.`,
					`${companyUn} 육아휴직을 시작하려는 날의 전날까지 해당 사업에서 계속 근로한 기간이 6개월 미만인 사원에게 육아휴직을 허용하지 아니할 수 있다.`,
					`육아휴직 기간은 1년 이내로 한다.`,
					`${companyUn} 육아휴직을 이유로 해고나 그 밖의 불리한 처우를 하지 않으며 특히 육아휴직기간에는 해고하지 아니한다. 다만, 사업을 계속할 수 없는 경우에는 그러하지 아니하다.`,
					`${companyUn} 사원이 육아휴직을 사용할 경우 고용보험법령이 정하는 육아휴직급여를 받을 수 있도록 증빙서류를 제공하는 등 적극 협조한다.`,
					`기간제 사원 또는 파견 사원의 육아휴직 기간은 「기간제 및 단시간근로자 보호 등에 관한 법률」 제4조에 따른 사용기간 또는 「파견근로자 보호 등에 관한 법률」 제6조에 따른 근로자파견기간에서 제외한다.`,
					`사원은 휴직개시예정일의 30일 전까지 육아휴직 대상인 영유아의 성명, 생년월일(임신 중인 여성근로자가 육아휴직을 신청하는 경우에는 영유아의 성명을 적지 않으며, 생년월일 대신 출산 예정일을 적어야 한다), 휴직개시예정일, 육아휴직을 종료하려는 날(이하 “휴직종료예정일”이라 한다), 육아휴직 신청 연월일, 신청인 등에 대한 사항을 신청서에 적어 ${company}에게 제출해야 한다.`,
					`제7항에도 불구하고 다음 각 호의 어느 하나에 해당하는 경우에는 휴직개시예정일 7일 전까지 육아휴직을 신청할 수 있다.`,
					[
						`임신 중인 여성 근로자에게 유산 또는 사산의 위험이 있는 경우`,
						`출산 예정일 이전에 자녀가 출생한 경우`,
						`배우자의 사망, 부상, 질병 또는 신체적ㆍ정신적 장애나 배우자와의 이혼 등으로 해당 영유아를 양육하기 곤란한 경우`,
					],
					`${companyUn} 사원이 제7항에 따른 기한이 지난 뒤에 육아휴직을 신청한 경우에는 그 신청일부터 30일 이내에, 제8항에 따른 기한이 지난 뒤에 육아휴직을 신청한 경우에는 그 신청일부터 7일 이내에 육아휴직 개시일을 지정하여 육아휴직을 허용해야 한다.`,
					`${companyUn} 육아휴직을 신청한 사원에게 임신 중인 사실을 증명할 수 있는 서류나 해당 자녀의 출생 등을 증명할 수 있는 서류의 제출을 요구할 수 있다.`,
					`육아휴직 중인 사원 중 임신 중인 경우 유산 또는 사산한 경우 임신중이 아닌 경우, 그 영유아가 사망한 경우 또는 그 영유아와 동거하지 않게 된 경우(영유아의 양육에 기여하지 않는 경우로 한정한다)에는 그 사유가 발생한 날부터 7일 이내에 그 사실을 ${company}에게 알려야 한다.`,
					`${companyUn} 제11항에 따라 육아휴직 중인 사원으로부터 영유아의 사망 등에 대한 사실을 통지받은 경우에는 통지받은 날부터 30일 이내로 근무개시일을 지정하여 그 사원에게 알려야 한다.`,
					`사원은 다음 각 호의 어느 하나에 해당하는 날에 육아휴직이 끝난 것으로 본다.`,
					[
						`제11항에 따라 통지를 하고 제12항에 따른 근무개시일을 통지받은 경우에는 그 근무개시일의 전날`,
						`제11항에 따라 통지를 하였으나 제12항에 따른 근무개시일을 통지받지 못한 경우에는 제11항의 통지를 한 날부터 30일이 되는 날`,
						`제11항에 따른 통지를 하지 아니한 경우에는 영유아의 사망 등의 사유가 발생한 날부터 37일이 되는 날`,
					],
					`육아휴직 중인 사원이 새로운 육아휴직을 시작하거나 「근로기준법」 제74조에 따른 출산전후휴가 또는 제63조에 따른 육아기 근로시간 단축(이하 “육아기 근로시간 단축”이라 한다)을 시작하는 경우에는 그 새로운 육아휴직, 출산전후휴가 또는 육아기 근로시간 단축 개시일의 전날에 육아휴직이 끝난 것으로 본다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate >= `2021-11-19`
						? null
						: [
								`2021년 11월19일 개정`,
								`변경전 : ① ${companyUn} 만 8세 이하 또는 초등학교 2학년 이하의 자녀(입양한 자녀를 포함한다)를 양육하는 사원에게 무급의 육아휴직을 허용해야 한다.`,
								`변경후 : ① ${companyUn} 사원이 임신 중인 여성 근로자가 모성을 보호하거나 만 8세 이하 또는 초등학교 2학년 이하의 자녀(입양한 자녀를 포함한다)를 양육하는 사원에게 무급의 육아휴직을 허용해야 한다.`,
								`개정이유 : 남녀고용평등과 일가정 양립 지원에 관한 법률 제19조 (육아휴직) 제1항 개정`,
						  ],
			},
			{
				title: `육아기 근로시간 단축`,
				content: [
					`${companyUn} 사원이 만 8세 이하 또는 초등학교 2학년 이하의 자녀를 양육하기 위하여 근로시간의 단축(이하 “육아기 근로시간 단축”이라 한다)을 신청하는 경우에 이를 허용해야 한다. 다만, 다음 각 호의 경우에는 그러하지 아니하다.`,
					[
						`단축개시예정일의 전날까지 해당 사업에서 계속 근로한 기간이 6개월 미만인 사원이 신청한 경우`,
						`${companyGa} 「직업안정법」 제2조의2제1호에 따른 직업안정기관(이하 “직업안정기관”이라 한다)에 구인신청을 하고 14일 이상 대체인력을 채용하기 위하여 노력하였으나 대체인력을 채용하지 못한 경우. 다만, 직업안정기관의 장의 직업소개에도 불구하고 정당한 이유 없이 2회 이상 채용을 거부한 경우는 제외한다.`,
						`육아기 근로시간 단축을 신청한 사원의 업무 성격상 근로시간을 분할하여 수행하기 곤란하거나 그 밖에 육아기 근로시간 단축이 정상적인 사업 운영에 중대한 지장을 초래하는 경우로서 {companyGa} 이를 증명하는 경우`,
					],
					`${companyGa} 전항의 근로시간 단축을 허락하지 아니하는 경우에는 해당 사원에게 그 사유를 서면으로 통보하고 육아휴직을 사용하도록 하고, 그 밖의 조치를 통하여 지원할 수 있는지를 해당 사원과 협의하여야 한다`,
					`${companyGa} 제1항의 규정에 따라 육아기 근로시간 단축을 허용하는 경우에 단축 후 근로시간은 주당 15시간 이상 35시간 이하로 한다.`,
					`육아기 근로시간 단축의 기간은 1년 이내로 한다. 다만, 제62조에 따라 육아휴직을 신청할 수 있는 사원이 육아휴직 기간 중 사용하지 아니한 기간이 있으면 그 기간을 가산한 기간 이내로 한다.`,
					`${companyUn} 육아기 근로시간 단축을 이유로 해당 사원에게 해고나 그 밖의 불리한 처우를 하지 아니한다.`,
					`${companyUn} 육아기 근로시간 단축기간이 끝난 사원에게 육아기 근로시간 단축 전과 같은 업무 또는 같은 수준의 임금을 지급하는 직무에 복귀시킨다.`,
					`${companyUn} 사원이 육아기 근로시간 단축을 사용할 경우 고용보험법령이 정하는 육아기 근로시간 단축 급여를 받을 수 있도록 증빙서류를 제공하는 등 적극 협조한다`,
					`사원은 육아기 근로시간 단축을 시작하려는 날(이하 “단축개시예정일”이라 한다)의 30일 전까지 육아기 근로시간 단축기간 중 양육하는 대상인 자녀의 성명, 생년월일, 단축개시예정일, 육아기 근로시간 단축을 종료하려는 날(이하 “단축종료예정일”이라 한다), 육아기 근로시간 단축 중 근무개시시각 및 근무종료시각, 육아기 근로시간 단축 신청 연월일, 신청인 등에 대한 사항을 적은 문서(전자문서를 포함한다)를 ${company}에게 제출해야 한다.`,
					`${companyUn} 사원이 제8항에 따른 기한이 지난 뒤에 육아기 근로시간 단축을 신청한 경우에는 그 신청일부터 30일 이내로 육아기 근로시간 단축 개시일을 지정하여 육아기 근로시간 단축을 허용해야 한다.`,
					`${companyUn} 육아기 근로시간 단축을 신청한 사원에게 해당 자녀의 출생 등을 증명할 수 있는 서류의 제출을 요구할 수 있다.`,
					// `사원은 육아기 근로시간 단축을 나누어 사용할 수 있다. 이 경우 나누어 사용하는 1회의 기간은 3개월(근로계약기간의 만료로 3개월 이상 근로시간 단축을 사용할 수 없는 기간제근로자에 대해서는 남은 근로계약기간을 말한다) 이상이 되어야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				// amended:
				// 	serviceStartDate >= `2021-11-19`
				// 		? null
				// 		: [
				// 				`2021년 11월19일 개정`,
				// 				`변경전 : ① 사원은 육아휴직을 2회에 한정하여 나누어 사용할 수 있다.`,
				// 				`변경후 : ① 사원은 육아휴직을 2회에 한정하여 나누어 사용할 수 있다. 이 경우 임신 중인 여성 근로자가 모성보호를 위하여 육아휴직을 사용한 횟수는 육아휴직을 나누어 사용한 횟수에 포함하지 아니한다.`,
				// 				`개정이유 : 남녀고용평등과 일가정 양립 지원에 관한 법률 제19조의 4 (육아휴직과 육아기 근로시간 단축의 사용형태) 제1항 개정`,
				// 		  ],
			},
			{
				title: `육아기 근로시간 단축 중 근로조건 등`,
				content: [
					`${companyUn} 육아기 근로시간을 단축하는 사원에게 단축된 근로시간에 비례하여 적용하는 근로조건을 제외하고는 육아기 근로시간의 단축을 이유로 해당 사원의 근로조건을 불리하게 처우하지 아니한다.`,
					`${companyUn} 육아기 근로시간을 단축한 사원의 근로조건은 ${companyWa} 사원간의 서면으로 정한다.`,
					`${companyUn} 육아기 근로시간을 단축한 사원에게 단축된 근로시간 외에 연장근로를 요구할 수 없다. 다만, 그 사원이 서면으로 청구하는 경우에는 주 12시간 이내에서 연장근로를 할 수 있다.`,
					`${companyUn} 육아기 근로시간을 단축한 사원의 평균임금은 그 사원의 육아기 근로시간 단축기간을 제외하고 산정한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `육아휴직과 육아기 근로시간 단축의 사용`,
				content: [
					`사원은 육아휴직을 2회에 한정하여 나누어 사용할 수 있다. 이 경우 임신 중인 여성 근로자가 모성 보호를 위하여 육아휴직을 사용한 횟수는 육아휴직을 나누어 사용한 횟수에 포함하지 아니한다.`,
					`사원은 육아기 근로시간 단축을 나누어 사용할 수 있다. 이 경우 나누어 사용하는 1회의 기간은 3개월(근로계약기간의 만료로 3개월 이상 근로시간 단축을 사용할 수 없는 기간제 사원에 대해서는 남은 근로계약기간을 말한다) 이상이 되어야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},

			{
				title: `개인휴직 및 군복무휴직`,
				content: [
					`${companyUn} 병역법 기타 법령에 의하여 징∙소집된 사원에게 군복무휴직을 허락해야 한다.`,
					`제1항의 휴직기간은 그 복무기간이 만료될 때까지로 한다.`,
					`${companyUn} 사원이 개인 휴직(질병, 학업, 가족돌봄휴직을 초과하는 가족돌봄 휴직 등)을 신청하는 경우 심사를 통해 개인 휴직을 허락할 수 있다.`,
					`제1항과 제3항에 의한 휴직기간은 계속 근로 연수에 산입하지 않는다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate >= `2022-08-04`
						? null
						: [
								`2022년 08월04일 개정`,
								`변경전 : (군복무휴직)`,
								`③ 제1항에 의한 휴직기간은 계속 근로 연수에 산입하지 않는다.`,
								`변경후 : (개인휴직 및 군복무휴직)`,
								`③ ${companyUn} 사원이 개인 휴직(질병, 학업, 가족돌봄휴직을 초과하는 가족돌봄 휴직 등)을 신청하는 경우 심사를 통해 개인 휴직을 허락할 수 있다.`,
								`④ 제1항과 제3항에 의한 휴직기간은 계속 근로 연수에 산입하지 않는다.`,
								`⑤ 기타 필요한 사항은 별도로 정할 수 있다.`,
								`* 개정이유 : 개인휴직이 없거나 불분명한 경우 효율적인 인사관리를 위해 이를 규정하되, 개인휴직기간은 군복무휴직과 같이 퇴직금계산을 위한 계속 근로 연수에서 제외된는 노동부해석(임금복지과-1294, 2010. 6. 11.)을 참조하여 내용을 개정함.`,
								`* 개정시 주의사항 : 동 조항의 개인휴직이 없었던 경우에 개인휴직을 부여하는 것이라면 동 기간을 계속근로기간에서 제외하는 것이 직원에게 불이익하지 않은 것으로 보아 직원 과반수 이상의 의견청취로 개정할 수도 있으나, 개정전에 개인휴직이 있었던 경우 이를 계속근로기간에서 제외하는 경우라면 근로자에게 불이익할 수 있으므로 직원 과반수의 동의 절차가 필요할 수 있으니 노동법 전문가인 공인노무사와 상의할 것을 권고드립니다.`,
						  ],
			},
			{
				title: `요양휴직`,
				content: [
					`${companyUn} 업무 외 부상 또는 질병으로 15일 이상 가료 또는 휴양이 필요한 사원에게 무급의 요양휴직을 허락할 수 있다.`,
					`제1항의 휴직기간은 회계년도 단위로 1월 이내로 한다.`,
					`휴직기간은 근속기간에 통산한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `강제휴직`,
				content: [
					`${companyUn} 다음 각 호의 하나에 해당하는 사원에게 무급의 강제휴직을 명령할 수 있다.`,
					[
						`근로 금지의 사유가 발생한 질병 사원`,
						`천재지변, 전시사변 또는 이에 준하는 사유로 인하여 사원의 생사 또는 소재가 불명한 경우`,
					],
					`${companyUn} 제1항 제1호의 근로 금지의 사유가 발생한 질병 사원은 다음 각 호의 사원을 말한다.`,
					[
						`전염될 우려가 있는 질병에 걸린 사원. 다만, 전염을 예방하기 위한 조치를 한 경우에는 그러하지 아니하다.`,
						`조현병, 마비성 치매에 걸린 사원`,
						`심장∙신장∙폐 등의 질환이 있는 사람으로서 근로에 의하여 병세가 악화될 우려가 있는 사원`,
						`제1호부터 제3호까지의 규정에 준하는 질병으로서 고용노동부장관이 정하는 질병에 걸린 사원`,
					],
					`${companyUn} 제1항 제1호에 따라 근로를 금지하거나 근로를 다시 시작하도록 하는 경우에는 미리 의사(${companyGa} 지정한 경우에는 ${companyGa} 지정한 의사)의 의견을 들어야 한다.`,
					`제1항의 휴직기간은 회계년도 단위로 2월 이내로 한다.`,
					`휴직기간은 근속기간에 통산한다.`,
					`제1항제1호의 근로금지사유 중 전염병에 걸림으로 인하여 그 사원이 출근할 수가 없거나 다른 사원의 건강에 영향을 미칠 우려가 있기 때문에 행정지도에 따라 자가 격리 중에 있는 사원은 휴업수당을 지급하고, 본 조항을 적용하지 않는다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `경영위기휴직`,
				content: [
					`${company}의 경영상 긴박한 필요성이 있는 경우에는 무급의 경영위기휴직을 부여할 수 있으며, 무급의 경영위기휴직의 부여 기간은 양당사자의 합의로 정할 수 있다.`,
					`제1항의 휴직기간은 3개월 이내로 하되, 당사자간의 합의로 그 기간을 연장할 수 있다.`,
					`휴직기간은 근속기간에 통산한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `휴직의 신청과 허가`,
				content: [
					`상기 휴직 조항에 휴직 신청일이 정해진 경우는 그에 따르고 그렇지 않은 휴직을 사용하는 사원은 휴직개시일로부터 3일 이전에 객관적인 소명자료를 첨부한 서면의 휴직계를 제출해야 한다.`,
					`${companyUn} 사원이 신청한 소정휴직의 사용이 사업상 지장이 없는 경우에는 신청된 휴직을 휴직개시일 전일 종업시각까지 허락해야 한다. 단, 사원이 신청한 소정휴직의 사용이 ${company}의 사업운영에 막대한 지장이 초래될 개연성이 있다면 ${companyUn} 소정휴직의 사용을 허락하지 않을 수 있다.`,
					`${companyUn} 휴직을 사용하는 사원은 반드시 ${company}의 허가를 늦어도 휴직개시일 전일 종업시각까지 얻어야 한다. 만약 그렇지 않으면 무단결근으로 본다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `복직`,
				content: [
					`휴직자가 휴직 사유소멸로 복직을 원할 때는 복직원을 제출해야 한다.`,
					`휴직기간의 만료 또는 휴직사유의 종료 후 5일 이내에 복직절차를 취하지 아니하면 휴직기간 기산일에 퇴직한 것으로 본다.`,
					`${companyUn} 복직절차를 갖춘 사람에 대해 즉시 복직 조치해야 한다. 다만, ${company}의 사정에 따라 다른 업무에 종사하도록 할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `직권면직의 사유`,
				content: [
					`${companyUn} 다음 각 호의 하나에 해당되는 사원을 직권으로 면직시킨다.`,
					[
						`무단으로 2주 소정근로일수의 전체를 결근한 사원`,
						`계속하여 10일 이상을 무단으로 결근한 사원`,
						`${company}의 임원에 취임한 사원`,
						`정당한 이유 없이 연봉계약의 갱신을 1개월 이상 지연한 연봉제 사원`,
						`제68조의 강제휴직을 정당한 이유없이 7일 이상 거부한 사원`,
						`제39조 제1호 내지 제8호의 채용 제한 사유에 해당될 때`,
					],
					`${companyUn} 형사사건과 관련된 다음 각 호의 사원을 직권으로 면직시킨다.`,
					[
						`금고 이상의 형이 확정되어 근로를 제공할 수 없는 사원`,
						`형사사건으로 기소되어 근로를 제공할 수 없는 사원`,
					],
					`${companyUn} 건강상태와 관련된 다음 각 호의 사원을 직권으로 면직시킨다.`,
					[
						`요양·강제휴직기간 만료 후에도 휴직사유가 존속하는 사원`,
						`건강진단 결과 취업부적격자로 의사의 판정을 받은 경우`,
						`신체 또는 정신상 장애로 직무를 감당할 수 없다고 객관적으로 판단된 사원`,
					],
					`${companyUn} 자격과 관련된 다음 각 호의 사원을 직권으로 면직시킨다.`,
					[
						`채용의 원인이었던 보유 자격증의 유효기간이 만료된 사원`,
						`채용의 원인이었던 보유 자격증이 취소된 사원`,
					],
					`${companyUn} 휴직과 관련된 다음 각 호의 사원을 직권으로 면직시킨다.`,
					[
						`휴직기간의 만료 후 5일 내에 복직하지 않을 때`,
						`휴직사유의 종료 후 5일 내에 복직하지 않을 때`,
						`휴직자가 ${company}의 허가없이 타 회사나 기업에 취업한 때`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `직권면직의 처분`,
				content: [
					`${companyUn} 사원에 대한 면직처분은 근로관계종료일 이전에 사원에게 서면으로 도달해야 한다.`,
					`${companyUn} 직권면직의 예고를 적어도 30일 전에 해야 한다.`,
					`${companyGa} 직권면직을 즉시 할 경우에는 30일분의 통상임금을 해고예고수당으로 지급한다.`,
					`제2항과 제3항은 계속 근로 연수 3개월 이하의 사원에게는 적용하지 아니한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `근로관계의 자동소멸 사유`,
				content: [
					`다음 각 호의 하나에 해당되는 사원과 ${company}의 근로계약은 자동으로 해지된다.`,
					[`근로계약기간의 만료`, `정년의 도달`, `사원의 사망`],
					`제1항의 근로관계가 종료되는 경우 ${companyUn} 별도의 처분을 하지 않는다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `정년`,
				content: [
					company === `주식회사 세종씨이엠`
						? `모든 사원의 정년은 만65세에 도달하는 달의 말일로 한다.`
						: `모든 사원의 정년은 만60세에 도달하는 달의 말일로 한다.`,
					`${companyUn} 당해년도 정년에 이른 자에 대해 매년 초 정년일자를 서면으로 예고해야 한다.`,
					`${companyGa} 업무상 특별히 필요하다고 인정한 자는 촉탁직 사원으로 위촉할 수 있다.`,
					`제3항에 따라 촉탁직으로 위촉한 사원 중 정년퇴직일로부터 6개월 이내 재고용한 사원은 촉탁 계약기간을 2년간 보장한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `사직원의 제출`,
				content: [
					`퇴직하고자 하는 사원은 퇴직효력발생일로부터 2개월 ~ 1개월 전에 사직원을 제출한다.`,
					`${company} 퇴직효력발생일로부터 1개월 이내에 제출된 사직원으로 인하여 발생된 손해는 당해 사원이 부담한다.`,
					`제1항의 규정에 따라 사직원을 제출한 사원은 퇴직효력발생일까지 종전의 직무에 종사해야 한다. 다만, 사직원을 제출한 후 1개월이 경과한 경우에는 그러하지 아니한다.`,
					`사직원을 제출하지 아니하고 퇴직하는 경우에는 개인사유에 의한 임의퇴직으로 간주한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `징계해고사유`,
				content: [
					`${companyUn} 근로관계의 성립기반을 훼손하는 다음 각 호의 행위를 한 사원을 해고할 수 있다.`,
					[
						`경력∙학력의 사칭`,
						`입사서류의 위조`,
						`기타 입사과정의 제 서류에 신원, 자격 등 중요한 사항을 허위로 기재함으로써 채용평가 및 심사요소 중 주요사항을 속이거나, 사기 또는 부정한 방법으로 채용되었거나 채용케 하였음이 판명되었을 경우`,
						`업무수행에 필요한 능력, 건강 등을 기망하거나 누락하여 신고함으로써 채용된 경우`,
						`업무의 정상적인 수행을 저해하는 질병 등을 기망하거나 누락하여 신고함으로써 채용된 경우`,
					],
					`${companyUn} 근로계약상 근로제공의 채무를 불이행하는 다음 각 호의 행위를 한 사원을 해고할 수 있다.`,
					[
						`${company}의 책임 없는 사유로 소정근로일을 연속한 5일 이상 무단결근`,
						`월 중 소정근로일의 7일 이상 무단결근`,
						`정당한 사유 없는 지각·조퇴·외출의 합계가 월 10회 이상인 경우`,
						`정직기간 종료 후 3일 이내 출근하지 아니한 경우`,
						`근무시간 중 사적인 음주 행위가 2회 이상인 경우`,
					],
					`${companyUn} 직장의 규범적 질서를 위반하는 다음 각 호의 행위를 한 사원을 해고할 수 있다.`,
					[
						`허위보고가 2회 이상인 경우`,
						`사원 본인 월급 대비 20% 미만의 경미한 ${company}의 재산상 손실을 발생시킨 경우가 3회 이상이거나 사원 본인 월급 대비 50%이상 100% 미만의 ${company}의 재산상 손실을 2회 이상 발생시킨 경우 또는 사원 본인 월급대비 100%이상의 ${company}의 재산상 손해를 입힌 경우`,
						`${company}의 명예 실추`,
						`공문서 혹은 서류의 기록 등을 고의적인 날조, 은폐, 제거, 삭제, 파기행위 또는 시도행위`,
						`제1심에서 집행유예 6개월 이상의 형사처벌을 받은 사원`,
						`정당한 사유없이 안전관리상의 지시, 명령에 위반하거나 불복한 사원`,
						`각종 재해사고의 은폐, 허위보고, 태만으로 안전사고 사후처리를 지연시킨 사원`,
						`${company}의 법인인감, 사용인감, 법인통장 등을 개인적인 목적으로 사용한 사원`,
						`자신의 임금수준 및 임금구성항목을 외부 또는 타인에게 알리거나 동료사원의 임금수준 및 임금구성항목을 알려고 한 행위가 2회 이상인 경우`,
						`거래처의 협조관계나 거래관계를 단절시킨 원인이 된 경우가 2회 이상인 경우`,
						[
							`복무의무 제1항의 권고의무의 각 호를 합한 위반 행위가 6회이상인 때`,
							`복무의무 제2항 제1호 내지 제8호의 강행의무의 각 호를 합한 위반 행위가 5회이상인 때`,
							`복무의무 제2항 제9호 내지 제16호의 강행의무의 각 호를 합한 위반 행위가 3회이상인 때`,
							`복무의무 제3항 제1호와 제2호의 금지행위의 위반 행위가 3회이상인 때`,
							`복무의무 제3항 제3호의 금지행위의 위반 행위가 2회이상인 때`,
						],
						[
							`${company} 규정 위반`,
							`${company} 업무 감사`,
							`${company} 외부 감사`,
							`국세청의 세무조사`,
							`고용노동부∙사회보험기관의 현장조사`,
							`기타 정부관계기관의 각종 조사`,
						],
						`고용보험법 시행규칙 별표 2의 수급자격이 제한되지 아니하는 정당한 이직사유에 해당하거나 이에 준하는 사유가 아님에도 불구하고 근로계약서 및 임금계약서 작성을 거부하거나 제출하지 아니하는 행위가 2회이상 발생한 경우`,
						`고용보험법 시행규칙 별표 2의 수급자격이 제한되지 아니하는 정당한 이직사유에 해당하거나 이에 준하는 사유가 아님에도 불구하고 ${company}의 정당한 인사명령을 거부하는 행위가 2회이상 발생한 경우`,
					],
					`${companyUn} 직무상 의무를 위반하는 다음 각 호의 행위를 한 사원을 해고할 수 있다.`,
					[
						`직무상 법정 교육훈련을 회피하여 이수하지 못한 경우`,
						`근무시간 중 2회이상 개인적인 업무를 수행한 경우`,
						`${company}의 고객을 상대로 개인적인 영업을 시도한 사원`,
						`직무상 기한을 3회이상 준수하지 못한 경우`,
						`본 규칙 제92조의 건강검진을 받지 않는 경우가 3회 이상인 경우`,
					],
					`${companyUn} 직장의 윤리적 질서를 위반하는 다음 각 호의 행위를 한 사원을 해고할 수 있다.`,
					[
						`임원∙상사∙동료사원∙부하사원에 대한 언어폭력∙욕설∙폭언`,
						`임원∙상사∙동료사원∙부하사원에 대한 험담∙모욕∙성희롱`,
						`임원∙상사∙동료사원∙부하사원에 대한 직장 내 괴롭힘이 2회 이상인 경우`,
						`임원∙상사∙동료사원∙부하사원에 대한 협박`,
						`임원∙상사∙동료사원∙부하사원에 대한 허위사실 또는 유언비어의 유포`,
						`불륜∙간통∙성매매 등 사회적 비난을 받을 수 있는 사생활을 한 경우`,
					],
					`${companyUn} 강행법규를 위반하는 다음 각 호의 행위를 한 사원을 해고할 수 있다.`,
					[
						`직장 상사 또는 동료사원을 쌍방 폭행하는 행위가 2회이상인 경우`,
						`직장 상사 또는 동료사원을 일방적으로 폭행하는 행위`,
						`직장 동료에게 상해를 가하는 행위`,
					],
					`${companyUn} 다음 각 호의 직장절도행위를 한 사원을 해고할 수 있다.`,
					[
						`금품과 향응의 요구∙수수`,
						`고의적인 장부기록의 조작으로 공금을 착복∙유용∙횡령`,
						`${company}의 영업비밀(거래처, 수가, 고객정보, 거래내역 등), 대외비 및 기타 정보를 경쟁관계에 있는 다른 사업자 및 개인 등에 제공`,
						`${company}의 영업비밀(거래처, 수가, 고객정보, 거래내역 등), 대외비 및 기타 정보를 포털사이트 또는 언론기관 등에 유출`,
					],
					`${companyUn} 다음 각 호의 사원을 해고할 수 있다.`,
					[
						`사회통념상 담당업무에 요구되는 객관적인 업무능력의 부족`,
						`3개월의 업무수행능력이 동일·유사한 업무를 담당하는 사원의 평균 업무수행능력(영업직은 영업 실적)의 50% 이하로 떨어진 경우`,
						`고용보험법 시행규칙 별표 2의 수급자격이 제한되지 아니하는 정당한 이직사유에 해당하거나 이에 준하는 사유가 아님에도 불구하고 부서 간 업무량의 변화 또는 특정 부서 인원의 이직으로 인해 같은 직무수준에서 이루어지는 배치전환을 거부하는 경우`,
						`고용보험법 시행규칙 별표 2의 수급자격이 제한되지 아니하는 정당한 이직사유에 해당하거나 이에 준하는 사유가 아님에도 불구하고 업무량이 전반적으로 감소된 경우 잉여인원을 조정하는 배치전환을 거부하는 경우`,
						`고용보험법 시행규칙 별표 2의 수급자격이 제한되지 아니하는 정당한 이직사유에 해당하거나 이에 준하는 사유가 아님에도 불구하고 교대조 사이의 직무 배치전환을 거부하는 사원`,
						`수습기간에 대한 인사고과에서 S등급, A등급, B등급, C등급, D등급 중 C등급이나 D등급을 받고 ${companyGa} 사원의 업무능력 향상을 위한 업무를 지시하거나, 교육을 지정했음에도 이를 거부한 경우`,
						`정기 인사고과에서 S등급, A등급, B등급, C등급, D등급 중 D등급을 연속하여 4회를 받았을 때`,
					],
					`${companyUn} 사회통념상 근로관계를 계속할 수 없는 기타의 중대한 사유가 있는 사원을 해고할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `즉시해고사유`,
				content: [
					`${companyUn} 다음 각 호의 하나에 해당하는 사원을 즉시 해고한다.`,
					[
						`납품업체로부터 금품 또는 향응을 제공받고 불량품을 납품 받아 업무에 차질을 가져온 경우`,
						`${company}의 영업비밀, 대외비 및 기타 정보를 경쟁관계에 있는 다른 사업자 및 개인 등에 제공하여 사업에 지장을 가져온 경우`,
						`허위사실을 날조·유포하거나 불법 집단행동을 주도하여 사업에 막대한 지장을 가져온 경우`,
						`직책을 이용하여 공금을 착복·장기유용·횡령하거나 배임한 경우`,
						`${company} 소유의 물품을 절취 또는 불법 반출한 경우`,
						`인사·경리·회계담당 사원이 본인 또는 다른 사원의 근무상황 실적을 조작 하거나 허위서류 등을 작성하여 사업에 손해를 끼친 경우`,
						`사업장의 기물을 고의로 파손하여 업무에 막대한 지장을 가져온 경우`,
						`기타 사회통념상 고의로 사업에 막대한 지장을 가져오거나 재산 상 손해를 끼쳤다고 인정되는 경우`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `해고예고의 사유`,
				content: [
					`${companyUn} 다음 각 호의 하나에 해당하는 해고를 할 경우에는 예고를 해야 한다.`,
					[`징계해고`, `경영상해고`, `직권면직`],
					`${companyUn} 다음 각 호의 하나에 해당하는 사원을 해고할 경우에는 예고를 하지 않는다.`,
					[
						`사원이 계속 근로한 기간이 3개월 미만인 경우`,
						`천재∙사변, 그 밖의 부득이한 사유로 사업을 계속하는 것이 불가능한 경우`,
						`사원이 고의로 사업에 막대한 지장을 초래하거나 재산상 손해를 끼친 경우로서 고용노동부령으로 정하는 사유에 해당하는 경우`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
		];

		// if (['대한민국', '한국', '일본'].includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles.includes(section.title)
			);
		}

		return baseSections;
	}, [serviceStartDate, company, companyUn, companyGa, companyWa]);

	useEffect(() => {
		setSectionCount(sections.length);
	}, [sections, setSectionCount]);

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className='jang sectionup'>제5장 사원 신분제도</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={
							index +
							1 +
							previousSectionCount1 +
							previousSectionCount2 +
							previousSectionCount3 +
							previousSectionCount4
						}
						title={section?.title}
						content={section?.content}
						isOpen={!!openSections[section?.title]}
						toggleOpen={() => toggleSection(section?.title)}
						amended={section?.amended}
					/>
				</div>
			))}
		</>
	);
}
