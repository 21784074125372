import React from 'react'
import { Table } from 'semantic-ui-react'

export default function ClientReqDLCWageTableTimeDetail({
  absentDays,
  absentPaidHoliDays,
  absentTimes,
  handelInputChange,
  centerStyle,
  inputTimeStyle,
  plusMonthOWT,
  plusMonthNWT,
  plusMonthHoliBasicTime,
  plusMonthOWTHoli,
  plusMonthNWTHoli,
  usedAnnualLeaves,
  numOfWorkers,
  weekLBTime,
  isExceptTimeLawWorker,
}) {
  
  return (
    <>
      {isExceptTimeLawWorker
        ?
          <Table.Row>
            <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = "plusMonthOWT"  value = {plusMonthOWT} onChange= {(e) => handelInputChange(e)} autoComplete='off' /></Table.Cell>
            <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = "plusMonthNWT" value = {plusMonthNWT} onChange= {(e) => handelInputChange(e)} autoComplete='off'  /></Table.Cell>
            <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = 'absentDays' value = {absentDays} onChange= {(e) => handelInputChange(e)} autoComplete='off'  /></Table.Cell>
            <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = 'absentTimes' value = {absentTimes} onChange= {(e) => handelInputChange(e)} autoComplete='off'  /></Table.Cell>
            {(numOfWorkers >= 5 && weekLBTime >= 15) ?
            <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = 'usedAnnualLeaves' value = {usedAnnualLeaves} onChange= {(e) => handelInputChange(e)} autoComplete='off'/></Table.Cell> : <Table.Cell></Table.Cell>}    
            <Table.Cell></Table.Cell> 
            <Table.Cell></Table.Cell> 
            <Table.Cell></Table.Cell> 
            <Table.Cell></Table.Cell> 
          </Table.Row>
        :
          <Table.Row>
            <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = "plusMonthOWT"  value = {plusMonthOWT} onChange= {(e) => handelInputChange(e)} autoComplete='off' /></Table.Cell>
            <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = "plusMonthNWT" value = {plusMonthNWT} onChange= {(e) => handelInputChange(e)} autoComplete='off'  /></Table.Cell>
            <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = "plusMonthHoliBasicTime" value = {plusMonthHoliBasicTime} onChange= {(e) => handelInputChange(e)} autoComplete='off'  /></Table.Cell>
            <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = "plusMonthOWTHoli"  value = {plusMonthOWTHoli} onChange= {(e) => handelInputChange(e)} autoComplete='off'  /></Table.Cell>
            <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = "plusMonthNWTHoli" value = {plusMonthNWTHoli} onChange= {(e) => handelInputChange(e)} autoComplete='off'  /></Table.Cell>
            <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = 'absentDays' value = {absentDays} onChange= {(e) => handelInputChange(e)} autoComplete='off'  /></Table.Cell>
            <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = 'absentPaidHoliDays' value = {absentPaidHoliDays} onChange= {(e) => handelInputChange(e)} autoComplete='off'  /></Table.Cell>
            <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = 'absentTimes' value = {absentTimes} onChange= {(e) => handelInputChange(e)} autoComplete='off'  /></Table.Cell>
            {(numOfWorkers >= 5 && weekLBTime >= 15) ?
            <Table.Cell className='gubun' style={centerStyle}><input style={inputTimeStyle} name = 'usedAnnualLeaves' value = {usedAnnualLeaves} onChange= {(e) => handelInputChange(e)} autoComplete='off'/></Table.Cell> : <Table.Cell></Table.Cell>}    
          </Table.Row>
      }
    </>
  )
}
