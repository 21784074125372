import React from 'react';
import { Table } from 'semantic-ui-react';
import { josaUnType } from '../../../app/common/util/util';

export default function PaydocuDetailedInHead({
  worker, 
  companyName, 
  isExceptTimeLawWorker, 
  thisYear, 
  thisMonth,
  isBizWorker
}) {
  const companyUn = josaUnType(companyName)

  function headText (worker) {
    const text = worker + '의 임금명세서'
    return text
  }

  return (
    <>
      {isBizWorker ? null :
        <>
          <Table.Row>
            <Table.Cell colSpan='9' textAlign = "center">
              <span style={{textAlign : 'right', fontSize : '20px'}}>{thisYear}년</span>
              &nbsp;&nbsp;&nbsp;&nbsp;<span style={{textAlign : 'right', fontSize : '20px'}}>{thisMonth}월</span>
              <span style={{textAlign : 'center', margin : '20px', fontSize : '20px'}}>{headText(worker)}</span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            {isExceptTimeLawWorker ? 
              <Table.Cell  colSpan='9'>
                {`
                  ${companyUn}
                  ${worker}에게 근로기준법 제48조 제2항에 의거 아래와 같이 임금의 구성항목, 계산방법과 공제내역이 명시된 임금명세서를 교부하며, 귀하는 근로기준법 제63조에 의거 근로시간, 휴게, 휴일의 규정이 적용되지 않음을 안내해드립니다, 귀하의 노고에 감사드립니다.
                `}
              </Table.Cell>
            :
              <Table.Cell  colSpan='9'>
                {`
                  ${companyUn}
                  ${worker}에게 근로기준법 제48조 제2항에 의거 아래와 같이 임금의 구성항목, 계산방법과 공제내역이 명시된 임금명세서를 교부합니다. 귀하의 노고에 감사드립니다.
                `}
              </Table.Cell>
            }
          </Table.Row>
        </>
      }
    </>
  )
}
