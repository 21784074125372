import React from 'react';
import { useSelector } from 'react-redux';
import MyTextInput from '../../../app/common/form/MyTextInput';

export default function CustomerPromotionForm({ authLevel }) {
	// 11월22일
	const { currentUserProfile } = useSelector((state) => state.profile);
	// -------
	return (
		<>
			<h3>고객프로모션설정(관리자용)</h3>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				{/* 11월21일 */}
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='추천 회사명'
						name='recommenderCompany'
						placeholder=''
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='추천인 성명'
						name='recommenderPersonName'
						placeholder=''
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='추천인 코드'
						name='recommenderCode'
						placeholder=''
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='할인 승인자'
						name='discountPermmitPerson'
						placeholder=''
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='할인 이유'
						name='discountPermmitReason'
						placeholder=''
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput label='할인코드' name='discountCode' placeholder='' />
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput label='할인률' name='discountRate' placeholder='' />
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='할인시작일'
						name='discountStartDate'
						placeholder='할인시작일'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='할인종료일'
						name='discountEndDate'
						placeholder='할인종료일'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='할인시 적용 요금'
						name='discountFee'
						placeholder='할인시 적용 요금'
					/>
				</div>
				{/* 11월22일 */}
				{currentUserProfile?.email === 'freya0207@gmail.com' && (
					// ----
					<>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MyTextInput
								label='무료사용 승인자'
								name='freeUsePermmitPerson'
								placeholder=''
							/>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MyTextInput
								label='무료사용 이유'
								name='freeUsePermmitReason'
								placeholder=''
							/>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MyTextInput
								label='무료사용(월)'
								name='freeUseMonth'
								placeholder='freeUseMonth'
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
}
