import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';
import {
	deductWageF,
	pensionText,
	roundUp,
	healthCareText,
	noJobText,
	numberToCommaString,
	oldCareText,
	isInterInWorkerF,
} from '../../../../../../../app/common/util/util';
import { format } from 'date-fns';

export default function ClientReqDLCWageTableDayWorkerDeductDetail({
	dayWorkerTaxText,
	dayWorkerDayBasicWorkingTime,
	weekBLTimeTWThisMonth,
	isDayOrTimeWorker,
	values,
	initialTax,
	dayWorkerTax,
	constructOrNot,
	gumakStyle,
	centerStyle,
	lawBaseWorkDatesWorkHourThisMonthOnlySum,

	countOfNomalDayWork,
	countOfOverWorkingDayWork,
	countOfHoliDayWork,
	totalMonthWageAmount,
	totalCountOfWorkingDay,

	thisYear,
	thisMonth,
	inputTax,
	inputCommentStyle,
	inputWageStyle,

	dayWorkerDeductEtcName1,
	dayWorkerDeductEtcWage1,
	dayWorkerDeductEtcWage1Comment,
	dayWorkerDeductEtcName2,
	dayWorkerDeductEtcWage2,
	dayWorkerDeductEtcWage2Comment,
	dayWorkerDeductEtcName3,
	dayWorkerDeductEtcWage3,
	dayWorkerDeductEtcWage3Comment,
	handelInputChange,

	dayWorkerHoliWage,
	dayWorkerOverWorkDayWage,
	dayWorkerWage,

	dayWorkerUserFixedTax,
	dayWorkerUserFixedRegionTax,
	dayWorkerUserFixedHealthCare,
	dayWorkerUserFixedOldCare,
	dayWorkerUserFixedPension,
	dayWorkerUserFixedNoJobInsurance,

	dayWorkerPlusDeductHealthCare,
	dayWorkerPlusDeductOldCare,
	dayWorkerPlusDeductWorkTax,
	dayWorkerPlusDeductWorkRegionTax,
	dayWorkerPlusDeductDuru,
	dayWorkerPlusMinus4Ins,
	dayWorkerPlusDeductWorkerCompanyLoan,

	dayWorkerPlusDeductHealthCareComment,
	dayWorkerPlusDeductOldCareComment,
	dayWorkerPlusDeductWorkTaxComment,
	dayWorkerPlusDeductWorkRegionTaxComment,
	dayWorkerPlusMinus4InsComment,
	dayWorkerPlusDeductWorkerCompanyLoanComment,

	daylyTimePay,
	timeWorkerTotalWorkDays,
	netAgeEntered,
}) {
	const { selectedClientReq4In } = useSelector(
		(state) => state.clientReq4InStore
	);

	const workerEnteredDate = selectedClientReq4In?.workerEnteredDate
		? format(new Date(selectedClientReq4In?.workerEnteredDate), 'yyyy-MM-dd')
		: '';
	const isInterInWorkerV = isInterInWorkerF(
		workerEnteredDate,
		thisMonth,
		thisYear
	);
	const visaType = selectedClientReq4In?.foreigner?.visaType || '';
	const selfNoJobPay = selectedClientReq4In?.foreigner?.selfNoJobPay || '';

	const deductWageInfo = deductWageF(
		isDayOrTimeWorker,
		selectedClientReq4In,
		lawBaseWorkDatesWorkHourThisMonthOnlySum,
		values,
		constructOrNot,
		totalCountOfWorkingDay,
		totalMonthWageAmount,
		timeWorkerTotalWorkDays,
		dayWorkerPlusDeductHealthCare,
		dayWorkerPlusDeductOldCare,
		dayWorkerPlusDeductWorkTax,
		dayWorkerPlusDeductWorkRegionTax,
		dayWorkerPlusDeductDuru,
		dayWorkerPlusMinus4Ins,
		dayWorkerPlusDeductWorkerCompanyLoan,
		dayWorkerDeductEtcWage1,
		dayWorkerDeductEtcWage2,
		dayWorkerDeductEtcWage3,
		daylyTimePay,
		dayWorkerWage,
		countOfNomalDayWork,
		dayWorkerOverWorkDayWage,
		countOfOverWorkingDayWork,
		dayWorkerHoliWage,
		countOfHoliDayWork,
		thisYear,
		thisMonth,
		dayWorkerUserFixedTax,
		dayWorkerUserFixedRegionTax,
		dayWorkerUserFixedHealthCare,
		dayWorkerUserFixedOldCare,
		dayWorkerUserFixedPension,
		dayWorkerUserFixedNoJobInsurance,
		weekBLTimeTWThisMonth,
		dayWorkerDayBasicWorkingTime,
		dayWorkerTaxText,
		isInterInWorkerV,
		selfNoJobPay,
		netAgeEntered
	);

	const calTax = deductWageInfo?.calTax;
	const taxPayType = deductWageInfo?.taxPayType;
	const calRegionTax = deductWageInfo?.calRegionTax;
	const healthCarePayType = deductWageInfo?.healthCarePayType;
	const calHealthCare = deductWageInfo?.calHealthCare;
	const healthCareBaseWage = deductWageInfo?.healthCareBaseWage;
	const oldCarePayType = deductWageInfo?.oldCarePayType;
	const calOldCare = deductWageInfo?.calOldCare;
	const oldCareBaseWage = deductWageInfo?.oldCareBaseWage;
	const pensionPayType = deductWageInfo?.pensionPayType;
	const pensionBaseWage = deductWageInfo?.pensionBaseWage;

	const calPension = deductWageInfo?.calPension;
	const calNoJobInsurance = deductWageInfo?.calNoJobInsurance;
	const noJobBaseWage = deductWageInfo?.noJobBaseWage;
	const noJobPayType = deductWageInfo?.noJobPayType;
	const numDayWorkerPlusDeductHealthCare =
		deductWageInfo?.numDayWorkerPlusDeductHealthCare;
	const sumOfDedectTax = deductWageInfo?.sumOfDedectTax;
	const deductWage = deductWageInfo?.deductWage;

	const selfNoJobPayType = selectedClientReq4In?.foreigner?.selfNoJobPayType
		? selectedClientReq4In?.foreigner?.selfNoJobPayType
		: '';

	const healtCareTextValue = healthCareText(
		healthCareBaseWage,
		healthCarePayType,
		thisYear,
		calHealthCare
	);
	const oldCareTextValue = oldCareText(
		oldCareBaseWage,
		oldCarePayType,
		thisYear,
		calOldCare
	);
	const pensionTextValue = pensionText(
		pensionBaseWage,
		pensionPayType,
		calPension,
		visaType
	);
	const noJobPayTextValue = noJobText(
		noJobBaseWage,
		noJobPayType,
		thisYear,
		calNoJobInsurance,
		visaType,
		selfNoJobPayType
	);

	return (
		<>
			<Table.Row>
				<Table.Cell className='gubun' style={centerStyle}>
					공제
				</Table.Cell>
				<Table.Cell className='hangmok' style={centerStyle}>
					근로소득세
				</Table.Cell>
				{inputTax ? (
					<>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(
								initialTax
									? calTax
									: dayWorkerUserFixedTax
									? dayWorkerUserFixedTax
									: calTax
							)}
						</Table.Cell>
						<Table.Cell
							colSpan='6'
							className='sulmung'>{`당월 과세 임금에 대한 소득세법상 ${
							dayWorkerTax === '일용근로자세금' ? '일용직' : ''
						} 근로소득세 ${taxPayType ? taxPayType : '100%'}`}</Table.Cell>
					</>
				) : (
					<>
						<Table.Cell className='gumak' style={gumakStyle}>
							<input
								style={inputWageStyle}
								name='dayWorkerUserFixedTax'
								defaultValue={numberToCommaString(
									initialTax
										? calTax
										: dayWorkerUserFixedTax
										? dayWorkerUserFixedTax
										: calTax
								)}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell colSpan='6' className='sulmung'>
							<input
								style={{ width: '100%' }}
								name='dayWorkerUserFixedTaxComment'
								defaultValue={`당월 과세 임금에 대한 소득세법상 일용직 근로소득세 ${
									taxPayType ? taxPayType : '100%'
								}`}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
					</>
				)}
			</Table.Row>
			<Table.Row>
				<Table.Cell className='gubun' style={centerStyle}>
					공제
				</Table.Cell>
				<Table.Cell className='hangmok' style={centerStyle}>
					지방소득세
				</Table.Cell>
				{inputTax ? (
					<>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(
								initialTax
									? calRegionTax
									: dayWorkerUserFixedRegionTax
									? dayWorkerUserFixedRegionTax
									: calRegionTax
							)}
						</Table.Cell>
						<Table.Cell colSpan='6' className='sulmung'>
							{`당월 과세 임금에 대한 소득세법상 ${
								dayWorkerTax === '일용근로자세금' ? '일용직' : ''
							} 지방소득세 ${taxPayType ? taxPayType : '100%'}`}
						</Table.Cell>
					</>
				) : (
					<>
						<Table.Cell className='gumak' style={gumakStyle}>
							<input
								style={inputWageStyle}
								name='dayWorkerUserFixedRegionTax'
								defaultValue={numberToCommaString(
									initialTax
										? calRegionTax
										: dayWorkerUserFixedRegionTax
										? dayWorkerUserFixedRegionTax
										: calRegionTax
								)}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
						<Table.Cell colSpan='6' className='sulmung'>
							<input
								style={{ width: '100%' }}
								name='dayWorkerUserFixedRegionTaxComment'
								defaultValue={`당월 과세 임금에 대한 소득세법상 일용직 지방소득세 ${
									taxPayType ? taxPayType : '100%'
								}`}
								onChange={(e) => handelInputChange(e)}
							/>
						</Table.Cell>
					</>
				)}
			</Table.Row>
			<>
				{healthCarePayType === '건강보험료 공제안함' ? null : (
					<Table.Row>
						<Table.Cell className='gubun' style={centerStyle}>
							공제
						</Table.Cell>
						<Table.Cell className='hangmok' style={centerStyle}>
							건강보험료
						</Table.Cell>
						{inputTax ? (
							<>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(
										initialTax
											? calHealthCare
											: dayWorkerUserFixedHealthCare
											? dayWorkerUserFixedHealthCare
											: calHealthCare
									)}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									{healtCareTextValue}
								</Table.Cell>
							</>
						) : (
							<>
								<Table.Cell className='gumak' style={gumakStyle}>
									<input
										style={inputWageStyle}
										name='dayWorkerUserFixedHealthCare'
										defaultValue={numberToCommaString(
											initialTax
												? calHealthCare
												: dayWorkerUserFixedHealthCare
												? dayWorkerUserFixedHealthCare
												: calHealthCare
										)}
										onChange={(e) => handelInputChange(e)}
									/>
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									<input
										style={{ width: '100%' }}
										name='dayWorkerUserFixedHealthCareComment'
										defaultValue={healtCareTextValue}
										onChange={(e) => handelInputChange(e)}
									/>
								</Table.Cell>
							</>
						)}
					</Table.Row>
				)}
				{oldCarePayType === '요양보험료 공제안함' ? null : (
					<Table.Row>
						<Table.Cell className='gubun' style={centerStyle}>
							공제
						</Table.Cell>
						<Table.Cell className='hangmok' style={centerStyle}>
							요양보험료
						</Table.Cell>
						{inputTax ? (
							<>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(
										initialTax
											? calOldCare
											: dayWorkerUserFixedOldCare
											? dayWorkerUserFixedOldCare
											: calOldCare
									)}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									{oldCareTextValue}
								</Table.Cell>
							</>
						) : (
							<>
								<Table.Cell className='gumak' style={gumakStyle}>
									<input
										style={inputWageStyle}
										name='dayWorkerUserFixedOldCare'
										defaultValue={numberToCommaString(
											initialTax
												? calOldCare
												: dayWorkerUserFixedOldCare
												? dayWorkerUserFixedOldCare
												: calOldCare
										)}
										onChange={(e) => handelInputChange(e)}
									/>
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									<input
										style={{ width: '100%' }}
										name='dayWorkerUserFixedOldCareComment'
										defaultValue={oldCareTextValue}
										onChange={(e) => handelInputChange(e)}
									/>
								</Table.Cell>
							</>
						)}
					</Table.Row>
				)}
			</>

			<>
				{pensionPayType === '국민연금료 공제안함' ? null : (
					<Table.Row>
						<Table.Cell className='gubun' style={centerStyle}>
							공제
						</Table.Cell>
						<Table.Cell className='hangmok' style={centerStyle}>
							국민연금
						</Table.Cell>
						{inputTax ? (
							<>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(
										initialTax
											? calPension
											: dayWorkerUserFixedPension
											? dayWorkerUserFixedPension
											: calPension
									)}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									{pensionTextValue}
								</Table.Cell>
							</>
						) : (
							<>
								<Table.Cell className='gumak' style={gumakStyle}>
									<input
										style={inputWageStyle}
										name='dayWorkerUserFixedPension'
										defaultValue={numberToCommaString(
											initialTax
												? calPension
												: dayWorkerUserFixedPension
												? dayWorkerUserFixedPension
												: calPension
										)}
										onChange={(e) => handelInputChange(e)}
									/>
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									<input
										style={{ width: '100%' }}
										name='dayWorkerUserFixedPensionComment'
										defaultValue={pensionTextValue}
										onChange={(e) => handelInputChange(e)}
									/>
								</Table.Cell>
							</>
						)}
					</Table.Row>
				)}
			</>

			<>
				{noJobPayType === '고용보험료 공제안함' ? null : (
					<Table.Row>
						<Table.Cell className='gubun' style={centerStyle}>
							공제
						</Table.Cell>
						<Table.Cell className='hangmok' style={centerStyle}>
							고용보험료
						</Table.Cell>
						{inputTax ? (
							<>
								<Table.Cell className='gumak' style={gumakStyle}>
									{numberToCommaString(
										initialTax
											? calNoJobInsurance
											: dayWorkerUserFixedNoJobInsurance
											? dayWorkerUserFixedNoJobInsurance
											: calNoJobInsurance
									)}
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									{' '}
									{noJobPayTextValue}
								</Table.Cell>
							</>
						) : (
							<>
								<Table.Cell className='gumak' style={gumakStyle}>
									<input
										style={inputWageStyle}
										name='dayWorkerUserFixedNoJobInsurance'
										defaultValue={numberToCommaString(
											initialTax
												? calNoJobInsurance
												: dayWorkerUserFixedNoJobInsurance
												? dayWorkerUserFixedNoJobInsurance
												: calNoJobInsurance
										)}
										onChange={(e) => handelInputChange(e)}
									/>
								</Table.Cell>
								<Table.Cell colSpan='6' className='sulmung'>
									<input
										style={{ width: '100%' }}
										name='dayWorkerUserFixedNoJobInsuranceComment'
										defaultValue={noJobPayTextValue}
										onChange={(e) => handelInputChange(e)}
									/>
								</Table.Cell>
							</>
						)}
					</Table.Row>
				)}
			</>
			<Table.Row>
				{numDayWorkerPlusDeductHealthCare ? (
					<>
						<Table.Cell className='gubun' style={centerStyle}>
							공제
						</Table.Cell>
						<Table.Cell className='hangmok' style={centerStyle}>
							건강보험정산분
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(+dayWorkerPlusDeductHealthCare)}
						</Table.Cell>
						<Table.Cell colSpan='6' className='sulmung'>
							{dayWorkerPlusDeductHealthCareComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row>
				{+dayWorkerPlusDeductOldCare ? (
					<>
						<Table.Cell className='gubun' style={centerStyle}>
							공제
						</Table.Cell>
						<Table.Cell className='hangmok' style={centerStyle}>
							장기요양보험정산분
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(+dayWorkerPlusDeductOldCare)}
						</Table.Cell>
						<Table.Cell colSpan='6' className='sulmung'>
							{dayWorkerPlusDeductOldCareComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row>
				{+dayWorkerPlusDeductWorkTax ? (
					<>
						<Table.Cell className='gubun' style={centerStyle}>
							공제
						</Table.Cell>
						<Table.Cell className='hangmok' style={centerStyle}>
							근로소득세정산분
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(+dayWorkerPlusDeductWorkTax)}
						</Table.Cell>
						<Table.Cell colSpan='6' className='sulmung'>
							{dayWorkerPlusDeductWorkTaxComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row>
				{+dayWorkerPlusDeductWorkRegionTax ? (
					<>
						<Table.Cell className='gubun' style={centerStyle}>
							공제
						</Table.Cell>
						<Table.Cell className='hangmok' style={centerStyle}>
							지방소득세정산분
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(+dayWorkerPlusDeductWorkRegionTax)}
						</Table.Cell>
						<Table.Cell colSpan='6' className='sulmung'>
							{dayWorkerPlusDeductWorkRegionTaxComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row>
				{+dayWorkerPlusDeductWorkerCompanyLoan ? (
					<>
						<Table.Cell className='gubun' style={centerStyle}>
							공제
						</Table.Cell>
						<Table.Cell className='hangmok' style={centerStyle}>
							대여금 반제
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(+dayWorkerPlusDeductWorkerCompanyLoan)}
						</Table.Cell>
						<Table.Cell colSpan='6' className='sulmung'>
							{dayWorkerPlusDeductWorkerCompanyLoanComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row>
				{+dayWorkerPlusDeductDuru ? (
					<>
						<Table.Cell className='gubun' style={centerStyle}>
							공제
						</Table.Cell>
						<Table.Cell className='hangmok' style={centerStyle}>
							두루누리지원금
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(+dayWorkerPlusDeductDuru)}
						</Table.Cell>
						<Table.Cell colSpan='6' className='sulmung'>
							4대보험 두루누리 지원금
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row>
				{+dayWorkerPlusMinus4Ins ? (
					<>
						<Table.Cell className='gubun' style={centerStyle}>
							공제
						</Table.Cell>
						<Table.Cell className='hangmok' style={centerStyle}>
							공제 조정
						</Table.Cell>
						<Table.Cell className='gumak' style={gumakStyle}>
							{numberToCommaString(+dayWorkerPlusMinus4Ins)}
						</Table.Cell>
						<Table.Cell colSpan='6' className='sulmung'>
							{dayWorkerPlusMinus4InsComment}
						</Table.Cell>
					</>
				) : null}
			</Table.Row>
			<Table.Row>
				<Table.Cell className='gubun' style={centerStyle}>
					공제
				</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					<input
						autoComplete='off'
						style={inputWageStyle}
						name='dayWorkerDeductEtcName1'
						value={dayWorkerDeductEtcName1}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					<input
						autoComplete='off'
						style={inputWageStyle}
						name='dayWorkerDeductEtcWage1'
						value={dayWorkerDeductEtcWage1}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
				<Table.Cell colSpan='7' className='sulmung'>
					<textarea
						autoComplete='off'
						style={inputCommentStyle}
						name='dayWorkerDeductEtcWage1Comment'
						value={dayWorkerDeductEtcWage1Comment}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell className='gubun' style={centerStyle}>
					공제
				</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					<input
						autoComplete='off'
						style={inputWageStyle}
						name='dayWorkerDeductEtcName2'
						value={dayWorkerDeductEtcName2}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					<input
						autoComplete='off'
						style={inputWageStyle}
						name='dayWorkerDeductEtcWage2'
						value={dayWorkerDeductEtcWage2}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
				<Table.Cell colSpan='7' className='sulmung'>
					<textarea
						autoComplete='off'
						style={inputCommentStyle}
						name='dayWorkerDeductEtcWage2Comment'
						value={dayWorkerDeductEtcWage2Comment}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell className='gubun' style={centerStyle}>
					공제
				</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					<input
						autoComplete='off'
						style={inputWageStyle}
						name='dayWorkerDeductEtcName3'
						value={dayWorkerDeductEtcName3}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					<input
						autoComplete='off'
						style={inputWageStyle}
						name='dayWorkerDeductEtcWage3'
						value={dayWorkerDeductEtcWage3}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
				<Table.Cell colSpan='7' className='sulmung'>
					<textarea
						autoComplete='off'
						style={inputCommentStyle}
						name='dayWorkerDeductEtcWage3Comment'
						value={dayWorkerDeductEtcWage3Comment}
						onChange={(e) => handelInputChange(e)}
					/>
				</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell className='gubun' style={centerStyle}>
					공제
				</Table.Cell>
				<Table.Cell className='hangmok' style={centerStyle}>
					공제 합계
				</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					{numberToCommaString(sumOfDedectTax)}
				</Table.Cell>
				<Table.Cell colSpan='6' className='sulmung'>
					세금 및 4대보험 기타 공제 합계
				</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell className='gubun' style={centerStyle}>
					차인지급액
				</Table.Cell>
				<Table.Cell className='hangmok' style={centerStyle}>
					실수령액
				</Table.Cell>
				<Table.Cell className='gumak' style={gumakStyle}>
					{numberToCommaString(roundUp(deductWage))}
				</Table.Cell>
				<Table.Cell colSpan='6' className='sulmung'>
					실수령액
				</Table.Cell>
			</Table.Row>
		</>
	);
}
