import React from 'react';
import MyTextInput from '../../../app/common/form/MyTextInput';
import MyDateInput from '../../../app/common/form/MyDateInput';
import MyNumberInput from '../../../app/common/form/MyNumberInput';

export default function CustomerServiceForm() {
	return (
		<>
			<h3>고객관리설정(관리자용)</h3>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='고객상태'
						name='companyStatus'
						placeholder='고객상태'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='정기구매상품'
						name='regularProduct'
						placeholder='구매상품'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyNumberInput
						label='월사용료'
						name='monthFee'
						placeholder='월사용료'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInput
						label='서비스시작일'
						name='serviceStartDate'
						placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInput
						label='서비스종료일'
						name='serviceEndDate'
						placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInput
						label='자동이체일'
						name='payStartDate'
						placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput label='결제방법' name='payType' placeholder='결제방법' />
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInput
						label='계약해지일'
						name='companyContractEndDate'
						placeholderText='달력 사용( 또는 0000-00-00  형식으로 입력요망)'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='해지사유'
						name='contractEndReason'
						placeholder='해지사유'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='비정기구매상품1'
						name='irregularProduct1'
						placeholder='비정기구매상품1'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='비정기구매상품가격1'
						name='irregularProductPrice1'
						placeholder='단건상품가격1'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput label='가입비' name='enterFee' placeholder='가입비' />
				</div>
			</div>
		</>
	);
}
