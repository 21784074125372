import React, { useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Grid, Table } from 'semantic-ui-react';
import {
	josaGaType,
	josaUlType,
	josaUnType,
	laborContractStyle,
	numStringToNumber,
	numberToCommaString,
	roundUp2,
} from '../../../../app/common/util/util';
import { listenToClientReq4InFromFirestore } from '../../../../app/firestore/firestoreService4In';
import useFirestoreDoc from '../../../../app/hooks/useFirestoreDoc';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
	listenToSelectedClientReq4In,
} from '../clientReqActions4In';
import ClientReqDetailedHeader4In from './ClientReqDetailedHeader4In';
import ClientReqDLCHeadLawBaseWorkerInfo from './clientReqDetailedLCDetail/ClientReqDLCHeadLawBaseWorkerInfo';
import PaydocuPrintOneTable from '../../../companyManage/paydocuDashboard/PaydocuPrintOneTable';
import PaydocuShortCut from '../../../companyManage/paydocuDashboard/PaydocuShortCut';
import { decrypto } from '../../../../app/firestore/firestoreService';
import moment from 'moment';

export default function ClientRDLawBaseWorkerInfo({ match }) {
	const htmlContentRef = useRef(null);

	const dispatch = useDispatch();
	const { selectedClientReq4In, clientReqs4In } = useSelector(
		(state) => state.clientReq4InStore
	);
	const { clientInfo, filter } = useSelector((state) => state.companyReducer);
	const { loading, error } = useSelector((state) => state.async);
	const { currentUserProfile } = useSelector((state) => state.profile);

	const worker = selectedClientReq4In?.worker?.name;
	const numOfWorkers = selectedClientReq4In?.numOfWorkers;
	const daylyTimePay = selectedClientReq4In?.daylyPay?.timeWage
		? numStringToNumber(selectedClientReq4In?.daylyPay?.timeWage)
		: 0;

	//노무사는 노무사가 입력한 회사로 회사이름 설정 가능하고, 유저는 본인 회원가입한 상호로만 회사이름 설정됨.
	const isWorker = currentUserProfile?.isWorker;

	const companyName = isWorker
		? currentUserProfile?.workerInputCompanyName
			? currentUserProfile?.workerInputCompanyName
			: selectedClientReq4In?.companyName
		: clientInfo?.companyName
		? clientInfo?.companyName
		: selectedClientReq4In?.company?.companyName
		? selectedClientReq4In?.company?.companyName
		: selectedClientReq4In?.companyName;
	useFirestoreDoc({
		query: () => listenToClientReq4InFromFirestore(match.params.id),
		data: (selectedClientReq4In) =>
			dispatch(listenToSelectedClientReq4In(selectedClientReq4In)),
		deps: [match.params.id, dispatch],
	});

	useEffect(() => {
		dispatch(fetchClientReqs4In(filter)).then(() => {});
		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);
	if (loading || (!selectedClientReq4In && !error))
		return <LoadingComponent content='Loading event ...' />;
	if (error) return <Redirect to='/error' />;

	const companyGa = josaGaType(companyName);
	const workerUn = josaUnType(worker);

	const companyUn = josaUnType(companyName);
	const workerUl = josaUlType(worker);

	const isDayOrTimeWorker =
		selectedClientReq4In?.dayWorker?.daylyPay || daylyTimePay ? true : false;

	const numOfFamily = selectedClientReq4In?.numOfTaxFamily
		? +selectedClientReq4In?.numOfTaxFamily - 1
		: 0;
	const workerSocialNumberFront = decrypto(
		selectedClientReq4In.workerSocialNumberFront,
		String(process.env.CRYPTO_KEY)
	);
	const weekLBTime = selectedClientReq4In?.lawBase?.weekLBTime;
	const paidHolidayLBTime = selectedClientReq4In?.lawBase?.paidHolidayLBTime;
	const weekLawHoliDay = selectedClientReq4In?.etc?.paidOffdays
		? selectedClientReq4In?.etc?.paidOffdays
		: selectedClientReq4In?.paidOffdays;
	const weekLBHoliday =
		numOfWorkers >= 5
			? `주휴일 : ${weekLawHoliDay}요일, 근로자의날, 근로기준법 제55조 2항의 공휴일`
			: `주휴일 ${weekLawHoliDay}요일, 근로자의날`;
	const alDate = moment(new Date(selectedClientReq4In?.finBaseDate));
	const finBaseDateMonth = moment(
		moment().set({ month: alDate.get('month') })
	).format('MM');
	const finBaseDateDate = moment(
		moment().set({ date: alDate.get('date') })
	).format('DD');

	const accountAL = selectedClientReq4In?.annualLeaves;

	const annualLeavesAccountText = `${companyUn} ${worker}에게 근로기준법 제60조에 따른 연차유급휴가를 부여하되 
  효율적인 연차관리를 위해 회계년도(매년 ${finBaseDateMonth}월 ${finBaseDateDate}일, 매월 ${finBaseDateDate}일) 
  기준으로 년 단위 연차와 월 단위 연차를 부여(회사는 회계년도 기준으로 연차를 부여하므로 월 단위 연차의 소멸시기는 근기법상 입사일부터 1년이 되는 날이 아닌 2년차 회계년도 기말 말일에 소멸함.)하며, 동법 제61조에 따라 적법하게 연차유급휴가를 사용촉진한 경우 
  연차유급휴가미사용 수당 지급의무를 면하며, 근로자대표와의 서면합의에 의거 연차유급휴가일을 갈음하여 
  특정근로일에 ${workerUl} 휴무시킬 수 있다. 한편  ${workerUn} ${companyGa} 전항에 따라 부여한 회계년도 단위로 부여 연차가 ${worker}의 입사일 기준으로 산정하는 법정연차보다 적게 부여된 경우 ${companyUn} ${worker}에게
  법정연차에 따른 추가 연차를 부여해야 하며, ${companyGa} ${worker}에게 회계년도 단위로 부여한 연차가 ${worker}의 입사일 기준으로 부여하는
  법정 연차보다 많이 부여된 경우 ${workerUn} 이를 마지막달 임금 또는 퇴직금 지급시 정산 공제한다.`;

	const annualLeavesLawText = `${companyUn} ${worker}에게 근로기준법 제60조에 따른 ${worker}의 입사일 단위로 계산되는 연차유급휴가를 부여하고, 
  ${companyGa} ${worker}에게 동법 제61조에 따라 적법하게 연차유급휴가 사용촉진을 한 경우 연차유급휴가미사용 수당 지급의무를 면하며, 
  ${companyUn} 근로자대표와의 서면합의에 의거 ${worker}의 연차유급휴가일을 갈음하여 특정 근로일에 ${workerUl} 휴무시킬 수 있다.`;

	const finalAnnualLeavesText =
		accountAL === '회계년도기준' || accountAL === '특정일(회계년도 등)기준'
			? annualLeavesAccountText
			: annualLeavesLawText;
	const annualLeavesText =
		numOfWorkers >= 5 ? finalAnnualLeavesText : '5인미만으로 해당사항 없음.';
	const calHourLowestWage = numberToCommaString(
		roundUp2(selectedClientReq4In?.lawBase?.calHourLowestWage)
	);
	const hourOrdinaryWage = numberToCommaString(
		roundUp2(selectedClientReq4In?.lawBase?.hourOrdinaryWage)
	);
	const monthOrdinaryWage = numberToCommaString(
		selectedClientReq4In?.calWorkWage?.monthOrdinaryWage
	);
	const mTPCTime = selectedClientReq4In?.calWorkTime?.mTPCTime;
	const totalMonthWageAmount = numberToCommaString(
		selectedClientReq4In?.totalMonthWageAmount
	);
	const monthLBTime = selectedClientReq4In?.lawBase?.monthLBTime;

	function dateFormat(date) {
		return date ? format(new Date(date), 'yyyy-MM-dd') : '';
	}

	const isLawBaseWorkerInfo = true;

	// 데이터 가공 함수
	const processLeaveData = (
		leaveData,
		maternityData,
		childShortTimeData,
		leaveOfAbseceData
	) => {
		const formatDate = (timestamp) => {
			const date = new Date(timestamp.seconds * 1000);
			return date.toLocaleDateString();
		};

		let combinedData = '';

		maternityData.forEach((leave, index) => {
			combinedData += `출산휴가 ${index + 1}:`;
			combinedData += ` - 유형: ${leave.maternityLeaveType}`;
			combinedData += ` - 시작일: ${formatDate(leave.maternityLeaveStartDate)}`;
			combinedData += ` - 종료일: ${formatDate(leave.maternityLeaveEndDate)}\n`; // 항목 사이에 한 칸 띄우기
		});

		combinedData += '\n'; // 육아휴직과 출산휴가 사이에 한 칸 띄우기

		leaveData.forEach((leave, index) => {
			combinedData += `육아휴직 ${index + 1}:`;
			combinedData += `- 유형: ${leave.childLeaveOfAbsenceType}`;
			combinedData += `- 시작일: ${formatDate(
				leave.childLeaveOfAbsenceStartDate
			)}`;
			combinedData += `- 종료일: ${formatDate(
				leave.childLeaveOfAbsenceEndDate
			)}\n`; // 항목 사이에 한 칸 띄우기
		});

		combinedData += '\n'; // 육아휴직과 출산휴가 사이에 한 칸 띄우기

		childShortTimeData.forEach((leave, index) => {
			combinedData += `육아기 근로시간 단축 ${index + 1}:`;
			combinedData += `- 유형: ${leave.childShortTimeType}`;
			combinedData += `- 시작일: ${formatDate(leave.childShortTimeStartDate)}`;
			combinedData += `- 종료일: ${formatDate(leave.childShortTimeEndDate)}\n`; // 항목 사이에 한 칸 띄우기
		});

		combinedData += '\n'; // 육아휴직과 출산휴가 사이에 한 칸 띄우기

		leaveOfAbseceData.forEach((leave, index) => {
			combinedData += `개인 휴직 ${index + 1}:`;
			combinedData += `- 유형: ${leave.leaveOfAbsenceType}`;
			combinedData += `- 시작일: ${formatDate(leave.leaveOfAbsenceStartDate)}`;
			combinedData += `- 종료일: ${formatDate(leave.leaveOfAbsenceEndDate)}\n`; // 항목 사이에 한 칸 띄우기
		});

		return combinedData;
	};

	const processedData = processLeaveData(
		selectedClientReq4In.childLeaveOfAbsences,
		selectedClientReq4In.maternityLeaves,
		selectedClientReq4In.childShortTimes,
		selectedClientReq4In.leaveOfAbsences
	);

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}

			<Grid>
				<Grid.Column width={16}>
					<ClientReqDetailedHeader4In
						selectedClientReq4In={selectedClientReq4In}
						companyName={companyName}
						clientReqs4In={clientReqs4In}
						daylyTimePay={daylyTimePay}
						isDayOrTimeWorker={isDayOrTimeWorker}
					/>
					<PaydocuPrintOneTable
						worker={worker}
						// year={year}
						// month={month}
						htmlContentRef={htmlContentRef}
						isLawBaseWorkerInfo={isLawBaseWorkerInfo}
						companyName={companyName}
					/>
					<div id='personalInfoAgree' ref={htmlContentRef}>
						<ClientReqDLCHeadLawBaseWorkerInfo
							selectedClientReq4In={selectedClientReq4In}
							laborContractStyle={laborContractStyle}
							companyName={companyName}
							worker={worker}
						/>
						<br />
						<div style={laborContractStyle}>
							<br />
							<Table celled>
								<Table.Body>
									<Table.Row>
										<Table.Cell width={3}>① 성명</Table.Cell>
										<Table.Cell colSpan='3'>{worker}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>② 생년월일</Table.Cell>
										<Table.Cell colSpan='3'>
											{workerSocialNumberFront}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>③ 주소 (전화)</Table.Cell>
										<Table.Cell colSpan='3'>
											<p>
												{selectedClientReq4In?.worker?.address} (
												{selectedClientReq4In?.worker?.phone})
											</p>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>④ 부양가족수</Table.Cell>
										<Table.Cell>{numOfFamily}</Table.Cell>
										<Table.Cell>⑤ 종사 업무</Table.Cell>
										<Table.Cell>
											{selectedClientReq4In?.noJobPayDetail}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell rowSpan='4'>이력</Table.Cell>
										<Table.Cell colSpan='3'>
											⑥ 기능 및 자격
											<p>{selectedClientReq4In?.worker?.ability}</p>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell colSpan='3'>
											⑦ 최종 학력
											<p>{selectedClientReq4In?.worker?.schoolGrage}</p>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell colSpan='3'>
											⑧ 경력
											<p>{selectedClientReq4In?.worker?.companyGrade}</p>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell colSpan='3'>
											⑨ 병역
											<p>{selectedClientReq4In?.worker?.militaryService}</p>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell rowSpan='4'>퇴직</Table.Cell>
										<Table.Cell>⑩ 해고일</Table.Cell>
										<Table.Cell>
											⑪ 퇴직일
											<p>{dateFormat(selectedClientReq4In?.retiredDate)}</p>
										</Table.Cell>
										<Table.Cell>
											⑫ 사유
											<p>{selectedClientReq4In?.eiOutDetailType}</p>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell colSpan='3'>⑬ 금품청산 등</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell colSpan='3'>
											⑭ 고용일 :{' '}
											{dateFormat(selectedClientReq4In?.workerEnteredDate)}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell colSpan='3'>
											⑮ 근로계약갱신일
											<p>
												{dateFormat(
													selectedClientReq4In?.againContractStartDate
												)}
											</p>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>⑯ 근로계약조건</Table.Cell>
										<Table.Cell colSpan='3'>
											<p>
												- 소정근로시간 : 1일 {paidHolidayLBTime}시간, 1주{' '}
												{weekLBTime}시간
											</p>
											<p>- 휴일 : {weekLBHoliday}</p>
											<p>- 월 통상임금계 : {monthOrdinaryWage} 원</p>
											<p>- 월 통상임금 산정기준 시간수 : {monthLBTime} 시간</p>
											<p>- 월급계 : {totalMonthWageAmount} 원</p>
											<p>- 월 총유급시간 : {mTPCTime} 시간</p>
											<p>- 시간급 최저임금 : {calHourLowestWage} 원</p>
											<p>- 시간급 통상임금 : {hourOrdinaryWage} 원</p>
											<p>- 휴가 : {annualLeavesText}</p>
											<p>
												- 구체적인 임금의 구성항목 계산방법은 임금계약서 및 매월
												지급되는 임금명세서에 의하고, 기타 근로계약시 근로조건은
												근로계약서에 의함.
											</p>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>⑰ 특기사항 (교육, 건강, 휴직 등)</Table.Cell>
										<Table.Cell colSpan='3'>
											<p>{selectedClientReq4In?.worker?.memo}</p>
											<pre>{processedData}</pre>
										</Table.Cell>
									</Table.Row>
								</Table.Body>
							</Table>
						</div>
					</div>
				</Grid.Column>
			</Grid>
		</>
	);
}
