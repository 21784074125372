import React, { useState, useMemo, useEffect } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && (
						<div className='no-print'>
							<ul>
								{amended.map((amendment, i) => (
									<li
										key={i}
										style={{
											color: amendment.includes('개정') ? 'red' : 'black',
										}}>
										{amendment}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function Roe6SufferDealRules({
	// serviceStartDate,
	// profile,
	// authLevel,
	company,
	companyUn,
	companyGa,
	companyWa,
	companyUl,
	allOpen,
	previousSectionCount1,
	previousSectionCount2,
	previousSectionCount3,
	previousSectionCount4,
	previousSectionCount5,
	setSectionCount,
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `고충처리위원`,
				content: [
					`${company}의 모든 사업 또는 사업장에는 근로자의 고충을 청취하고 이를 처리하기 위하여 고충처리위원을 두어야 한다.`,
					`제1항에도 불구하고 ${company}의 사업 또는 사업장 중 상시 30명 미만의 사원을 고용하는 사업 또는 사업장에는 고충처리위원을 두지 않을 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `고충처리위원의 구성 및 임기`,
				content: [
					`고충처리위원은 노사를 대표하는 3명 이내의 위원으로 구성하되, 협의회가 설치되어 있는 사업이나 사업장의 경우에는 협의회가 그 위원 중에서 선임하고, 협의회가 설치되어 있지 아니한 사업이나 사업장의 경우에는 ${companyGa} 위촉한다.`,
					`고충처리위원의 임기에 관하여는 제11조의 규정을 준용한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `고충처리 절차`,
				content: [
					`사원이 고충사항이 있을 때는 고충처리 위원에게 구두 또는 서면으로 신고한다.`,
					`고충처리위원은 사원로부터 고충사항을 청취한 경우에는 10일 이내에 조치 사항과 그 밖의 처리결과를 해당 사원에게 통보하여야 한다.`,
					`고충처리위원이 처리하기 곤란한 사항은 협의회의 회의에 부쳐 협의 처리한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `상담실`,
				content: [
					`${companyUn} 고충처리 업무를 수행하기 위하여 상담실을 설치하여 운영할 수 있다.`,
					`상담실의 운영에 필요한 세부 사항은 별도로 정할 수 있다.`,
					`고충처리위원은 사원과의 상담내용 중 기밀을 요하는 사항은 공개 하여서는 아니 된다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `위원의 처우`,
				content: [
					`고충처리위원의 신분은 비상임, 무보수로 한다.`,
					`${companyUn} 고충처리위원의 직무 수행과 관련하여 해당 위원에게 불 이익 한 처우를 하여서는 아니 된다.`,
					`고충처리위원의 상담, 협의, 조사 등 해당 업무와 관련된 소요 시간은 이를 정상 근무시간으로 본다.`,
					`기타 필요한 사항은 별도로 정한다.`,
				],
			},
			{
				title: `대장의 비치`,
				content: [
					`고충처리 위원은 고충사항의 접수 및 처리에 관한 대장을 작성∙비치하여야 하고 이를 1년간 보존한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
		];
		// if (['대한민국', '한국', '일본'].includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles.includes(section.title)
			);
		}

		return baseSections;
	}, [company, companyUn, companyGa]);

	useEffect(() => {
		setSectionCount(sections.length);
	}, [sections, setSectionCount]);

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className='jang sectionup'>제6장 고충처리</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={
							index +
							1 +
							previousSectionCount1 +
							previousSectionCount2 +
							previousSectionCount3 +
							previousSectionCount4 +
							previousSectionCount5
						}
						title={section?.title}
						content={section?.content}
						isOpen={!!openSections[section?.title]}
						toggleOpen={() => toggleSection(section?.title)}
						amended={section?.amended}
					/>
				</div>
			))}
		</>
	);
}
