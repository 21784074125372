import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';
import { GrUserWorker } from 'react-icons/gr';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearSelectedClientReq4In } from '../clientsRequest/clientReq4In/clientReqActions4In';
import { freeComClient } from '../../app/common/util/util';

export default function WorkerManageMenu({authLevel, ceoName, superAdminForFreeTrue}) {

  const history = useHistory();
  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseOver = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };


  function goToWorkerList () {
    dispatch(clearSelectedClientReq4In());
    history.push('/clientReqs4In');
  }

  function reloadToPaydocuWorkerCode () {
    history.push('/paydocuWorkerCode');
  }
  
  const freeComClientTrue = (!superAdminForFreeTrue && freeComClient(authLevel, ceoName)) ? true : false;

  return (
    <>
      <Menu.Item className="menu-item" onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      {!isMobile && <GrUserWorker className="icon-spacing" />}
        <Dropdown pointing='top left' text='직원List / 직원등록' open={dropdownOpen} onClick={handleDropdownToggle}>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => goToWorkerList()} text='전체직원 List보기' />
            <Dropdown.Item onClick={() => reloadToPaydocuWorkerCode()} text='직원등록 코드보기' />
            <hr />
            {freeComClientTrue
              ? 
                <Dropdown.Item as={Link} to='/pricePolicyPaper' text='유료버전 안내' />
              : 
                <>
                  <Dropdown.Item as={Link} to='/createClientReq4In'>
                    <span>월급직 등록<br /><br />(정규직/계약직/단시간 등)</span>
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to='/createClientReqDayWorker' text='일용/시급/사업소득' />
                </>
                }
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </>
  )
}
